import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyB1IMoshM4WC1k9w9NHTBZyMSzKEIj5Ft8',
  authDomain: 'cafe-click-notifications.firebaseapp.com',
  projectId: 'cafe-click-notifications',
  storageBucket: 'cafe-click-notifications.firebasestorage.app',
  messagingSenderId: '894826859216',
  appId: '1:894826859216:web:44cb4d117ccd36c44aecd5',
  measurementId: 'G-J661BXZVL5',
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const requestForToken = async () => {
  const token = await getToken(messaging, {
    vapidKey:
      'BN0VINcX2VrATGvDZWrAWjhxVeZY-uK2pcLBTHXFDDmNzZPdCJgE00OeA-HU8ovVfRx1Bl8OfEkvLRIOw1szEOc',
  });
  return token;
};

export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
