import React from 'react';
import styles from './style.module.css';

function Order({
  id,
  tableNumber,
  orderDate,
  amount,
  waiterName,
  status,
  setModalActive,
  isMobile,
}) {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getBackgroundColorForStatus(status) {
    if (status === 'new') {
      return '#42725e';
    }
    if (status === 'completed') {
      return '#E5D9B6';
    }
    return null;
  }

  function getColorForStatus() {
    if (status === 'new') {
      return 'white';
    }
    if (status === 'completed') {
      return 'black';
    }
  }

  const getDate = (date) => {
    if (isMobile) {
      return date.split(' ')[1];
    }
    return date;
  };
  return (
    <div
      className={styles.fields_wrapper}
      onClick={() => setModalActive(id)}
      style={{
        backgroundColor: status === 'new' ? '#f6f6fb' : 'white',
      }}>
      <span className={`${styles.field} ${styles.desktop_field}`}>
        {id.slice(0, 5).toString() + '...'}
      </span>
      <span className={styles.field}>{tableNumber}</span>
      <span className={styles.field}>{getDate(orderDate)}</span>
      <span className={`${styles.field} ${styles.desktop_field}`}>{amount}</span>
      <span className={`${styles.field} ${styles.desktop_field}`}>{waiterName}</span>
      <div className={styles.field_status}>
        <div
          className={styles.status}
          style={{
            fontSize: status === 'completed' ? '13px' : '14px',
            color: getColorForStatus(status),
            backgroundColor: getBackgroundColorForStatus(status),
          }}>
          {capitalizeFirstLetter(status)}
        </div>
      </div>
    </div>
  );
}

export default Order;
