import React, { useCallback, useEffect, useRef, useState } from 'react';
import { URL } from '../../utils/url';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import Order from './Order';
import styles from './style.module.css';
import { getOrders } from './helpers';
import Modal from './Modal';
import Header from '../Header/Header';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import Filter from './Filter/Filter.jsx';
import Footer from '../Footer/Footer.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInformationAboutCurrentRestaurant } from '../../redux/restaurantsSlice.js';

function OrderList() {
  const menuRef = useRef(null);
  // const SOCKET_URL = 'http://localhost:7000';
  const SOCKET_URL = 'https://cafeclickback.worx.am:7000';
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { id } = useParams();
  const [orders, setOrders] = useState([]);
  const [isModalActive, setModalActive] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [filter, setFilter] = useState({
    tableNumber: null,
    waiter: null,
    orderDate: null,
    amount: null,
  });
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.admin.user);
  const [infoAboutTableNumbersAndWaiters, setInfoAboutTableNumbersAndWaiters] = useState(null);

  useEffect(() => {
    if (role === 'waiter' || role === 'manager') {
      dispatch(getAllInformationAboutCurrentRestaurant({ id }));
    }
  }, [dispatch, id, role]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape' && isModalActive) {
        setModalActive(false);
      }
      return;
    },
    [isModalActive],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile && window.innerWidth > 600) {
        setIsMobile(false);
      }
      if (!isMobile && window.innerWidth < 600) {
        setIsMobile(true);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    getOrders(URL, id, filter).then((resp) => {
      const { tableNumbers, waiterNames, orders } = resp;
      setInfoAboutTableNumbersAndWaiters({ tableNumbers, waiterNames });
      let sortedOrders = [];

      if (Array.isArray(orders)) {
        sortedOrders = orders?.sort(
          (a, b) => parseDateString(b.orderDate) - parseDateString(a.orderDate),
        );
      }

      setOrders(sortedOrders);
    });
  }, [id, filter]);

  useEffect(() => {
    const socket = io(SOCKET_URL);

    socket.on('order', (newOrder) => {
      const sortedOrders = [...orders, newOrder]?.sort(
        (a, b) => parseDateString(b.orderDate) - parseDateString(a.orderDate),
      );
      setOrders(sortedOrders);
    });

    return () => {
      socket.disconnect();
    };
  }, [orders]);

  const toggleSideBarMenu = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const parseDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const order = orders.find((order) => order.id === isModalActive);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      const element = e.target;
      if (menuRef?.current) {
        if (
          !menuRef.current.contains(element) &&
          element.id !== 'burgerMenuToggleImage' &&
          element.id !== 'header' &&
          element.id !== 'footer'
        ) {
          setSideBarOpen(false);
        }
      }
    });
  }, []);

  return (
    <div className={styles.global_container}>
      <Header toggleSideBarMenu={toggleSideBarMenu} sideBarOpen={sideBarOpen} />

      <BurgerMenu menuRef={menuRef} id={id} sideBarOpen={sideBarOpen} page="orderList" />

      <Filter
        filter={filter}
        setFilter={setFilter}
        orders={orders}
        infoAboutTableNumbersAndWaiters={infoAboutTableNumbersAndWaiters}
      />

      <div className={styles.container}>
        <div className={styles.titles_wrapper}>
          <div className={`${styles.title} ${styles.desktop_field}`}>ID</div>
          <div className={styles.title}>Table</div>
          <div className={styles.title}>Date</div>
          <div className={`${styles.title} ${styles.desktop_field}`}>Amount</div>
          <div className={`${styles.title} ${styles.desktop_field}`}>Waiter</div>
          <div className={styles.title}>Status</div>
        </div>
        <div className={styles.orders_wrapper}>
          {orders.map((order) => {
            return (
              <Order
                isMobile={isMobile}
                key={order.id}
                {...order}
                isModalActive={isModalActive}
                setModalActive={setModalActive}
              />
            );
          })}
        </div>
      </div>

      <Footer />

      {isModalActive && <Modal id={isModalActive} setModalActive={setModalActive} order={order} />}
    </div>
  );
}

export default OrderList;
