import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import { addCategory } from '../../redux/categoriesSlice';
import { URL as ServerURL } from '../../utils/url.js';
import { toast } from 'react-toastify';
import itemAddStyles from '../ItemAdd/ItemAdd.module.css';
import 'react-toastify/dist/ReactToastify.css';
import closeModal from '../../images/close_modal.svg';

function CategoryAdd({ setAddCategoryModalActive }) {
  const categories = useSelector((state) => state.categories.categories);
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState();
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(null);
  const [parentID, setParentID] = useState('');
  const { id: restaurantID } = useSelector((state) => state.restaurants.currentRestaurant);
  const fileInput = useRef();
  const [values, setValues] = useState({
    categoryName: '',
  });

  const validateCategoryName = (e) => {
    if (e.length < 2) {
      return 'Category name should contain minimum two characters';
    } else if (!/^[A-Za-z' -]+$/.test(e)) {
      return 'Please enter your category name';
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    const newvalues = { ...values, [field]: value };
    setValues(newvalues);
    const newErrors = {
      ...errors,
      [field]: validateField(field, value),
    };
    setErrors(newErrors);
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'name':
        return validateCategoryName(value);
      default:
        return '';
    }
  };

  const handleImageChange = (event) => {
    console.log(event.target.files[0]);
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
    } else {
      console.log('file is not found.');
    }
  };

  const getStatus = (id, status) => {
    if (id) {
      if (status === 'second') {
        const isSecond = categories.find((item) => item.id === +id);
        return isSecond ? 1 : 0;
      } else if (status === 'last') {
        const isLast = categories.find((item) => item.id === +id);
        if (isLast) {
          return isLast.parentID ? 1 : 0;
        } else {
          return 0;
        }
      }
    } else {
      return 0;
    }
  };

  const showToast = () => {
    toast.info('Your category is successfully added.', {
      position: 'top-right',
      autoClose: 900,
      style: {
        color: '#0DA300',
      },
      icon: false,
    });
  };

  const resetInfo = () => {
    setValues({
      categoryName: '',
    });
    setParentID('');
    setFile(null);
    setPreviewImage(null);
    showToast();
    fileInput.current.value = '';
  };

  const showErrorMessage = (message) => {
    toast.info(message, {
      position: 'top-right',
      autoClose: 900,
      style: {
        color: 'red',
      },
      icon: false,
    });
  };

  const handleAddCategories = (e) => {
    e.preventDefault();
    if (values.categoryName.length >= 2) {
      const formData = new FormData();
      formData.append('restaurantID', restaurantID);
      formData.append('file', file);
      formData.append('name', values.categoryName);
      formData.append('parentID', parentID);
      formData.append('isSecondChild', getStatus(parentID, 'second'));
      formData.append('isLastChild', getStatus(parentID, 'last'));
      dispatch(addCategory({ formData, resetInfo, showErrorMessage }));
    }
  };

  function sortNestedArrayByParentId(arr) {
    function sortItem(item) {
      const children = arr.filter((child) => +child.parentID === item.id);
      children.sort((a, b) => a.name.localeCompare(b.name));
      for (const child of children) {
        sortItem(child);
      }
      return children;
    }

    const rootItems = arr.filter((item) => item.parentID === null);

    rootItems.sort((a, b) => a.name.localeCompare(b.name));

    const sortedArray = [];

    function addToSortedArray(item) {
      sortedArray.push(item);
      const children = sortItem(item);
      for (const child of children) {
        addToSortedArray(child);
      }
    }

    for (const rootItem of rootItems) {
      addToSortedArray(rootItem);
    }

    return sortedArray;
  }

  const sortedArray = sortNestedArrayByParentId(categories);

  const handleRemoveIcon = () => {
    setPreviewImage(`${ServerURL}/images/defaultCategoryIcon.png`);
    setFile('default');
  };

  const modalHeight = document.documentElement.scrollHeight;

  return (
    <div
      className={styles.container}
      style={{
        minHeight: modalHeight,
      }}>
      <div className={styles.CategoryAdd}>
        <form onSubmit={handleAddCategories} className={styles.ModalDetailsPart}>
          <img
            src={closeModal}
            alt="close"
            className={styles.close}
            onClick={() => setAddCategoryModalActive(false)}
          />

          <p className={styles.ModalName}>Add Category</p>
          <div className={styles.ModalDivForUserInputs}>
            <select
              className={styles.ModalUserInputs}
              name="ParentCategories"
              value={parentID}
              onChange={(e) => {
                setParentID(e.target.value);
              }}>
              <option defaultValue={''} value={''}>
                Choose parent category
              </option>
              {sortedArray.map((item) => {
                if (item.parentID === null) {
                  return (
                    <option style={{ fontWeight: 'bold' }} key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                } else if (item.isSecondChild) {
                  return (
                    <option style={{ fontWeight: '500' }} key={item.id} value={item.id}>
                      &nbsp;&nbsp;{item.name}
                    </option>
                  );
                } else if (item.isLastChild) {
                  return (
                    <option disabled key={item.id} value={item.id}>
                      &nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                    </option>
                  );
                } else {
                  return null;
                }
              })}
            </select>
            <label className={styles.ModalLabelForUserInputs}>Parent Category</label>
          </div>

          <div className={styles.ModalDivForUserInputs}>
            <input
              className={styles.ModalUserInputs}
              type="text"
              value={values.categoryName}
              onChange={(e) => {
                handleInputChange('categoryName', e.target.value);
              }}
              required
            />
            <label className={styles.ModalLabelForUserInputs}>Category</label>
            {errors.name && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.name}
              </p>
            )}
          </div>
          <div className={styles.ModalDivForRestLogo}>
            <label className={styles.ModalLabelForRestLogo}>Image</label>
            <div className={styles.ModalDivForInputAndLogo}>
              <button
                type="button"
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                  background: '#E7E7E7',
                  boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '4px 20px',
                  marginTop: '10px',
                  cursor: 'pointer',
                  color: '#484848',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  border: 'none',
                }}
                onClick={() => {
                  fileInput.current.click();
                }}>
                Choose file
              </button>
              <input
                style={{
                  opacity: '0',
                  height: '0',
                  width: '0',
                  lineHeight: '0',
                  overflow: 'hidden',
                  padding: '0',
                  margin: '0',
                }}
                ref={fileInput}
                type="file"
                onChange={handleImageChange}
                accept=".png, .jpeg, .jpg, .svg"
              />
              {(!previewImage ||
                previewImage === `${ServerURL}/images/defaultCategoryIcon.png`) && (
                <p
                  style={{
                    fontSize: '12px',
                    fontFamily: 'Inter',
                    color: '#484848',
                    transform: 'translate(20px, 14px)',
                  }}>
                  No file chosen
                </p>
              )}

              {previewImage && previewImage !== `${ServerURL}/images/defaultCategoryIcon.png` && (
                <>
                  <img
                    style={{
                      marginLeft: '20px',
                    }}
                    className={styles.ModalRestImage}
                    src={previewImage}
                    alt="Selected"
                  />
                  <button
                    type="button"
                    onClick={handleRemoveIcon}
                    className={itemAddStyles.removeIconBtn}>
                    X
                  </button>
                </>
              )}
            </div>
          </div>
          <button
            className={styles.ModalSaveButton}
            disabled={values.categoryName.length < 2}
            type="submit">
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default CategoryAdd;
