import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.css';
import TableQrRow from '../TableQrRow/TableQrRow.jsx';
import { v4 as uuidv4 } from 'uuid';
import { addTables, deleteTable, getTables } from '../../redux/tablesSlice.js';
import { useParams } from 'react-router-dom';
import Header from '../Header/Header.jsx';
import BurgerMenu from '../BurgerMenu/BurgerMenu.jsx';
import Footer from '../Footer/Footer.jsx';
import DeleteModal from './DeleteModal.jsx';

function RestTables() {
  const qrUrl = 'https://cafe-click-view1.worx.am';
  const dispatch = useDispatch();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const currentRest = useSelector((state) => state.restaurants.currentRestaurant);
  const [tablesCount, setTablesCount] = useState('1');
  const tables = useSelector((state) => state.tables.tables);
  const toggleSideBarMenu = () => setSideBarOpen(!sideBarOpen);
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [deletedTableId, setDeletedTableId] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getTables({ id: currentRest.id }));
  }, [dispatch, currentRest.id]);

  const changeTablesCount = (e) => {
    setTablesCount(e.target.value);
  };

  const getNumberForAddingNewQR = (i) => {
    if (sortedTables[0]?.number) {
      return +sortedTables[0]?.number + 1 + i;
    } else {
      return 1;
    }
  };

  const handleAddTables = (e) => {
    e.preventDefault();
    const result = [];

    for (let i = 0; i < tablesCount; i++) {
      result.push({
        number: getNumberForAddingNewQR(i),
        id: uuidv4(),
        qr: `${qrUrl}/${currentRest.id}/${getNumberForAddingNewQR(i)}`,
      });
    }
    dispatch(addTables({ id: currentRest.id, tables: result }));
  };

  const handleDeleteTable = (id) => {
    dispatch(deleteTable({ id, setDeletedTableId, setIsDeleteModalActive }));
  };

  const sortedTables = [...tables].sort((a, b) => +a.number - +b.number).reverse();

  console.log(sortedTables);

  return (
    <div className={styles.global_container}>
      <Header toggleSideBarMenu={toggleSideBarMenu} sideBarOpen={sideBarOpen} />

      <BurgerMenu id={id} sideBarOpen={sideBarOpen} page="qr-tables" />

      <div className={styles.container} onClick={() => setSideBarOpen(false)}>
        <form className={styles.form} onSubmit={handleAddTables}>
          <div className={styles.tables_input_wrapper}>
            <label htmlFor="tables" className={styles.label}>
              Number of tables
            </label>
            <input
              onBlur={() => {
                if (!tablesCount || tablesCount === '0') {
                  setTablesCount(1);
                }
              }}
              className={styles.input}
              id="tables"
              type="number"
              value={tablesCount}
              onChange={changeTablesCount}
            />
            <p className={styles.text}>Please enter the number of tables in your restaurant.</p>
          </div>
          <button className={styles.add_btn}>Add</button>
        </form>

        <div className={styles.table}>
          <div className={styles.head}>
            <div className={styles.header_big_item_wrapper_for_table_number}>
              <p className={styles.head_item_big_table_number}>Table Number</p>
            </div>
            <div className={styles.header_big_item_wrapper_for_qr_code}>
              <p className={styles.head_item_big_qr}>QR</p>
            </div>
            <div className={styles.header_item_wrapper}>
              <p className={styles.head_item}>Download QR</p>
            </div>
            <div className={styles.header_item_wrapper}>
              <p className={styles.head_item}>Print QR</p>
            </div>
            <div className={styles.header_item_wrapper}>
              <p className={styles.head_item}>Delete</p>
            </div>
          </div>
          <div className={styles.body}>
            {sortedTables?.map((qrCode) => {
              return (
                <TableQrRow
                  key={qrCode.id}
                  qrCode={qrCode}
                  setDeletedTableId={setDeletedTableId}
                  setIsDeleteModalActive={setIsDeleteModalActive}
                />
              );
            })}
          </div>
        </div>
      </div>

      {isDeleteModalActive ? (
        <DeleteModal
          deletedTableId={deletedTableId}
          setDeletedTableId={setDeletedTableId}
          setIsDeleteModalActive={setIsDeleteModalActive}
          handleDeleteTable={handleDeleteTable}
        />
      ) : (
        ''
      )}

      <Footer />
    </div>
  );
}

export default RestTables;
