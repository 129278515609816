import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './style.module.css';
import closeBtn from '../../images/close_modal.svg';
import Select from 'react-select';
import countries from '../../data/countries.json';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { signupAsEmployee } from '../../redux/adminSlice';
import { FlagIcon } from 'react-flag-kit';

function AddEmployeeModal({ setModalActive, showToast }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [roleSelectValue, setRoleSelectValue] = useState('default');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState({ value: '+1', label: '(+1)', code: 'US' });
  const { id } = useParams();
  const dispatch = useDispatch();
  const [timeOutForBtn, setTimeOutForBtn] = useState(false);
  const [timerFirstName, setTimerFirstName] = useState(null);
  const [timerLastName, setTimerLastName] = useState(null);
  const [timerEmail, setTimerEmail] = useState(null);
  const [timerPhoneNumber, setTimerPhoneNumber] = useState(null);
  const formRef = useRef(null);
  const [message, setMessage] = useState('');

  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    phoneNumber: null,
  });

  const countriesOptions = countries.map((country) => {
    const { dial_code, code } = country;
    return { value: dial_code, label: `(${dial_code})`, code };
  });

  const fullPhoneNumber = `${countryCode.value} ${phoneNumber}`;

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChangeFirstName = (e) => {
    const inputText = e.target.value.trim();
    setFirstName(inputText);
    setErrors({ ...errors, firstName: '' });
    setTimeOutForBtn(true);

    if (timerFirstName) {
      clearTimeout(timerFirstName);
    }

    let time;

    if (
      errors.email === '' &&
      errors.lastName === '' &&
      errors.role === '' &&
      errors.phoneNumber === '' &&
      inputText !== '' &&
      inputText.length >= 2 &&
      !/\d/.test(inputText)
    ) {
      time = 10;
    } else {
      time = 1000;
    }

    const newTimer = setTimeout(() => {
      const containsDigits = /\d/.test(inputText);

      if (inputText === '') {
        setErrors({ ...errors, firstName: 'Please enter your first name' });
      } else if (inputText.length < 2) {
        setErrors({ ...errors, firstName: 'First Name must contain min 2 letters' });
      } else if (containsDigits) {
        setErrors({ ...errors, firstName: 'Please enter only letters' });
      } else {
        setErrors({ ...errors, firstName: '' });
      }
      setTimeOutForBtn(false);
    }, time);

    setTimerFirstName(newTimer);
  };

  const handleChangeLastName = (e) => {
    const inputText = e.target.value.trim();
    setLastName(inputText);
    setErrors({ ...errors, lastName: '' });
    setTimeOutForBtn(true);

    if (timerLastName) {
      clearTimeout(timerLastName);
    }

    let time;

    if (
      errors.email === '' &&
      errors.firstName === '' &&
      errors.role === '' &&
      errors.phoneNumber === '' &&
      inputText !== '' &&
      inputText.length >= 2 &&
      !/\d/.test(inputText)
    ) {
      time = 10;
    } else {
      time = 1000;
    }

    const newTimer = setTimeout(() => {
      const containsDigits = /\d/.test(inputText);

      if (inputText === '') {
        setErrors({ ...errors, lastName: 'Please enter your last name' });
      } else if (inputText.length < 2) {
        setErrors({ ...errors, lastName: 'Last Name must contain min 2 letters' });
      } else if (containsDigits) {
        setErrors({ ...errors, lastName: 'Please enter only letters' });
      } else {
        setErrors({ ...errors, lastName: '' });
      }
      setTimeOutForBtn(false);
    }, time);

    setTimerLastName(newTimer);
  };

  const handleChangeEmail = (e) => {
    const inputText = e.target.value.trim();
    setEmail(inputText);
    setErrors({ ...errors, email: '' });
    setTimeOutForBtn(true);

    if (timerEmail) {
      clearTimeout(timerEmail);
    }

    let time;

    if (
      errors.firstName === '' &&
      errors.lastName === '' &&
      errors.role === '' &&
      errors.phoneNumber === '' &&
      inputText !== '' &&
      inputText.length >= 2 &&
      isValidEmail(inputText)
    ) {
      time = 10;
    } else {
      time = 1000;
    }

    const newTimer = setTimeout(() => {
      if (inputText === '') {
        setErrors({ ...errors, email: 'Please enter your email' });
      } else if (inputText.length < 2) {
        setErrors({ ...errors, email: 'Email must contains min 2 letters' });
      } else if (!isValidEmail(inputText)) {
        setErrors({
          ...errors,
          email: 'Please enter your email address in format yourname@example.com',
        });
      } else {
        setErrors({ ...errors, email: '' });
      }
      setTimeOutForBtn(false);
    }, time);

    setTimerEmail(newTimer);
  };

  const handleChangePhoneNumber = (e) => {
    const inputText = e.target.value.trim();
    setPhoneNumber(inputText);
    setErrors({ ...errors, phoneNumber: '' });
    setTimeOutForBtn(true);

    if (timerPhoneNumber) {
      clearTimeout(timerPhoneNumber);
    }

    let time;

    if (
      errors.email === '' &&
      errors.lastName === '' &&
      errors.role === '' &&
      errors.phoneNumber === '' &&
      inputText !== '' &&
      inputText.length >= 5
    ) {
      time = 10;
    } else {
      time = 1000;
    }

    const newTimer = setTimeout(() => {
      if (inputText === '') {
        setErrors({ ...errors, phoneNumber: 'Please enter your phone number' });
      } else if (inputText.length < 5) {
        setErrors({ ...errors, phoneNumber: 'Phone number must contains min 5 letters' });
      } else {
        setErrors({ ...errors, phoneNumber: '' });
      }
      setTimeOutForBtn(false);
    }, time);

    setTimerPhoneNumber(newTimer);
  };

  const isAddBtnDisabled = () => {
    if (Object.values(errors).every((err) => err === '')) {
      return false;
    }
    return true;
  };

  const handleAddEmployee = useCallback(
    (e, flag) => {
      if (!flag) {
        e.preventDefault();
      }
      dispatch(
        signupAsEmployee({
          setMessage,
          setModalActive,
          showToast,
          restID: id,
          firstName,
          lastName,
          email,
          fullPhoneNumber,
          roleSelectValue,
        }),
      );
    },
    [
      dispatch,
      email,
      firstName,
      lastName,
      fullPhoneNumber,
      id,
      roleSelectValue,
      setModalActive,
      showToast,
    ],
  );

  const submitFormByEnter = useCallback(
    (e) => {
      if (formRef.current.contains(document.activeElement)) {
        return;
      }

      if (e.key === 'Enter') {
        handleAddEmployee(e, true);
      }
      return null;
    },
    [handleAddEmployee],
  );

  const handleChangeRole = (e) => {
    setRoleSelectValue(e.target.value);
    setErrors({ ...errors, role: '' });
  };

  useEffect(() => {
    document.addEventListener('keydown', submitFormByEnter);

    return () => {
      document.removeEventListener('keydown', submitFormByEnter);
    };
  }, [submitFormByEnter]);

  return (
    <div className={styles.modal_container} onClick={() => setModalActive(false)}>
      <form
        ref={formRef}
        onSubmit={handleAddEmployee}
        className={styles.modal_wrapper}
        onClick={(e) => e.stopPropagation()}>
        <div className={styles.close_btn_wrapper}>
          <img
            src={closeBtn}
            alt="close"
            onClick={() => setModalActive(false)}
            className={styles.close_btn}
          />
        </div>
        <div className={styles.title_wrapper}>
          <h2 className={styles.title}>Add Employees</h2>
        </div>
        <div className={styles.two_inputs_wrapper}>
          <div className={styles.error_and_input_wrapper}>
            <input
              style={{
                border: errors.firstName ? '1px solid red' : '1px solid #c6c6c6',
              }}
              type="text"
              placeholder="First Name"
              className={styles.input}
              value={firstName}
              onChange={handleChangeFirstName}
            />
            <span className={styles.error_message}>{errors.firstName || ''}</span>
          </div>
          <div className={styles.error_and_input_wrapper}>
            <input
              style={{
                border: errors.lastName ? '1px solid red' : '1px solid #c6c6c6',
              }}
              type="text"
              placeholder="Last Name"
              className={styles.input}
              value={lastName}
              onChange={handleChangeLastName}
            />
            <span className={styles.error_message}>{errors.lastName || ''}</span>
          </div>
        </div>
        <div className={styles.two_inputs_wrapper}>
          <div className={styles.error_and_input_wrapper}>
            <input
              style={{
                border: errors.email ? '1px solid red' : '1px solid #c6c6c6',
              }}
              type="text"
              placeholder="Email"
              className={styles.input}
              value={email}
              onChange={handleChangeEmail}
            />
            <span className={styles.error_message}>{errors.email || ''}</span>
          </div>
          <div className={styles.select_wrapper}>
            <select value={roleSelectValue} onChange={handleChangeRole} className={styles.select}>
              {roleSelectValue === 'default' && <option value="default">Role</option>}
              <option value="manager">Manager</option>
              <option value="waiter">Waiter</option>
            </select>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
          }}>
          <div
            className={styles.phoneNumberWrapper}
            style={{
              border: errors.phoneNumber ? '1px solid red' : '1px solid #c6c6c6',
            }}>
            <Select
              options={countriesOptions}
              value={countryCode}
              onChange={setCountryCode}
              getOptionLabel={(e) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}>
                    <FlagIcon code={e.code} size={18} /> <span>{e.label}</span>
                  </div>
                );
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minWidth: state.isFocused ? '104.66px' : null,
                  boxShadow: state.isFocused ? 'none' : null,
                  borderColor: state.isFocused ? '#ccc' : null,
                  cursor: 'pointer',
                  border: state.isFocused ? 'none' : 'none',
                  outline: state.isFocused ? 'none' : 'none',
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: '170px',
                }),
              }}
            />
            <input
              style={{
                paddingLeft: '16px',
              }}
              type="number"
              placeholder="123456789"
              className={styles.input_phone_number}
              value={phoneNumber}
              onChange={handleChangePhoneNumber}
              onKeyDown={(e) => {
                if (
                  e.key === 'e' ||
                  e.key === 'E' ||
                  e.key === '+' ||
                  e.key === '-' ||
                  e.key === '/' ||
                  e.key === '*'
                ) {
                  e.preventDefault();
                }
              }}
            />
            <span
              style={{
                top: '76%',
                left: '30%',
              }}
              className={styles.error_message}>
              {errors.phoneNumber || ''}
            </span>
          </div>
        </div>
        <div className={styles.error_message_wrapper}>
          <span className={styles.error_message_global}>{message}</span>
        </div>
        <div className={styles.add_btn_wrapper}>
          <button
            type="submit"
            disabled={timeOutForBtn || isAddBtnDisabled()}
            className={styles.add_btn}>
            Add
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddEmployeeModal;
