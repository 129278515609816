import MenuTemplateStyles from './MenuTemplate.module.css';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import template1 from '../../images/template_1.png';
import template2 from '../../images/template_2.png';
import template3 from '../../images/template_3.png';
import template4 from '../../images/template_4.png';
import template5 from '../../images/template_5.png';

function MenuTemplate() {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const toggleSideBarMenu = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const { id } = useParams();

  return (
    <div className={MenuTemplateStyles.RestRegContainer}>
      <Header toggleSideBarMenu={toggleSideBarMenu} sideBarOpen={sideBarOpen} />

      <BurgerMenu id={id} sideBarOpen={sideBarOpen} page="menu-template" />

      <div className={MenuTemplateStyles.wrapper} onClick={() => setSideBarOpen(false)}>
        <div className={MenuTemplateStyles.customDesignContainer}>
          <span className={MenuTemplateStyles.spanForCustomDesignPart}></span>
          <div className={MenuTemplateStyles.customDesignAdvrt}>
            <p>If you want a custom design for your restaurant, contact us.</p>
          </div>
          <div className={MenuTemplateStyles.contactPart}>
            <p className={MenuTemplateStyles.emailContact}>info@worx.am</p>
            <hr style={{ width: '125px' }} />
            <p className={MenuTemplateStyles.phoneContact}>+374 41 969 620</p>
          </div>
        </div>

        <div>
          <p className={MenuTemplateStyles.MenuTemplateName}>Menu Templates</p>
          <div className={MenuTemplateStyles.divForMenuTemplates}>
            <div className={MenuTemplateStyles.Templates}>
              <img src={template1} alt="template_1" className={MenuTemplateStyles.template_image} />
            </div>
            <div className={MenuTemplateStyles.Templates}>
              <img src={template2} alt="template_2" className={MenuTemplateStyles.template_image} />
            </div>
            <div className={MenuTemplateStyles.Templates}>
              <img src={template3} alt="template_3" className={MenuTemplateStyles.template_image} />
            </div>
            <div className={MenuTemplateStyles.Templates}>
              <img src={template4} alt="template_4" className={MenuTemplateStyles.template_image} />
            </div>
            <div className={MenuTemplateStyles.Templates}>
              <img
                src={template5}
                alt="template_5s"
                className={MenuTemplateStyles.template_image}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MenuTemplate;
