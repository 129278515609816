import React, { useState } from 'react';
import styles from '../style.module.css';

function DropDownForTableNumberAndWaiter({
  orders,
  item,
  filter,
  setFilter,
  setFilterSelectActive,
  infoAboutTableNumbersAndWaiters,
}) {
  const [inputValue, setInputValue] = useState('');

  let data;
  let type;

  if (item === 'tableNumber') {
    // data = orders.map((order) => +order.tableNumber).sort((a, b) => a - b);
    data = infoAboutTableNumbersAndWaiters.tableNumbers.sort((a, b) => a - b);
    type = 'number';
  } else {
    // data = orders.map((order) => order.waiterName);
    data = infoAboutTableNumbersAndWaiters.waiterNames;
    type = 'text';
  }

  const valuesObj = filter[item]?.reduce((acc, current) => {
    acc[current] = true;
    return acc;
  }, {});

  let valuesObjFromData = data.reduce((acc, current) => {
    acc[current] = false;
    return acc;
  }, {});

  valuesObjFromData = { ...valuesObjFromData, ...valuesObj };

  const [checkedValues, setCheckedValues] = useState(valuesObjFromData);

  const filteredData = data.filter((item) => {
    return item.toString().toLowerCase().includes(inputValue.toLowerCase());
  });

  const handleApplyFilters = () => {
    const result = [];

    for (let key in checkedValues) {
      if (checkedValues[key] === true) {
        result.push(key);
      }
    }

    setFilter({
      ...filter,
      [item]: result.length ? result : null,
    });
    setFilterSelectActive(null);
  };

  const isChecked = (value) => checkedValues[value];

  const handleChangeInputValue = (e) => {
    const number = e.target.value;
    setInputValue(number);
  };

  const unickFilteredData = [...new Set(filteredData)];

  return (
    <div className={styles.dropDownWrapper}>
      <input
        type={type}
        className={styles.tableNumber_and_waiter_input}
        placeholder="Search"
        value={inputValue}
        onChange={handleChangeInputValue}
      />
      <div className={styles.dropDownArrWrapper}>
        {unickFilteredData.map((item) => {
          return (
            <div key={Math.random()} className={styles.dropDown_checkbox_wrapper}>
              <input
                checked={isChecked(item.toString())}
                value={item}
                type="checkbox"
                id={item}
                className={styles.checkbox_input}
                onChange={(e) => {
                  const { value } = e.target;
                  setCheckedValues({ ...checkedValues, [value]: !checkedValues[value] });
                }}
              />
              <label htmlFor={item} className={styles.checkbox_label}>
                {item}
              </label>
            </div>
          );
        })}
      </div>
      <button className={styles.apply_btn} onClick={handleApplyFilters}>
        Apply
      </button>
    </div>
  );
}

export default DropDownForTableNumberAndWaiter;
