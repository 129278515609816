import React, { useState } from 'react';
import { BiSolidDownArrow } from 'react-icons/bi';
import styles from '../style.module.css';
import DropDownForTableNumberAndWaiter from './DropDownForTableNumberAndWaiter.jsx';
import closeBtn from '../../../images/close_icon.svg';
import DropDownForOrderDate from './DropDownForOrderDate.jsx';
import DropDownForAmount from './DropDownForAmount.jsx';

function FilterItem({
  setFilterSelectActive,
  isFilterSelectActive,
  item,
  filter,
  setFilter,
  orders,
  infoAboutTableNumbersAndWaiters,
}) {
  const [isFullText, setIsFullText] = useState(false);

  const getFilterName = () => {
    if (item === 'tableNumber') {
      return 'Table Number';
    } else if (item === 'waiter') {
      return 'Waiter';
    } else if (item === 'orderDate') {
      return 'Order Date';
    } else if (item === 'amount') {
      return 'Amount';
    } else {
      return null;
    }
  };

  const getSelectedFilters = () => {
    const text = filter[item]?.join(', ') || '';
    let length = item === 'tableNumber' ? 20 : 14;

    if (isFullText) {
      return text;
    }

    if (text.length <= length) {
      return text;
    } else {
      return text.slice(0, length) + ' ...';
    }
  };

  const handleToggleText = () => {
    setIsFullText(!isFullText);
  };

  const handleRemoveFilterItem = () => {
    setFilter({
      ...filter,
      [item]: null,
    });
  };

  const getCountForOrderDateFilter = () => {
    if (filter[item]?.min && filter[item]?.max) {
      return 2;
    }

    if (filter[item]?.min && !filter[item]?.max) {
      return 1;
    }

    if (!filter[item]?.min && filter[item]?.max) {
      return 1;
    }
  };
  const handleRemoveDateFilter = () => {
    setFilter({ ...filter, orderDate: { min: null, max: null } });
  };

  const getSelectedDateText = () => {
    if (filter[item]?.min && !filter[item]?.max) {
      return `From - ${filter[item]?.min}`;
    }

    if (filter[item]?.max && !filter[item]?.min) {
      return `To - ${filter[item]?.max}`;
    }

    if (filter[item]?.min && filter[item]?.max) {
      return `${filter[item]?.min} - ${filter[item]?.max}`;
    }

    return null;
  };

  const getSelectAmountPrices = () => {
    if (filter[item]?.from && filter[item]?.to) {
      return `${filter[item]?.from} - ${filter[item]?.to}`;
    }

    if (filter[item]?.from && !filter[item]?.to) {
      return `From - ${filter[item]?.from}`;
    }

    if (!filter[item]?.from && filter[item]?.to) {
      return `To - ${filter[item]?.to}`;
    }
  };

  const getStylesForDate = () => {
    if (filter[item]?.min && !filter[item]?.max) {
      return true;
    }
    if (filter[item]?.max && !filter[item]?.min) {
      return true;
    }
    return false;
  };

  const getCountForAmountFilter = () => {
    console.log(1);
    if (filter[item]?.from && filter[item]?.to) {
      return 2;
    }
    if (filter[item]?.from || !filter[item]?.to) {
      return 1;
    }
    if (!filter[item]?.from || filter[item]?.to) {
      return 1;
    }
  };

  return (
    <div className={styles.filter_container}>
      <div
        className={styles.filter_btn}
        onClick={() =>
          setFilterSelectActive((activeSelect) => {
            if (activeSelect === item) {
              return null;
            } else {
              return item;
            }
          })
        }>
        <span className={styles.filter_text}>{getFilterName()}</span>
        <BiSolidDownArrow
          color={'#C4C4C4'}
          size={14}
          className={
            isFilterSelectActive === item ? `${styles.arrow} ${styles.rotate}` : styles.arrow
          }
        />
      </div>
      {!!filter[item]?.length && (
        <div className={styles.filter_count}>
          <span className={styles.filter_count_text}>{filter[item].length}</span>
        </div>
      )}
      {(filter[item]?.min || filter[item]?.max) && (
        <div className={styles.filter_count}>
          <span className={styles.filter_count_text}>{getCountForOrderDateFilter()}</span>
        </div>
      )}
      {filter[item] && item === 'amount' && (filter[item]?.from || filter[item]?.to) && (
        <div className={styles.filter_count}>
          <span className={styles.filter_count_text}>{getCountForAmountFilter()}</span>
        </div>
      )}
      {(item === 'tableNumber' || item === 'waiter') && isFilterSelectActive === item && (
        <DropDownForTableNumberAndWaiter
          infoAboutTableNumbersAndWaiters={infoAboutTableNumbersAndWaiters}
          orders={orders}
          item={item}
          filter={filter}
          setFilter={setFilter}
          setFilterSelectActive={setFilterSelectActive}
        />
      )}
      {item === 'orderDate' && isFilterSelectActive === item && (
        <DropDownForOrderDate
          filter={filter}
          setFilter={setFilter}
          setFilterSelectActive={setFilterSelectActive}
        />
      )}

      {item === 'amount' && isFilterSelectActive === item && (
        <DropDownForAmount
          filter={filter}
          setFilter={setFilter}
          setFilterSelectActive={setFilterSelectActive}
        />
      )}

      {item === 'orderDate' && (filter[item]?.min || filter[item]?.max) && (
        <div
          className={styles.selected_filters_wrapper}
          style={{
            maxWidth: 'none',
            width: 'none',
            marginLeft: getStylesForDate() ? '10px' : '-10px',
          }}>
          <div className={styles.selected_filters_text}>{getSelectedDateText()}</div>
          <img
            className={styles.selected_filters_close_btn}
            src={closeBtn}
            alt="close"
            onClick={handleRemoveDateFilter}
          />
        </div>
      )}

      {filter[item]?.length ? (
        <div className={styles.selected_filters_wrapper}>
          <div
            className={`${styles.selected_filters_text} ${isFullText ? styles.fullText : ''}`}
            onClick={handleToggleText}>
            {getSelectedFilters()}
          </div>
          <img
            className={styles.selected_filters_close_btn}
            src={closeBtn}
            alt="close"
            onClick={handleRemoveFilterItem}
          />
        </div>
      ) : null}

      {item === 'amount' && (filter[item]?.from || filter[item]?.to) && (
        <div className={styles.selected_filters_wrapper}>
          <span className={styles.selected_filters_text}>{getSelectAmountPrices()}</span>
          <img
            className={styles.selected_filters_close_btn}
            src={closeBtn}
            alt="close"
            onClick={handleRemoveFilterItem}
          />
        </div>
      )}
    </div>
  );
}

export default FilterItem;
