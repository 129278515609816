import React from 'react';
import styles from './style.module.css';
import closeModal from '../../images/close_modal.svg';

function ItemDeleteModal({ setItemDeleteModalActive, setDeletedItemInfo, handleDeleteItem }) {
  const modalHeight = document.documentElement.scrollHeight;

  return (
    <div
      className={styles.container}
      onClick={() => {
        setDeletedItemInfo(null);
        setItemDeleteModalActive(false);
      }}
      style={{
        height: modalHeight,
      }}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <h3 className={styles.title}>Are you sure?</h3>
        <img
          src={closeModal}
          alt="close"
          className={styles.close}
          onClick={() => {
            setItemDeleteModalActive(false);
            setDeletedItemInfo(null);
          }}
        />
        <p className={styles.text}>This action will delete all your information.</p>
        <div className={styles.modal_btns}>
          <button onClick={handleDeleteItem} className={styles.remove_btn}>
            Delete
          </button>
          <button
            className={styles.cancel_btn}
            onClick={() => {
              setItemDeleteModalActive(false);
              setDeletedItemInfo(null);
            }}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ItemDeleteModal;
