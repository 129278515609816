import { useDispatch, useSelector } from 'react-redux';
import MenuStructureStyles from './MenuStructure/MenuStructure.module.css';
import deleteCategoryIcon from '../images/deleteCategoryIcon.svg';
import editCategoryIcon from '../images/editCategoryIcon.svg';
import { useCallback, useRef, useState } from 'react';
import ctgrOpen from '../images/ctgrOpen.svg';
import ctgrClose from '../images/ctgrClose.svg';
import { changeCategory } from '../redux/categoriesSlice';
import { URL as serverURL } from '../utils/url';
import { RiUpload2Line } from 'react-icons/ri';
import ItemsRow from './ItemsRow';

function CategoryRow({
  id,
  logo,
  name,
  parentID,
  index,
  setDeleteModalActive,
  setDeletedCategoryInfo,
  setEditModeID,
  editModeID,
  setDeletedItemInfo,
  setItemDeleteModalActive,
}) {
  const nameRef = useRef(null);
  const dispatch = useDispatch();
  const [isChildShow, setChildShow] = useState(false);
  const childrenCategories = useSelector((state) => state.categories.categories).filter(
    (item) => item.parentID === id.toString(),
  );
  const items = useSelector((state) => state.items.items).filter((item) => +item.categoryID === id);
  const { id: restaurantID } = useSelector((state) => state.restaurants.currentRestaurant);
  const inputFile = useRef();
  const [file, setFile] = useState(null);
  const [editedName, setEditedName] = useState(name);
  const [previewImage, setPreviewImage] = useState(`${serverURL}/${logo}`);

  const handleChangeIcon = (event) => {
    setFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleChangeCategory = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('editedName', editedName);
    formData.append('restaurantID', restaurantID);
    dispatch(changeCategory({ id, formData }));
    setEditModeID(null);
  };

  const cancelEditMode = useCallback(() => {
    inputFile.current.value = '';
    setFile(null);
    setPreviewImage(`${serverURL}/${logo}`);
    setEditedName(name);
    setEditModeID(null);
  }, [name, setEditModeID, logo]);

  const handleRemoveIcon = () => {
    setPreviewImage(`${serverURL}/images/defaultCategoryIcon.png`);
    setFile('default');
  };

  return (
    <div style={{ marginLeft: parentID ? '5.1%' : '' }} id={id}>
      <div className={`${MenuStructureStyles.divForEachCategoryRow} ${id}`}>
        <div
          className={
            !isChildShow
              ? MenuStructureStyles.categoryNameUpDownArrow
              : MenuStructureStyles.categoryNameUpDownArrowSubcategory
          }>
          {isChildShow ? (
            <img
              alt="show"
              onClick={() => setChildShow(false)}
              style={{ cursor: 'pointer' }}
              src={ctgrClose}
            />
          ) : (
            <img
              alt="show"
              onClick={() => setChildShow(true)}
              style={{ cursor: 'pointer' }}
              src={ctgrOpen}
            />
          )}
          {editModeID === id ? (
            <input
              ref={nameRef}
              type="text"
              className={MenuStructureStyles.edit_input}
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
            />
          ) : (
            <p
              className={MenuStructureStyles.stylesForCategoryName}
              onClick={() => (isChildShow ? setChildShow(false) : setChildShow(true))}>
              {index}. {name}
            </p>
          )}
        </div>
        <div className={`${MenuStructureStyles.categoryImageWithEditDeleteIcons} ${id}`}>
          <div className={MenuStructureStyles.divForImageOfCategory}>
            {editModeID === id && (
              <RiUpload2Line
                cursor={'pointer'}
                size={26}
                style={{
                  position: 'absolute',
                  top: '16px',
                  left: '30px',
                  zIndex: '99999999',
                }}
                onClick={() => {
                  inputFile.current.click();
                }}
              />
            )}
            <input
              ref={inputFile}
              type="file"
              onChange={handleChangeIcon}
              className={MenuStructureStyles.input_file}
            />
            <img
              style={{
                width: '43px',
                height: '43px',
                objectFit: 'contain',
                opacity: editModeID === id ? '30%' : '100%',
              }}
              src={previewImage}
              alt="previewImage"
            />
            {editModeID === id &&
              previewImage !== `${serverURL}/images/defaultCategoryIcon.png` && (
                <button onClick={handleRemoveIcon} className={MenuStructureStyles.removeIconBtn}>
                  X
                </button>
              )}
          </div>
          <div className={MenuStructureStyles.editAndDeleteIconsDiv}>
            {editModeID === id ? (
              <div className={MenuStructureStyles.actions_wrapper}>
                <button
                  className={`${MenuStructureStyles.actions_btn} ${MenuStructureStyles.btn_ok}`}
                  onClick={handleChangeCategory}>
                  Save
                </button>
                <button
                  className={`${MenuStructureStyles.actions_btn} ${MenuStructureStyles.btn_cancel}`}
                  onClick={cancelEditMode}>
                  Cancel
                </button>
              </div>
            ) : (
              <img
                className="editBtn"
                style={{ cursor: 'pointer' }}
                src={editCategoryIcon}
                alt="edit"
                onClick={() => {
                  setEditModeID(id);
                  setTimeout(() => {
                    nameRef.current && nameRef.current.focus();
                  }, 500);
                }}
              />
            )}
            <img
              style={{ cursor: 'pointer' }}
              src={deleteCategoryIcon}
              alt=""
              onClick={() => {
                setDeleteModalActive(true);
                setDeletedCategoryInfo({ id, restaurantID });
              }}
            />
          </div>
        </div>
      </div>

      <div>
        {isChildShow &&
          childrenCategories.map((child, index_2) => {
            return (
              <CategoryRow
                setEditModeID={setEditModeID}
                editModeID={editModeID}
                setDeleteModalActive={setDeleteModalActive}
                setDeletedCategoryInfo={setDeletedCategoryInfo}
                setDeletedItemInfo={setDeletedItemInfo}
                setItemDeleteModalActive={setItemDeleteModalActive}
                key={child.id}
                {...child}
                index={`${index}.${index_2 + 1}`}
              />
            );
          })}
        {isChildShow &&
          items.map((item) => {
            return (
              <ItemsRow
                key={item.id}
                {...item}
                setDeletedItemInfo={setDeletedItemInfo}
                setItemDeleteModalActive={setItemDeleteModalActive}
              />
            );
          })}
      </div>
    </div>
  );
}

export default CategoryRow;
