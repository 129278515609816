import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CiCalendarDate } from 'react-icons/ci';

const CustomInput = forwardRef(({ onClick }, ref) => (
  <div className="custom-date-input" onClick={onClick} ref={ref} style={{ cursor: 'pointer' }}>
    <CiCalendarDate size={30} />
  </div>
));

function Calendar({ dates, setDates, forFrom }) {
  const handleChangeDate = (date) => {
    let currentDate = forFrom ? 'fromDate' : 'toDate';
    setDates({ ...dates, [currentDate]: date });
  };

  const getMinDate = () => {
    if (forFrom) {
      return null;
    } else {
      return dates.fromDate;
    }
  };

  const getMaxDate = () => {
    if (forFrom) {
      return dates.toDate || new Date();
    } else {
      return new Date();
    }
  };

  const getSelectedDate = () => {
    if (forFrom) {
      return dates.fromDate;
    } else {
      return dates.toDate;
    }
  };

  return (
    <DatePicker
      selected={getSelectedDate()}
      onChange={handleChangeDate}
      dateFormat="dd/mm/yyyy"
      maxDate={getMaxDate()}
      minDate={getMinDate()}
      customInput={<CustomInput />}
    />
  );
}

export default Calendar;
