import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import eyeOn from '../../images/eye.svg';
import eyeOff from '../../images/eyeOff.svg';
import styles from './styles.module.css';
import { URL } from '../../utils/url';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../redux/adminSlice';
import LoadingPage from '../LoadingPage/LoadingPage';
import done from '../../images/done.svg';
import cafeClickLogo from '../../images/cafeClickLogoWithText.png';

function ResetPassword() {
  const navigate = useNavigate();
  const [IsValidToken, setValidToken] = useState(false);
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [email, setEmail] = useState('');
  const message = useSelector((state) => state.admin.message);
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  });

  const validatePassword = (e) => {
    if (!e) {
      return 'Please enter a password';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(e)) {
      return 'Password must contain min 8 charachters, capital letters, numbers or symbols';
    } else return '';
  };

  const validateConfirmPassword = (value) => {
    if (value !== values.password) {
      return 'Passwords do not match';
    } else if (values.password === '') {
      return 'Please enter a password';
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    const newvalues = { ...values, [field]: value };
    setValues(newvalues);
    const newErrors = {
      ...errors,
      [field]: validateField(field, value),
    };
    setErrors(newErrors);
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'password':
        return validatePassword(value);
      case 'confirmPassword':
        return validateConfirmPassword(value);
      default:
        return '';
    }
  };

  const { token } = useParams();

  useEffect(() => {
    fetch(`${URL}/admins/checkEmailToken`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ token }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res.message === 'success') {
          setValidToken(true);
          setEmail(res.email);
        } else {
          setValidToken(false);
        }
        setShowComponent(true);
      });
  }, [token]);

  const handleResetPassword = (e) => {
    e.preventDefault();
    dispatch(resetPassword({ password: values.password, email }));
  };

  if (!showComponent) {
    return <LoadingPage />;
  }

  if (showComponent && IsValidToken) {
    return (
      <div className={styles.resetPassword}>
        <div className={styles.bothSideRP}>
          <div className={styles.leftSideRP}>
            <img
              src={cafeClickLogo}
              alt="Cafe Click"
              style={{ width: '192px', height: '86px', objectFit: 'contain' }}
            />
            <div className={styles.leftSideBottomInfoRP}>
              <p>Welcome</p>
              <span>Step into Your Account The Journey Continues!</span>
            </div>
          </div>
          <div className={styles.rightSideRP}>
            {message === 'password is changed!' && (
              <div className={styles.successfully_changed_component_wrapper}>
                <img src={done} alt="done" />
                <div className={styles.text_wrapper}>
                  <span className={styles.text_of_changed_password}>
                    Your password has been changed successfully.
                  </span>
                </div>
                <button className={styles.sign_in_btn} onClick={() => navigate('/signin')}>
                  Sign in
                </button>
              </div>
            )}

            {message !== 'password is changed!' && (
              <div className={styles.rightSideInfoRP}>
                <p className={styles.signUpHeaderRP}>Reset Password</p>

                <form className={styles.formForInputsRP} onSubmit={handleResetPassword}>
                  <div className={styles.divForUserInputsRP}>
                    <input
                      className={styles.userInputsRP}
                      id="firstPasswordEye"
                      type={showPassword1 ? 'text' : 'password'}
                      value={values.password}
                      onChange={(e) => handleInputChange('password', e.target.value)}
                      required
                    />
                    <label className={styles.labelForUserInputsRP}>Password</label>
                    {errors.password && (
                      <p
                        style={{
                          color: '#FF0000',
                          fontSize: 10,
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                          textIndent: 7,
                        }}>
                        {errors.password}
                      </p>
                    )}
                    <img
                      className={styles.passwordEye}
                      src={showPassword1 ? eyeOff : eyeOn}
                      onClick={() => setShowPassword1(!showPassword1)}
                      alt=""
                    />
                  </div>
                  <div className={styles.divForUserInputsRP}>
                    <input
                      className={styles.userInputsRP}
                      id="secondPasswordEye"
                      type={showPassword2 ? 'text' : 'password'}
                      value={values.confirmPassword}
                      onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                      required
                    />
                    <label className={styles.labelForUserInputsRP}>Confirm Password</label>
                    {errors.confirmPassword && (
                      <p
                        style={{
                          color: '#FF0000',
                          fontSize: 10,
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          wordWrap: 'break-word',
                          textIndent: 7,
                        }}>
                        {errors.confirmPassword}
                      </p>
                    )}
                    <img
                      className={styles.passwordEye}
                      src={showPassword2 ? eyeOff : eyeOn}
                      onClick={() => setShowPassword2(!showPassword2)}
                      alt=""
                    />
                  </div>

                  <button className={styles.resetButton}>Reset</button>
                </form>

                <div className={styles.rightSideBottomInfoRP}>
                  <p>Don't have an account?</p>
                  <button className={styles.SignUpButton} onClick={() => navigate('/signup')}>
                    Sign up
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (showComponent && !IsValidToken) {
    return (
      <div className={styles.resetPassword}>
        <div className={styles.bothSideRP}>
          <div className={styles.leftSideRP}>
            <p className={styles.cafeClickRP}>Cafe_Click</p>
            <div className={styles.leftSideBottomInfoRP}>
              <p>Welcome</p>
              <span>Fill the form to become member of team</span>
            </div>
          </div>
          <div className={styles.rightSideRP}>
            <div className={styles.rightSideInfoRP}>
              <p className={styles.signUpHeaderRP}>Reset Password</p>
              <h1>Время вышло!</h1>
              <button className={styles.resetButton}>Reset</button>
              <div className={styles.rightSideBottomInfoRP}>
                <p>Don't have an account?</p>
                <button className={styles.SignUpButton} onClick={() => navigate('/signup')}>
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
