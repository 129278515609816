// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__MYIUj {
  background-color: #ffffff;
  height: 80px;
  width: 100%;
  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
  padding: 0px 20px;
  margin-top: auto;
}

.style_restName__vaijq {
  color: #42715d;
  text-align: left;
  vertical-align: text-top;
  font-size: 16px;
  font-family: Knewave;
  line-height: auto;
  border-style: hidden;
  outline: none;
  white-space: nowrap;
}

.style_right_block_text__AQvHt {
  white-space: nowrap;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.style_worx_logo__JEWz8 {
  height: 32px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/style.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,qDAAqD;EACrD,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU;EACV,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,wBAAwB;EACxB,eAAe;EACf,oBAAoB;EACpB,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".wrapper {\n  background-color: #ffffff;\n  height: 80px;\n  width: 100%;\n  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 4;\n  padding: 0px 20px;\n  margin-top: auto;\n}\n\n.restName {\n  color: #42715d;\n  text-align: left;\n  vertical-align: text-top;\n  font-size: 16px;\n  font-family: Knewave;\n  line-height: auto;\n  border-style: hidden;\n  outline: none;\n  white-space: nowrap;\n}\n\n.right_block_text {\n  white-space: nowrap;\n  display: flex;\n  justify-content: center;\n  gap: 10px;\n  align-items: center;\n}\n\n.worx_logo {\n  height: 32px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__MYIUj`,
	"restName": `style_restName__vaijq`,
	"right_block_text": `style_right_block_text__AQvHt`,
	"worx_logo": `style_worx_logo__JEWz8`
};
export default ___CSS_LOADER_EXPORT___;
