import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { URL } from '../../utils/url';
import sideBarMenu from '../../images/menuSideBar.svg';
import cafeClickIcon from '../../images/cafeClickIcon.svg';
import blackCloseIcon from '../../images/blackCloseIcon.svg';
import partnerLogo from '../../images/partner_logo.svg';
import settings from '../../images/user_settings.svg';
import styles from './style.module.css';
import myRestsIcon from '../../images/my_rests_icon.svg';
import { useNavigate } from 'react-router';
import LogoutModal from './LogoutModal';

function Header({
  toggleSideBarMenu,
  sideBarOpen,
  adminHeader,
  inSettings,
  inVerifyPage,
  isVerified,
}) {
  const currentRest = useSelector((state) => state.restaurants.currentRestaurant);
  const navigate = useNavigate();
  const [isLogoutModalActive, setLogoutModalActive] = useState(false);

  if (!adminHeader) {
    return (
      <>
        {isLogoutModalActive && <LogoutModal setLogoutModalActive={setLogoutModalActive} />}
        <header id="header" className={styles.wrapper}>
          <img
            id="burgerMenuToggleImage"
            src={sideBarOpen === false ? sideBarMenu : blackCloseIcon}
            alt="menu"
            className={sideBarOpen === false ? styles.sideBarMenuOpened : styles.sideBarMenuClosed}
            onClick={toggleSideBarMenu}
          />

          <div style={{ display: 'flex', alignItems: 'center', columnGap: '25px' }}>
            <img
              src={`${URL}/${currentRest.logo}`}
              alt="restaurant"
              style={{ width: '38px', height: '37px', objectFit: 'contain' }}
            />
            <img src={partnerLogo} alt="partner" />
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
              <img
                src={cafeClickIcon}
                alt="restaurant"
                style={{ width: '28px', height: '37px', objectFit: 'contain' }}
              />
              <p className={styles.restaurantName}>Cafe Click</p>
            </div>
          </div>
          <button className={styles.logOutBtn} onClick={() => setLogoutModalActive(true)}>
            Log out
          </button>
        </header>
      </>
    );
  } else {
    return (
      <>
        {isLogoutModalActive && <LogoutModal setLogoutModalActive={setLogoutModalActive} />}
        <header className={styles.wrapper}>
          {inSettings ? (
            <img
              src={myRestsIcon}
              alt="burgerMenu"
              className={styles.settings_image}
              onClick={() => navigate('/my-restaurants')}
            />
          ) : (
            <img
              src={settings}
              alt="burgerMenu"
              className={styles.settings_image}
              onClick={() => {
                if (inVerifyPage && isVerified) {
                  navigate('/settings');
                } else {
                  navigate('/settings');
                }
              }}
            />
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={cafeClickIcon}
              alt=""
              style={{ width: '28px', height: '37px', objectFit: 'contain' }}
            />
            <p className={styles.restaurantName}>Cafe Click</p>
          </div>
          <button className={styles.logOutBtn} onClick={() => setLogoutModalActive(true)}>
            Log out
          </button>
        </header>
      </>
    );
  }
}

export default Header;
