import React, { useState } from 'react';
import styles from '../style.module.css';
import Calendar from './Calendar';
import closeBtn from '../../../images/close_icon.svg';

function DropDownForOrderDate({ filter, setFilter, setFilterSelectActive }) {
  let minDate = null;
  let maxDate = null;

  if (filter?.orderDate?.min) {
    const [day, month, year] = filter.orderDate.min.split('.');
    minDate = new Date(year, month - 1, day);
  }
  if (filter?.orderDate?.max) {
    const [day, month, year] = filter.orderDate.max.split('.');
    maxDate = new Date(year, month - 1, day);
  }

  const [dates, setDates] = useState({
    fromDate: minDate,
    toDate: maxDate,
  });

  const handleApplyFilters = () => {
    setFilter({
      ...filter,
      orderDate: {
        min: dates.fromDate ? getCorrectDate(dates.fromDate) : null,
        max: dates.toDate ? getCorrectDate(dates.toDate) : null,
      },
    });
    setFilterSelectActive(null);
  };

  const getCorrectDate = (date) => {
    if (!date) {
      return '-';
    } else {
      return date.toLocaleDateString();
    }
  };

  const removeDate = (date) => {
    if (date === 'from') {
      setDates({ ...dates, fromDate: null });
    } else {
      setDates({ ...dates, toDate: null });
    }
  };

  return (
    <div className={styles.dropDownWrapper}>
      <div className={styles.dateFilterWrapper}>
        <div className={styles.dateFilterItem_wrapper}>
          <span className={styles.calendar_text}>From</span>
          <Calendar dates={dates} setDates={setDates} forFrom={true} />
          <div
            style={{
              position: 'relative',
            }}>
            <span className={styles.currentDateText}>{getCorrectDate(dates.fromDate)}</span>
            {!!dates.fromDate && (
              <img
                className={styles.selected_dates_close_btn}
                src={closeBtn}
                alt="close"
                onClick={() => removeDate('from')}
              />
            )}
          </div>
        </div>
        <div className={styles.dateFilterItem_wrapper}>
          <span className={styles.calendar_text}>To</span>
          <Calendar dates={dates} setDates={setDates} forFrom={false} />
          <div
            style={{
              position: 'relative',
            }}>
            <span className={styles.currentDateText}>{getCorrectDate(dates.toDate)}</span>
            {!!dates.toDate && (
              <img
                className={styles.selected_dates_close_btn}
                src={closeBtn}
                alt="close"
                onClick={() => removeDate('to')}
              />
            )}
          </div>
        </div>
      </div>
      <button className={styles.apply_btn} onClick={handleApplyFilters}>
        Apply
      </button>
    </div>
  );
}

export default DropDownForOrderDate;
