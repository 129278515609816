import React, { useState } from 'react';
import styles from '../style.module.css';

function DropDownForAmount({ filter, setFilter, setFilterSelectActive }) {
  const notNumbers = ['+', '-', '/', '.', '*', 'e', 'E'];
  const [amount, setAmount] = useState({
    from: filter?.amount?.from || '',
    to: filter?.amount?.to || '',
  });

  const handleInputOnlyNumbers = (e) => {
    if (notNumbers.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleChangeValues = (e) => {
    let { name, value } = e.target;

    value = value === '0' ? '' : value;

    setAmount({ ...amount, [name]: value });
  };

  const handleApplyFilters = () => {
    setFilter({ ...filter, amount });
    setFilterSelectActive(null);
  };

  console.log(filter);
  console.log(amount);

  return (
    <div className={styles.dropDownWrapper}>
      <div className={styles.amount_filter_wrapper}>
        <input
          value={amount.from}
          type="number"
          name="from"
          className={styles.amount_input}
          placeholder="From"
          onKeyDown={handleInputOnlyNumbers}
          onChange={handleChangeValues}
        />
        <input
          value={amount.to}
          type="number"
          name="to"
          className={styles.amount_input}
          placeholder="To"
          onKeyDown={handleInputOnlyNumbers}
          onChange={handleChangeValues}
        />
      </div>
      <button className={styles.apply_btn} onClick={handleApplyFilters}>
        Apply
      </button>
    </div>
  );
}

export default DropDownForAmount;
