import { useNavigate, useParams } from 'react-router';
import Timer from '../Timer/Timer';
import EmailMessageStyles from './EmailMessage.module.css';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendEmail } from '../../redux/adminSlice';
import cafeClickLogo from '../../images/cafeClickLogoWithText.png';

function EmailMessage() {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email } = useParams();
  const [flag, setFlag] = useState(false);

  const handleResend = () => {
    if (!isButtonDisabled) {
      dispatch(sendEmail({ email, navigate, setFlag }));
    } else {
      return;
    }
  };

  return (
    <div className={EmailMessageStyles.emailMessage}>
      <div className={EmailMessageStyles.bothSideEM}>
        <div className={EmailMessageStyles.leftSideEM}>
          <img
            src={cafeClickLogo}
            alt="Cafe Click"
            style={{ width: '192px', height: '86px', objectFit: 'contain' }}
          />
          <div className={EmailMessageStyles.leftSideBottomInfoEM}>
            <p>Welcome</p>
            <span>Fill the form to become member of team</span>
          </div>
        </div>
        <div className={EmailMessageStyles.rightSideEM}>
          <div className={EmailMessageStyles.rightSideInfoEM}>
            <div className={EmailMessageStyles.mailInfo}>
              <p className={EmailMessageStyles.signUpHeaderEM}>Check your Email</p>
              <p className={EmailMessageStyles.mailText}>
                We have sent you the link to reset the password, please check your email and follow
                the provided link.
              </p>
            </div>
            <div className={EmailMessageStyles.rightSideBottomInfoEM}>
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '6px',
                }}>
                Did you not receive the code in your email? Check again in
                <Timer flag={flag} setButtonDisabled={setButtonDisabled} />
              </p>
              <button
                disabled={isButtonDisabled}
                className={EmailMessageStyles.resendButton}
                onClick={handleResend}>
                Resend
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailMessage;
