import React from 'react';
import styles from '../Header/style.module.css';
import closeIcon from '../../images/close icon.svg';

function DeleteModal({ setIsDeleteModalActive, handleDeleteTable, deletedTableId }) {
  return (
    <div
      className={styles.modal_container}
      onClick={() => setIsDeleteModalActive(false)}
      style={{
        height: Math.max(document.body.scrollHeight, document.documentElement.scrollHeight),
      }}>
      <div className={styles.modal_wrapper} onClick={(e) => e.stopPropagation()}>
        <img
          src={closeIcon}
          className={styles.close_icon}
          alt="close"
          onClick={() => setIsDeleteModalActive(false)}
        />
        <div
          className={styles.wrapper_for_modal_item}
          style={{
            margin: '20px 0px 20px 0px',
          }}>
          <span className={styles.modal_text}>Are you sure you want to delete the QR code?</span>
        </div>
        <div className={styles.wrapper_for_modal_item}>
          <div className={styles.btns_wrapper}>
            <button className={styles.logout_btn} onClick={() => handleDeleteTable(deletedTableId)}>
              Delete
            </button>
            <button className={styles.cancel_btn} onClick={() => setIsDeleteModalActive(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
