import { useSelector } from 'react-redux';
import MenuStructureStyles from './MenuStructure/MenuStructure.module.css';
import deleteCategoryIcon from '../images/deleteCategoryIcon.svg';
import editCategoryIcon from '../images/editCategoryIcon.svg';
import { URL as serverURL } from '../utils/url';
import { useNavigate } from 'react-router';

function ItemsRow({
  id,
  name,
  currency,
  price,
  categoryID,
  restaurantID,
  image,
  setDeletedItemInfo,
  setItemDeleteModalActive,
}) {
  const navigate = useNavigate();

  const handleEditItemm = () => {
    navigate(`/edit-item/${id}`);
  };

  const categories = useSelector((state) => state.categories.categories);

  const getDistance = () => {
    if (categoryID === 'without') return -40;

    const category = categories.find((category) => category.id === +categoryID);

    if (category.isSecondChild) {
      return -120;
    }

    if (category.isLastChild) {
      return -150;
    }

    if (category.parentID === null) {
      return -80;
    }
  };

  function formatMoney(amount) {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  return (
    <div style={{ marginLeft: categoryID === 'without' ? '' : '5%' }}>
      <div className={MenuStructureStyles.divForEachCategoryRow}>
        <div
          className={
            !name
              ? MenuStructureStyles.itemNameUpDownArrow
              : MenuStructureStyles.itemNameUpDownArrowSubcategory
          }>
          <p style={{ cursor: 'auto' }} className={MenuStructureStyles.stylesForCategoryName}>
            {name}
          </p>
        </div>
        <div
          style={{
            padding: '21.5px',
            backgroundColor: '#f9e6ff',
            transform: `translate(${getDistance()}px)`,
          }}>
          <p className={MenuStructureStyles.price}>{`${formatMoney(price)} ${currency}`}</p>
        </div>
        <div className={MenuStructureStyles.categoryImageWithEditDeleteIcons}>
          <div
            className={MenuStructureStyles.divForImageOfCategory}
            style={{
              backgroundColor: '#f9e6ff',
            }}>
            <img
              style={{ width: '43px', height: '43px', objectFit: 'contain' }}
              src={`${serverURL}/${image}`}
              alt=""
            />
          </div>
          <div className={MenuStructureStyles.editAndDeleteIconsDiv}>
            <img
              style={{ cursor: 'pointer' }}
              src={editCategoryIcon}
              alt="edit"
              onClick={handleEditItemm}
            />
            <img
              style={{ cursor: 'pointer' }}
              src={deleteCategoryIcon}
              alt="remove"
              onClick={() => {
                setItemDeleteModalActive(true);
                setDeletedItemInfo({ id, restaurantID });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemsRow;
