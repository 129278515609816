import MenuStructureStyles from './MenuStructure.module.css';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCategory } from '../../redux/categoriesSlice';
import CategoryRow from '../CategoryRow';
import { getAllInformationAboutCurrentRestaurant } from '../../redux/restaurantsSlice';
import DeleteModal from '../DeleteModal/DeleteModal.jsx';
import ItemsRow from '../ItemsRow.jsx';
import Header from '../Header/Header.jsx';
import BurgerMenu from '../BurgerMenu/BurgerMenu.jsx';
import Footer from '../Footer/Footer.jsx';
import ItemDeleteModal from '../ItemDeleteModal/ItemDeleteModal.jsx';
import { deleteItem } from '../../redux/itemsSlice.js';
import CategoryAdd from '../CategoryAdd/CategoryAdd.jsx';
import { ToastContainer } from 'react-toastify';

function MenuStructure() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [isDeleteModalActive, setDeleteModalActive] = useState(false);
  const [deletedCategoryInfo, setDeletedCategoryInfo] = useState(null);
  const [editModeID, setEditModeID] = useState(null);
  const [deletedItemInfo, setDeletedItemInfo] = useState(null);
  const [isItemDeleteModalActive, setItemDeleteModalActive] = useState(false);
  const [isAddCategoryModalActive, setAddCategoryModalActive] = useState(false);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape' && isItemDeleteModalActive) {
        setItemDeleteModalActive(false);
      }
      if (e.key === 'Escape' && isDeleteModalActive) {
        setDeleteModalActive(false);
      }
      if (e.key === 'Escape' && isAddCategoryModalActive) {
        setAddCategoryModalActive(false);
      }
      return;
    },
    [isItemDeleteModalActive, isDeleteModalActive, isAddCategoryModalActive],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleClickOutSide = useCallback(
    (e) => {
      if (editModeID) {
        if (e.target.classList.contains('editBtn')) {
          return;
        }

        const categoryWrapper = document.getElementById(editModeID);

        if (
          e.target.classList.contains(editModeID.toString()) ||
          !categoryWrapper.contains(e.target)
        ) {
          setEditModeID(null);
        }
      }
    },
    [editModeID],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide);

    return () => {
      document.removeEventListener('click', handleClickOutSide);
    };
  }, [handleClickOutSide]);

  const toggleSideBarMenu = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const categoriesData = useSelector((state) => state.categories.categories)
    ?.filter((item) => item.parentID === null)
    ?.sort((a, b) => +b.date_added - +a.date_added);

  const itemsWithoutCategory = useSelector((state) => state.items.items).filter(
    (item) => item.categoryID === 'without',
  );

  useEffect(() => {
    dispatch(getAllInformationAboutCurrentRestaurant({ id }));
  }, [dispatch, id]);

  const handleDeleteCategory = () => {
    if (deletedCategoryInfo) {
      const { id, restaurantID } = deletedCategoryInfo;
      dispatch(deleteCategory({ id, restaurantID }));
    }
    setDeletedCategoryInfo(null);
    setDeleteModalActive(false);
  };

  const handleDeleteItem = () => {
    if (deletedItemInfo) {
      const { id, restaurantID } = deletedItemInfo;
      dispatch(deleteItem({ id, restaurantID }));
    }
    setDeletedItemInfo(null);
    setItemDeleteModalActive(false);
  };

  return (
    <>
      <div className={MenuStructureStyles.RestRegContainer}>
        <Header toggleSideBarMenu={toggleSideBarMenu} sideBarOpen={sideBarOpen} />

        <div>
          <ToastContainer />
        </div>

        <BurgerMenu id={id} sideBarOpen={sideBarOpen} page="menu-structure" />

        <div onClick={() => setSideBarOpen(false)} className={MenuStructureStyles.container}>
          <div className={MenuStructureStyles.AddCategoryAddItemDiv}>
            <button
              className={MenuStructureStyles.AddCategoryButton}
              onClick={() => {
                setAddCategoryModalActive(true);
              }}>
              Add category
            </button>
            <button
              className={MenuStructureStyles.AddItemButton}
              onClick={() => {
                navigate('/item-add');
                setSideBarOpen(false);
              }}>
              Add item
            </button>
          </div>

          <div className={MenuStructureStyles.ContentContainer}>
            {categoriesData.length || itemsWithoutCategory.length ? (
              <div className={MenuStructureStyles.categoryTable}>
                <header className={MenuStructureStyles.tableHeader}>
                  <p className={MenuStructureStyles.categoryHeaderName}>Category</p>
                  <p
                    className={`${MenuStructureStyles.categoryHeaderName} ${MenuStructureStyles.price_title}`}>
                    Price
                  </p>
                  <p className={MenuStructureStyles.imageHeaderName}>Image</p>
                </header>
                <>
                  {categoriesData.map((item, index) => {
                    return (
                      <CategoryRow
                        editModeID={editModeID}
                        setEditModeID={setEditModeID}
                        setDeletedCategoryInfo={setDeletedCategoryInfo}
                        setDeleteModalActive={setDeleteModalActive}
                        setDeletedItemInfo={setDeletedItemInfo}
                        setItemDeleteModalActive={setItemDeleteModalActive}
                        {...item}
                        key={item.id}
                        index={index + 1}
                      />
                    );
                  })}

                  {itemsWithoutCategory.map((item) => {
                    return (
                      <ItemsRow
                        key={item.id}
                        {...item}
                        setDeletedItemInfo={setDeletedItemInfo}
                        setItemDeleteModalActive={setItemDeleteModalActive}
                      />
                    );
                  })}
                </>
              </div>
            ) : null}
          </div>
        </div>

        <Footer />

        {isAddCategoryModalActive && (
          <CategoryAdd setAddCategoryModalActive={setAddCategoryModalActive} />
        )}

        {isItemDeleteModalActive && (
          <ItemDeleteModal
            setItemDeleteModalActive={setItemDeleteModalActive}
            setDeletedItemInfo={setDeletedItemInfo}
            handleDeleteItem={handleDeleteItem}
          />
        )}

        {isDeleteModalActive && (
          <DeleteModal
            setDeletedCategoryInfo={setDeletedCategoryInfo}
            handleDeleteCategory={handleDeleteCategory}
            setDeleteModalActive={setDeleteModalActive}
          />
        )}
      </div>
    </>
  );
}

export default MenuStructure;
