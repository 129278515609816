import React, { useEffect } from 'react';
import styles from './style.module.css';
import warningEmail from '../../images/warningEmail.svg';
import closeBtn from '../../images/close_modal.svg';
import { useDispatch } from 'react-redux';
import { removeErrorMessage } from '../../redux/adminSlice';

function EmailVerificationModalForSignUp({ setIsEmailVerificationModalActive }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(removeErrorMessage());
    };
  }, [dispatch]);

  return (
    <div className={styles.container} onClick={() => setIsEmailVerificationModalActive(false)}>
      <div
        className={styles.wrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <img src={warningEmail} alt="email" />
        </div>
        <div className={styles.text}>Please check your email inbox for validation.</div>
        <button className={styles.ok_btn} onClick={() => setIsEmailVerificationModalActive(false)}>
          OK
        </button>
        <img
          src={closeBtn}
          alt="close"
          className={styles.close_btn}
          onClick={() => setIsEmailVerificationModalActive(false)}
        />
      </div>
    </div>
  );
}

export default EmailVerificationModalForSignUp;
