import React from 'react';
import styles from './style.module.css';
import closeModal from '../../images/close_modal.svg';

function DeleteEmployeeModal({ setDeleteModalOpen, handleDeleteEmployee }) {
  return (
    <div className={styles.modal_container} onClick={() => setDeleteModalOpen(false)}>
      <div className={styles.delete_modal_wrapper} onClick={(e) => e.stopPropagation()}>
        <h3 className={styles.title}>Are you sure?</h3>
        <img
          src={closeModal}
          alt="close"
          className={styles.close}
          onClick={() => setDeleteModalOpen(false)}
        />
        <p className={styles.text}>This action will be delete your employee.</p>
        <div className={styles.modal_btns}>
          <button className={styles.remove_btn} onClick={handleDeleteEmployee}>
            Delete
          </button>
          <button onClick={() => setDeleteModalOpen(false)} className={styles.cancel_btn}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteEmployeeModal;
