import { useEffect, useState } from 'react';
import MyRestaurantsStyles from './MyRestaurants.module.css';
import Modal from '../Modal/Modal.jsx';
import removeRestIcon from '../../images/removeRestIcon.svg';
import editRestIcon from '../../images/editRestIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getRestaurants } from '../../redux/restaurantsSlice.js';
import EditRestModal from '../EditRestModal.jsx';
import { useNavigate } from 'react-router';
import { URL } from '../../utils/url.js';
import { ToastContainer, toast } from 'react-toastify';
import DeleteModalForRestaurants from '../DeleteModalForRestaurants/DeleteModalForRestaurants.jsx';
import EmailVerificationModalForSignUp from '../EmailVerificationModalForSignUp/EmailVerificationModalForSignUp.jsx';
// import { requestForToken, onMessageListener } from '../../firebase.js';
import Header from '../Header/Header.jsx';
import Footer from '../Footer/Footer.jsx';
import loadingSpinner from '../../images/loading_spinner.svg';

function MyRestaurants() {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const dispatch = useDispatch();
  const restaurants = useSelector((state) => state.restaurants.restaurants);
  const [currentRestId, setCurrentRestId] = useState(null);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(null);
  const { message } = useSelector((state) => state.admin);
  const [isLoading, setLoading] = useState(false);

  // const [notifiaction, setNotification] = useState({ title: '', body: '' });

  // useEffect(() => {
  //   if (notifiaction?.title) {
  //     Notification.requestPermission().then((res) => {
  //       if (res === 'granted') {
  //         new Notification(notifiaction?.title, {
  //           body: notifiaction?.body,
  //           icon: 'https://t4.ftcdn.net/jpg/01/98/33/63/360_F_198336329_D3JsfuSGm5UBTXR9fwcr2WhKNebr7SiB.jpg',
  //         });
  //       }
  //     });
  //   }
  // }, [notifiaction]);

  // onMessageListener()
  //   .then((payload) => {
  //     const { title, body } = payload?.notification;

  //     if (title && body) {
  //       console.log(10);
  //       setNotification({ title, body });
  //     }
  //   })
  //   .catch((err) => console.log(err));

  // requestForToken()
  //   .then((res) => console.log(res))
  //   .catch((err) => console.log(err));

  const [isEmailVerificationModalActive, setIsEmailVerificationModalActive] = useState(
    message === 'signup',
  );

  useEffect(() => {
    dispatch(getRestaurants());
  }, [dispatch]);

  const handleOpenEditRestModal = (e, id) => {
    e.stopPropagation();
    setCurrentRestId(id);
    setEditModalOpen(true);
  };

  const handleRemoveRest = (e, id) => {
    e.stopPropagation();
    setRemoveModalOpen(id);
  };

  const showToast = (text) => {
    toast.info(text || 'Your restaurant is successfully deleted.', {
      position: 'top-right',
      autoClose: 900,
      style: {
        color: text ? 'red' : '#0DA300',
      },
      icon: false,
    });
  };

  return (
    <>
      <div className={MyRestaurantsStyles.RestRegContainer}>
        <Header adminHeader={true} />

        <div className={MyRestaurantsStyles.divForModal}>
          <div className={MyRestaurantsStyles.RestAddingRests}>
            <p className={MyRestaurantsStyles.MyRestaurants}>My Restaurants</p>
            <ToastContainer />

            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '46px' }}>
              <div
                className={MyRestaurantsStyles.AddingRestContainerButton}
                onClick={() => {
                  setModalOpen(true);
                }}>
                <button className={MyRestaurantsStyles.AddingRestButton}>+Add</button>
              </div>
              {restaurants?.map((restaurant) => {
                return (
                  <div
                    className={MyRestaurantsStyles.AddingRestContainer}
                    key={restaurant.id}
                    onClick={() => {
                      navigate(`/menu-structure/${restaurant.id}`);
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <img
                        className={MyRestaurantsStyles.vectorIcon}
                        src={editRestIcon}
                        alt=""
                        onClick={(e) => handleOpenEditRestModal(e, restaurant.id)}
                      />
                      <img
                        className={MyRestaurantsStyles.remove_rest_icon}
                        src={removeRestIcon}
                        alt="remove"
                        onClick={(e) => handleRemoveRest(e, restaurant.id)}
                      />
                      <div className={MyRestaurantsStyles.divForImageDataURL}>
                        <img
                          style={{
                            width: '100px',
                            height: '60px',
                            objectFit: 'contain',
                            marginLeft: '100%',
                          }}
                          src={`${URL}/${restaurant.logo}`}
                          alt="rest"
                        />
                      </div>
                    </div>
                    <div>
                      <p className={MyRestaurantsStyles.restName}>{restaurant.name}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <p className={MyRestaurantsStyles.restphoneNumber}>
                        {restaurant.phoneNumber}
                      </p>
                      <p className={MyRestaurantsStyles.restAddress}>{restaurant.address}</p>
                    </div>
                  </div>
                );
              })}
              {isLoading && (
                <div
                  className={MyRestaurantsStyles.AddingRestContainer}
                  style={{
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <img width={'40%'} src={loadingSpinner} alt="loading.." />
                </div>
              )}
            </div>
          </div>
        </div>

        <Footer />

        {isEmailVerificationModalActive && (
          <EmailVerificationModalForSignUp
            setIsEmailVerificationModalActive={setIsEmailVerificationModalActive}
          />
        )}

        {isRemoveModalOpen && (
          <DeleteModalForRestaurants
            showToast={showToast}
            id={isRemoveModalOpen}
            setRemoveModalOpen={setRemoveModalOpen}
          />
        )}

        {modalOpen && (
          <Modal
            showToast={showToast}
            setLoading={setLoading}
            modalOpen={modalOpen}
            modalClose={() => setModalOpen(false)}
          />
        )}

        {editModalOpen && (
          <EditRestModal
            id={currentRestId}
            setCurrentRestId={setCurrentRestId}
            editModalOpen={editModalOpen}
            editModalClose={() => setEditModalOpen(false)}
          />
        )}
      </div>
    </>
  );
}

export default MyRestaurants;
