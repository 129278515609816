import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url';
import { logout } from './adminSlice';

const initialState = {
  ingredients: [],
};

export const getIngredients = createAsyncThunk(
  'ingredients/getAllIngredients',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/ingredients/get-all-ingredients/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getIngredients({ id }));
        return 'refreshed';
      }

      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState,
  reducers: {
    getIngredientsFirstTime(state, action) {
      state.ingredients = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIngredients.fulfilled, (state, action) => {
      if (action.payload === 'refreshed') {
        return;
      }
      state.ingredients = action.payload.ingredients;
    });
  },
});

export const { getIngredientsFirstTime } = ingredientsSlice.actions;
export default ingredientsSlice.reducer;
