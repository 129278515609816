import React, { useState } from 'react';
import styles from '../style.module.css';
import FilterItem from './FilterItem.jsx';

function Filter({ filter, setFilter, orders, infoAboutTableNumbersAndWaiters }) {
  const [isFilterSelectActive, setFilterSelectActive] = useState(null);
  const filterItems = ['tableNumber', 'waiter', 'orderDate', 'amount'];

  return (
    <div className={styles.filter_wrapper}>
      {filterItems.map((filterItem) => {
        return (
          <FilterItem
            infoAboutTableNumbersAndWaiters={infoAboutTableNumbersAndWaiters}
            orders={orders}
            setFilter={setFilter}
            filter={filter}
            key={filterItem}
            isFilterSelectActive={isFilterSelectActive}
            setFilterSelectActive={setFilterSelectActive}
            item={filterItem}
          />
        );
      })}
    </div>
  );
}

export default Filter;
