export const getOrders = async (url, id, filter) => {
  const response = await fetch(`${url}/orders/getOrders/${id}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(filter),
  });

  const data = await response.json();

  return data;
};
