import React, { useRef } from 'react';
import { GoDownload } from 'react-icons/go';
import { IoPrintOutline } from 'react-icons/io5';
import { RiDeleteBinLine } from 'react-icons/ri';
import styles from '../RestTables/style.module.css';
import QRCode from 'react-qr-code';
import ReactToPrint from 'react-to-print';
import { saveAs } from 'file-saver';

function TableQrRow({ qrCode, setIsDeleteModalActive, setDeletedTableId }) {
  const { qr, id, number } = qrCode;
  const qrCodeRef = useRef(null);

  const handleDownload = (number) => {
    const svg = qrCodeRef.current.cloneNode(true);
    svg.setAttribute('width', '500');
    svg.setAttribute('height', '500');
    const svgContent = svg.outerHTML;
    const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });
    saveAs(blob, `Table_${number}_QR_code.svg`);
  };

  return (
    <div className={styles.table_row}>
      <div className={styles.table_number_wrapper}>
        <p className={styles.table_number}>{number}</p>
      </div>
      <p className={styles.qr_code_wrapper}>
        <QRCode
          ref={qrCodeRef}
          className={styles.qr_code_component}
          size={60}
          value={qr}
          cursor={'pointer'}
          viewBox={'0 0 100 100'}
        />
      </p>
      <div className={styles.table_item_wrapper}>
        <GoDownload
          onClick={() => handleDownload(qr[qr.length - 1])}
          className={styles.table_item_download}
          size={24}
          cursor={'pointer'}
        />
      </div>
      <div className={styles.table_item_wrapper}>
        <ReactToPrint
          pageStyle={'print'}
          documentTitle={`Table ${qr[qr.length - 1]} QR code`}
          content={() => {
            const svg = qrCodeRef.current.cloneNode(true);
            svg.setAttribute('width', '300');
            svg.setAttribute('height', '300');
            return svg;
          }}
          trigger={() => {
            return (
              <IoPrintOutline className={styles.table_item_print} size={24} cursor={'pointer'} />
            );
          }}
        />
      </div>
      <div className={styles.table_item_wrapper}>
        <RiDeleteBinLine
          onClick={() => {
            setIsDeleteModalActive(true);
            setDeletedTableId(id);
          }}
          className={styles.table_item}
          size={24}
          cursor={'pointer'}
        />
      </div>
    </div>
  );
}

export default TableQrRow;
