import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url.js';
import { logout } from './adminSlice.js';

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async (dataFromFront, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/categories/get-categories`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(dataFromFront),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getCategories(dataFromFront));
        return 'refreshed';
      }

      return data.categories;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const addCategory = createAsyncThunk(
  'categories/add-category',
  async ({ formData: category, resetInfo, showErrorMessage }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/categories/add-category`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: category,
      });

      if (!response.ok) {
        const { message } = await response.json();
        showErrorMessage(message);
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(addCategory({ formData: category, resetInfo, showErrorMessage }));
        return 'refreshed';
      }

      resetInfo();

      return data;
    } catch (err) {
      console.log('err2', err);
      return rejectWithValue({ message: err.message });
    }
  },
);

export const changeCategory = createAsyncThunk(
  'categories/changeCategory',
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/categories/change-category/${id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(changeCategory({ id, formData }));
        return 'refreshed';
      }

      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async ({ id, restaurantID }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/categories/delete-category/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ restaurantID }),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(deleteCategory({ id, restaurantID }));
        return 'refreshed';
      }

      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
  },
  reducers: {
    getCategoriesFirstTime(state, action) {
      state.categories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategory.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.categories = action.payload.categories;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.categories = [];
      })
      .addCase(changeCategory.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.categories = action.payload.categories;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.categories = action.payload.categories;
      });
  },
});

export const { getCategoriesFirstTime } = categoriesSlice.actions;
export default categoriesSlice.reducer;
