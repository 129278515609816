import React, { useState } from 'react';
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import countries from '../../data/countries.json';
import { changeAdminInfo } from '../../redux/adminSlice.js';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { FlagIcon } from 'react-flag-kit';

function UserInfo() {
  const dispatch = useDispatch();
  const { firstName, lastName, phoneNumber, email, id } = useSelector((state) => state.admin?.user);
  const [editedFirstName, setEditedFirstName] = useState(firstName);
  const [editedLastName, setEditedLastName] = useState(lastName);
  const [editedEmail, setEditedEmail] = useState(email);
  const [message, setMessage] = useState({ type: null, text: '' });

  // all countries with their number codes
  const countriesOptions = countries.map((country) => {
    const { dial_code, code } = country;
    return { value: dial_code, label: `(${dial_code})`, code };
  });

  // only number without country number code
  const [editedPhoneNumber, setEditedPhoneNumber] = useState(phoneNumber.split(' ')[1]);

  // country flag with number code
  const code = phoneNumber.split(' ')[0];
  const [countryCode, setCountryCode] = useState(
    countriesOptions.find((country) => country.value === code),
  );

  // full phone number
  const fullPhoneNumber = `${countryCode?.value} ${editedPhoneNumber}`;

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });

  const handleChangeValues = (key, value) => {
    setMessage({ type: null, message: '' });

    if (key === 'firstName') {
      setEditedFirstName(value);
      if (!value) {
        setErrors({ ...errors, firstName: 'Please enter your First Name' });
      } else if (value.length < 2) {
        setErrors({ ...errors, firstName: 'First Name must contain min 2 letters' });
      } else if (!/^[A-Za-z]+$/.test(value)) {
        setErrors({ ...errors, firstName: 'Please enter only letters' });
      } else {
        setErrors({ ...errors, firstName: '' });
      }
    } else if (key === 'lastName') {
      setEditedLastName(value);
      if (!value) {
        setErrors({ ...errors, lastName: 'Please enter your Last Name' });
      } else if (value.length < 3) {
        setErrors({ ...errors, lastName: 'Last Name must contain min 3 letters' });
      } else if (!/^[A-Za-z]+$/.test(value)) {
        setErrors({ ...errors, lastName: 'Please enter only letters' });
      } else {
        setErrors({ ...errors, lastName: '' });
      }
    } else if (key === 'phoneNumber') {
      setEditedPhoneNumber(value);
      if (!value) {
        setErrors({ ...errors, phoneNumber: 'Please enter your phone number' });
      } else if (value.length < 3 && value.length > 0) {
        setErrors({ ...errors, phoneNumber: 'Phone number must contain min 3 numbers' });
      } else {
        setErrors({ ...errors, phoneNumber: '' });
      }
    } else {
      setEditedEmail(value);

      if (!value) {
        setErrors({ ...errors, email: 'Please enter your email' });
      } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        setErrors({
          ...errors,
          email: 'Please enter your email address in format yourname@example.com',
        });
      } else {
        setErrors({ ...errors, email: '' });
      }
    }
  };

  const stopWritingNumbers = (e) => {
    if (!isNaN(+e.key)) {
      e.preventDefault();
    }
  };

  const isInfoChanged = () => {
    if (
      editedFirstName === firstName &&
      editedLastName === lastName &&
      editedEmail === email &&
      fullPhoneNumber === phoneNumber
    ) {
      return false;
    }
    return true;
  };

  const isBtnDisabled = () => {
    const isChanged = isInfoChanged();
    return !Object.values(errors).every((err) => err === '') || !isChanged;
  };

  const filterInputValue = (e) => {
    if (editedPhoneNumber.length === 25 && e.key !== 'Backspace') {
      e.preventDefault();
    }

    if (editedPhoneNumber.length) {
      if (e.key === '+' || e.key === 'e' || e.key === '-' || e.key === '.') {
        e.preventDefault();
      }
      return;
    }
    if (e.key === '+' || e.key === '0' || e.key === 'e' || e.key === '-' || e.key === '.') {
      e.preventDefault();
    }
  };

  const showToast = () => {
    toast.info('Your changes were successfully saved', {
      position: 'top-right',
      autoClose: 900,
      style: {
        color: '#0DA300',
      },
      icon: false,
    });
  };

  const handleSaveInfo = (e) => {
    e.preventDefault();

    const data = {
      id,
      firstName: editedFirstName === firstName ? '' : editedFirstName,
      lastName: editedLastName === lastName ? '' : editedLastName,
      phoneNumber: fullPhoneNumber === phoneNumber ? '' : fullPhoneNumber,
      email: editedEmail === email ? '' : editedEmail,
    };

    dispatch(changeAdminInfo({ newInfoData: data, setMessage, showToast }));
  };

  return (
    <form className={styles.form_container} onSubmit={handleSaveInfo}>
      <div className={styles.form_wrapper}>
        <div>
          <ToastContainer />
        </div>
        <div className={styles.inputs_wrapper_row}>
          <div className={styles.input_wrapper}>
            <input
              onKeyDown={stopWritingNumbers}
              required
              type="text"
              id="firstName"
              className={styles.input}
              value={editedFirstName}
              onChange={(e) => handleChangeValues('firstName', e.target.value)}
            />
            <label htmlFor="firstName" className={styles.label}>
              First Name
            </label>
            {errors.firstName && <span className={styles.error_message}>{errors.firstName}</span>}
          </div>
          <div className={styles.input_wrapper}>
            <input
              onKeyDown={stopWritingNumbers}
              required
              type="text"
              id="lastName"
              className={styles.input}
              value={editedLastName}
              onChange={(e) => handleChangeValues('lastName', e.target.value)}
            />
            <label htmlFor="lastName" className={styles.label}>
              Last Name
            </label>
            {errors.lastName && <span className={styles.error_message}>{errors.lastName}</span>}
          </div>
        </div>
        <div
          className={styles.inputs_wrapper_row}
          style={{
            alignItems: 'end',
          }}>
          <div
            className={styles.input_wrapper}
            style={{
              borderBottom: message?.text?.includes('phone number')
                ? '1px solid red'
                : '1px solid #c6c6c6',
            }}>
            <label htmlFor="phoneNumber" className={styles.label_for_phone_number}>
              Phone Number
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                position: 'relative',
              }}>
              <div
                id="phoneNumber"
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <div
                  className={styles.divForUserInputs}
                  style={{
                    minWidth: '36%',
                  }}>
                  <Select
                    options={countriesOptions}
                    value={countryCode}
                    onChange={setCountryCode}
                    getOptionLabel={(e) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}>
                          <FlagIcon code={e.code} size={16} /> <span>{e.label}</span>
                        </div>
                      );
                    }}
                    styles={{
                      menu: (base, state) => ({
                        ...base,
                        maxHeight: '20vh',
                        overflow: 'scroll',
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        boxShadow: state.isFocused ? 'none' : null,
                        borderColor: state.isFocused ? '#ccc' : null,
                        cursor: 'pointer',
                        border: state.isFocused ? 'none' : 'none',
                        outline: state.isFocused ? 'none' : 'none',
                      }),
                    }}
                  />
                </div>
                <input
                  style={{
                    border: 'none',
                    width: '64%',
                    transform: 'translate(-10px, 0px)',
                  }}
                  type="number"
                  value={editedPhoneNumber}
                  placeholder="1122334455"
                  required
                  className={styles.input}
                  onKeyDown={filterInputValue}
                  onChange={(e) => handleChangeValues('phoneNumber', e.target.value)}
                />
              </div>
              {errors.phoneNumber && (
                <p
                  style={{
                    color: '#FF0000',
                    fontSize: 10,
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    textIndent: 7,
                  }}>
                  {errors.phoneNumber}
                </p>
              )}
            </div>
          </div>
          <div
            className={styles.input_wrapper}
            style={{
              height: '33px',

              borderBottom: message?.text?.includes('email')
                ? '1px solid red'
                : '1px solid #c6c6c6',
            }}>
            <input
              required
              type="text"
              id="email"
              className={styles.input}
              value={editedEmail}
              onChange={(e) => handleChangeValues('email', e.target.value)}
            />
            <label htmlFor="email" className={styles.label}>
              Email
            </label>
            {errors.email && <span className={styles.error_message}>{errors.email}</span>}
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}>
        {message.type && (
          <>
            {message.type === 'error' && (
              <p className={styles.error_message_from_server}>{message.text}</p>
            )}
          </>
        )}
        <button className={styles.save_btn} disabled={isBtnDisabled()}>
          Save
        </button>
      </div>
    </form>
  );
}

export default UserInfo;
