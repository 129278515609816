import eyeOn from '../../images/eye.svg';
import eyeOff from '../../images/eyeOff.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../redux/adminSlice';
import countries from '../../data/countries.json';
import Select from 'react-select';
import styles from './style.module.css';
import cafeClickLogo from '../../images/cafeClickLogoWithText.png';
import { FlagIcon } from 'react-flag-kit';

function SignUp() {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [message, setMessage] = useState('');
  const isLoading = useSelector((state) => state.admin.isLoading);
  const [isShowPassword1, setShowPassword1] = useState(false);
  const [isShowPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState({ value: '+1', label: '(+1)', code: 'US' });
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const countriesOptions = countries.map((country) => {
    const { dial_code, code } = country;

    return { value: dial_code, label: `(${dial_code})`, code };
  });

  const fullPhoneNumber = `${countryCode.value} ${phoneNumber}`;

  const validateFirstName = (e) => {
    if (!e) {
      return 'Please enter your First Name';
    } else if (e.length < 2) {
      return 'First Name must contain min 2 letters';
    } else if (!/^[A-Za-z]+$/.test(e)) {
      return 'Please enter only letters';
    }
    return '';
  };

  const validateLastName = (e) => {
    if (!e) {
      return 'Please enter your Last Name';
    } else if (e.length < 2) {
      return 'Last Name must contain min 2 letters';
    } else if (!/^[A-Za-z-]+$/.test(e)) {
      return 'Please enter only letters';
    }
    return '';
  };

  const validateEmail = (e) => {
    if (!e) {
      return 'Please enter your email address';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(e)) {
      return 'Please enter your email address in format yourname@example.com';
    }
    return '';
  };

  const validatePassword = (e) => {
    if (!e) {
      return 'Please enter a password';
    } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(e)) {
      return 'Password must contain min 8 charachters, capital letters, numbers or symbols';
    } else return '';
  };

  const validateConfirmPassword = (value) => {
    if (value !== values.password) {
      return 'Passwords do not match';
    } else if (values.password === '') {
      return 'Please enter a password';
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    if (field === 'email') {
      setMessage('');
    }
    const newvalues = { ...values, [field]: value };
    setValues(newvalues);
    const newErrors = {
      ...errors,
      [field]: validateField(field, value),
    };
    setErrors(newErrors);
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'firstName':
        return validateFirstName(value);
      case 'lastName':
        return validateLastName(value);
      case 'email':
        return validateEmail(value);
      case 'password':
        return validatePassword(value);
      case 'confirmPassword':
        return validateConfirmPassword(value);
      default:
        return '';
    }
  };

  const isSignUpButtonDisabled = () => {
    if (
      Object.keys(errors).length &&
      Object.values(errors).every((err) => err === '') &&
      isChecked &&
      !isLoading
    ) {
      return false;
    }

    return true;
  };

  const filterInputValue = (e) => {
    if (phoneNumber.length) {
      if (e.key === '+' || e.key === 'e' || e.key === '-' || e.key === '.') {
        e.preventDefault();
      }
      return;
    }
    if (e.key === '+' || e.key === '0' || e.key === 'e' || e.key === '-' || e.key === '.') {
      e.preventDefault();
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();

    if (!isSignUpButtonDisabled()) {
      dispatch(signup({ ...values, phoneNumber: fullPhoneNumber, setMessage }));
    } else {
      return null;
    }
  };

  const handleChangePhoneNumber = (e) => {
    let error;
    if (e.target.value.length < 3 && e.target.value.length > 0) {
      error = 'Phone number must contain min 2 numbers';
    } else if (e.target.value.length === 0) {
      error = 'Please enter your phone number';
    } else {
      setErrors({ ...errors, phoneNumber: '' });
    }

    if (error) {
      setErrors({ ...errors, phoneNumber: error });
    }
    setPhoneNumber(e.target.value);
  };

  return (
    <div className={styles.signUp}>
      <div className={styles.bothSide}>
        <div className={styles.leftSide}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={cafeClickLogo}
              alt="Cafe Click"
              style={{ width: '192px', height: '86px', objectFit: 'contain' }}
            />
          </div>
          <div className={styles.leftSideBottomInfo}>
            <p>Welcome</p>
            <span>Fill the form to become member of team</span>
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.rightSideInfo}>
            <p className={styles.signUpHeader}>Sign Up</p>
            <form className={styles.formForInputs} onSubmit={handleSignUp}>
              <div className={styles.divForUserInputs}>
                <input
                  className={styles.userInputs}
                  type="text"
                  value={values.firstName}
                  onChange={function (e) {
                    handleInputChange('firstName', e.target.value);
                  }}
                  required
                  style={{ textTransform: 'capitalize' }}
                />
                <label className={styles.labelForUserInputs}>First Name</label>
                <p className={styles.error_message}>{errors.firstName || ''}</p>
              </div>
              <div className={styles.divForUserInputs}>
                <input
                  className={styles.userInputs}
                  type="text"
                  value={values.lastName}
                  onChange={(e) => handleInputChange('lastName', e.target.value)}
                  required
                  style={{ textTransform: 'capitalize' }}
                />
                <label className={styles.labelForUserInputs}>Last Name</label>
                <p className={styles.error_message}>{errors.lastName || ''}</p>
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  position: 'relative',
                }}>
                {phoneNumber && (
                  <label
                    htmlFor="phoneNumber"
                    className={styles.labelForUserInputs}
                    style={{
                      zIndex: '99999',
                      top: '-20px',
                      left: '6px',
                      fontSize: '13px',
                      color: '#42725e',
                    }}>
                    Phone Number
                  </label>
                )}
                <div
                  id="phoneNumber"
                  style={{
                    width: '100%',
                    borderBottom: '1px #979797 solid',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <div className={styles.divForUserInputs}>
                    <Select
                      options={countriesOptions}
                      value={countryCode}
                      onChange={setCountryCode}
                      getOptionLabel={(e) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}>
                            <FlagIcon code={e.code} size={18} /> <span>{e.label}</span>
                          </div>
                        );
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minWidth: state.isFocused ? '104.66px' : null,
                          boxShadow: state.isFocused ? 'none' : null,
                          borderColor: state.isFocused ? '#ccc' : null,
                          cursor: 'pointer',
                          border: state.isFocused ? 'none' : 'none',
                          outline: state.isFocused ? 'none' : 'none',
                        }),
                      }}
                    />
                  </div>
                  <input
                    style={{
                      paddingLeft: '16px',
                      border: 'none',
                      width: '69%',
                      transform: 'translate(-10px, 0px)',
                    }}
                    type="number"
                    value={phoneNumber}
                    placeholder="1122334455"
                    required
                    className={styles.userInputs}
                    onKeyDown={filterInputValue}
                    onChange={handleChangePhoneNumber}
                  />
                </div>
                <p
                  className={styles.error_message}
                  style={{
                    top: '42px',
                  }}>
                  {errors.phoneNumber || ''}
                </p>

                {message === 'An account with this number has already been registered!' && (
                  <p style={{ color: 'red', fontSize: '12px', marginLeft: '10px' }}>{message}</p>
                )}
              </div>

              <div className={styles.divForUserInputs}>
                <input
                  autoComplete="off"
                  className={styles.userInputs}
                  type="text"
                  value={values.email}
                  onChange={(e) => handleInputChange('email', e.target.value)}
                  required
                />
                <label className={styles.labelForUserInputs}>Email</label>
                <p className={styles.error_message}>{errors.email || ''}</p>

                {message === 'The email address is already registered!' && (
                  <p style={{ color: 'red', fontSize: '12px', marginLeft: '10px' }}>{message}</p>
                )}
              </div>
              <div className={styles.divForUserInputs}>
                <input
                  autoComplete="new-password"
                  className={styles.userInputs}
                  id="firstPasswordEye"
                  type={`${isShowPassword1 ? 'text' : 'password'}`}
                  value={values.password}
                  onChange={(e) => handleInputChange('password', e.target.value)}
                  required
                />
                <label className={styles.labelForUserInputs}>Password</label>
                <p className={styles.error_message}>{errors.password || ''}</p>
                <img
                  className={styles.passwordEye}
                  src={isShowPassword1 ? eyeOff : eyeOn}
                  onClick={() => setShowPassword1(!isShowPassword1)}
                  alt=""
                />
              </div>
              <div className={styles.divForUserInputs}>
                <input
                  className={styles.userInputs}
                  id="secondPasswordEye"
                  type={`${isShowPassword2 ? 'text' : 'password'}`}
                  value={values.confirmPassword}
                  onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                  required
                />
                <label className={styles.labelForUserInputs}>Confirm Password</label>
                <p className={styles.error_message}>{errors.confirmPassword || ''}</p>

                <img
                  className={styles.passwordEye}
                  src={isShowPassword2 ? eyeOff : eyeOn}
                  onClick={() => setShowPassword2(!isShowPassword2)}
                  alt=""
                />
              </div>
              <div
                style={{
                  paddingLeft: '4px',
                  display: 'flex',
                  gap: '10px',
                }}>
                <input
                  className={styles.checkbox_input}
                  type="checkbox"
                  id="checkbox1"
                  name="checkbox1"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <label
                  style={{ fontSize: '12px', display: 'flex', alignItems: 'center', gap: '4px' }}
                  htmlFor="checkbox1">
                  I agree to the <span style={{ color: '#0066FF' }}>Terms of Service </span> and
                  <span style={{ color: '#0066FF' }}>Privacy Policy</span>
                </label>
              </div>
              <button
                type="submit"
                className={styles.SignUpButton}
                disabled={isSignUpButtonDisabled()}
                onClick={handleSignUp}>
                Sign up
              </button>
            </form>
            <div className={styles.rightSideBottomInfo}>
              <p className={styles.signInButton_text}>Already have an account?</p>
              <button
                type="button"
                className={styles.SignInButton}
                onClick={() => navigate('/signin')}>
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignUp;
