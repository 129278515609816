import { createSlice } from '@reduxjs/toolkit';

const typesSlice = createSlice({
  name: 'types',
  initialState: {
    types: [],
  },
  reducers: {
    getTypesFirstTime(state, action) {
      state.types = action.payload;
    },
  },
});

export const { getTypesFirstTime } = typesSlice.actions;
export default typesSlice.reducer;
