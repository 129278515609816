import React from 'react';
import { Navigate } from 'react-router';

function ProtectedRoute({ children, allowedRoles, userRole, restID }) {
  if (!userRole) {
    return <Navigate to="/signin" />;
  }

  if (allowedRoles.includes(userRole)) {
    return children;
  }

  if (userRole === 'manager') {
    return <Navigate to={`/menu-structure/${restID}`} />;
  }

  if (userRole === 'waiter') {
    return <Navigate to={`/order-list/${restID}`} />;
  }

  if (userRole === 'admin') {
    return <Navigate to="/my-restaurants" />;
  }

  return allowedRoles.includes(userRole) ? children : <Navigate to="/signin" />;
}

export default ProtectedRoute;
