import { useRef, useState } from 'react';
import itemAddStyles from '../components/ItemAdd/ItemAdd.module.css';
import iconForAddItem from '../images/iconForAddItem.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { URL as serverURL } from '../utils/url';
import EditIngredientsModal from './EditIngredientsModal';
import { changeItem } from '../redux/itemsSlice';
import { ToastContainer, toast } from 'react-toastify';
import Header from './Header/Header.jsx';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import Footer from './Footer/Footer.jsx';

function EditItem() {
  const fileInput = useRef();
  const { id } = useParams();
  const item = useSelector((state) => state.items.items).find((item) => item.id == id);
  const categories = useSelector((state) => state.categories.categories);
  const ingredientss = useSelector((state) => state.ingredients.ingredients).filter(
    (item) => item.itemID == id,
  );
  const typess = useSelector((state) => state.types.types).filter((item) => item.itemID == id);
  const dispatch = useDispatch();
  const [deletedIngredientsID, setDeletedIngredientsID] = useState([]);
  const [previewImage, setPreviewImage] = useState(`${serverURL}/${item.image}`);
  const [file, setFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [newIngredients, setNewIngredients] = useState([]);
  const [newTypes, setNewTypes] = useState([]);
  const [ingredients, setIngredients] = useState(ingredientss);
  const [editedName, setEditedName] = useState(item.name);
  const [editedPrice, setEditedPrice] = useState(item.price);
  const [editedDescription, setEditedDescription] = useState(item.description);
  const [editedCategoryID, setEditedCategoryID] = useState(item.categoryID);
  const [editedCurrency, setEditedCurrency] = useState(item.currency);

  const navigate = useNavigate();
  const [editedCategoryName, setEditedCategoryName] = useState(
    getCategoryNameByID(item.categoryID),
  );
  const [types, setTypes] = useState(typess);
  const [typesIdsForDelete, setTypesIdsForDelete] = useState([]);

  const showToast = () => {
    toast.info('Your item is successfully changed.', {
      position: 'top-right',
      autoClose: 900,
      style: {
        color: '#0DA300',
      },
      icon: false,
    });
  };

  function getCategoryNameByID(id) {
    if (id === 'without') {
      return 'Choose category';
    }
    const category = categories.find((category) => category.id === +id);
    return category.name;
  }

  const toggleSideBarMenu = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const handleChangeIcon = (event) => {
    setFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleRemoveIcon = () => {
    setPreviewImage(`${serverURL}/images/defaultItemIcon.png`);
    setFile('default');
  };

  const handleEditItem = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file || '');
    formData.append('name', editedName === item.name ? '' : editedName);
    formData.append('price', editedPrice === item.price ? '' : editedPrice);
    formData.append('categoryID', editedCategoryID === item.categoryID ? '' : editedCategoryID);
    formData.append('description', editedDescription === item.description ? '' : editedDescription);
    formData.append('currency', editedCurrency === item.currency ? '' : editedCurrency);
    formData.append('restaurantID', item.restaurantID);

    formData.append('newIngredients', JSON.stringify(newIngredients));
    formData.append('newTypes', JSON.stringify(newTypes));

    formData.append('idsForDelete', JSON.stringify(deletedIngredientsID));
    formData.append('typesIdsForDelete', JSON.stringify(typesIdsForDelete));
    dispatch(changeItem({ id, formData, showToast, navigate, restID: item.restaurantID }));
  };

  return (
    <>
      <div className={itemAddStyles.ItemAdd}>
        <Header toggleSideBarMenu={toggleSideBarMenu} sideBarOpen={sideBarOpen} />

        <BurgerMenu id={id} sideBarOpen={sideBarOpen} page="menu-structure" />

        <form
          className={itemAddStyles.ModalDetailsPart}
          onSubmit={handleEditItem}
          onClick={() => setSideBarOpen(false)}>
          <div>
            <ToastContainer />
          </div>

          <p className={itemAddStyles.ModalName}>Edit Item</p>

          <div className={itemAddStyles.ModalDivForUserInputs}>
            <select
              className={itemAddStyles.ModalUserInputs}
              name="ParentCategories"
              value={editedCategoryID}
              onChange={(e) => {
                setEditedCategoryID(e.target.value);
                setEditedCategoryName(getCategoryNameByID(e.target.value));
              }}>
              <option disabled value={editedCategoryID}>
                {getCategoryNameByID(editedCategoryID)}
              </option>

              {editedCategoryID !== 'without' && <option value="without">Choose category</option>}
              {categories
                .filter((category) => category.id != editedCategoryID)
                .map((item) => {
                  if (item.parentID === null) {
                    return (
                      <option style={{ fontWeight: 'bold' }} key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  } else if (item.isSecondChild) {
                    return (
                      <option style={{ fontWeight: '500' }} key={item.id} value={item.id}>
                        &nbsp;&nbsp;{item.name}
                      </option>
                    );
                  } else if (item.isLastChild) {
                    return (
                      <option disabled key={item.id} value={item.id}>
                        &nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                      </option>
                    );
                  } else {
                    return null;
                  }
                })}
            </select>
            <label className={itemAddStyles.ModalLabelForUserInputs}>Category</label>
            {errors.parentCategory && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.parentCategory}
              </p>
            )}
          </div>

          <div className={itemAddStyles.ModalDivForUserInputs}>
            <input
              className={itemAddStyles.ModalUserInputs}
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              required
            />
            <label className={itemAddStyles.ModalLabelForUserInputs}>Name</label>
            {errors.nameOfItem && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.nameOfItem}
              </p>
            )}
          </div>

          <div
            className={itemAddStyles.ModalDivForUserInputs}
            style={{
              width: '550px',
            }}>
            <input
              className={itemAddStyles.ModalUserInputs}
              style={{
                width: '450px',
              }}
              type="number"
              value={editedPrice}
              onChange={(e) => setEditedPrice(e.target.value)}
              required
            />
            <label className={itemAddStyles.ModalLabelForUserInputs}>Price</label>
            <select
              defaultValue={editedCurrency}
              className={itemAddStyles.currency_select}
              onChange={(e) => setEditedCurrency(e.target.value)}>
              <option value="AMD">AMD</option>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="RUB">RUB</option>
            </select>
          </div>

          <div className={itemAddStyles.ModalDivForRestLogo}>
            <label className={itemAddStyles.ModalLabelForRestLogo}>Image</label>
            <div className={itemAddStyles.ModalDivForInputAndLogo}>
              <button
                type="button"
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                  background: '#E7E7E7',
                  boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '4px 20px',
                  marginTop: '10px',
                  cursor: 'pointer',
                  color: '#484848',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  border: 'none',
                }}
                onClick={() => {
                  fileInput.current.click();
                }}>
                Choose file
              </button>
              <input
                style={{
                  opacity: '0',
                  height: '0',
                  width: '0',
                  lineHeight: '0',
                  overflow: 'hidden',
                  padding: '0',
                  margin: '0',
                }}
                ref={fileInput}
                className={itemAddStyles.ModalRestLogo}
                type="file"
                onChange={handleChangeIcon}
              />
              <img className={itemAddStyles.ModalRestImage} src={previewImage} alt="Selected" />
              {previewImage !== `${serverURL}/images/defaultItemIcon.png` && (
                <button
                  type="button"
                  onClick={handleRemoveIcon}
                  className={itemAddStyles.removeIconBtn}>
                  X
                </button>
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '530px',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '30px',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
              }}>
              <div className={itemAddStyles.ModalDivForIngredients}>
                <label className={itemAddStyles.itemDetailsLabel}>Item Details</label>
                <div
                  className={itemAddStyles.divForAddItemIcon}
                  onClick={() => {
                    setModalOpen(true);
                    setSideBarOpen(false);
                  }}>
                  <img src={iconForAddItem} alt="" />
                </div>
              </div>

              <div
                className={
                  [...ingredients, ...newIngredients, ...types, ...newTypes].length
                    ? itemAddStyles.twoTablesOfIngredient
                    : itemAddStyles.twoTablesOfIngredientActive
                }>
                <table className={itemAddStyles.tableForIngAndPrice}>
                  <thead>
                    <tr>
                      <th>Ingredient</th>
                      <th>Price</th>
                    </tr>
                  </thead>

                  <tbody>
                    {ingredients
                      .filter((item) => {
                        if (deletedIngredientsID.includes(item.id)) {
                          return false;
                        }
                        return true;
                      })
                      .map((item) => {
                        return (
                          <tr key={item.id}>
                            <td style={{ width: '100px' }}>{item.name}</td>
                            <td style={{ width: '100px' }}>{!item.price ? '-' : item.price}</td>
                          </tr>
                        );
                      })}

                    {newIngredients.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td style={{ width: '100px' }}>{item.name}</td>
                          <td style={{ width: '100px' }}>{!item.price ? '-' : item.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <table
                  className={
                    [...types, ...newTypes].length
                      ? itemAddStyles.tableForType
                      : itemAddStyles.tableForTypeActive
                  }>
                  <thead>
                    <tr>
                      <th colSpan={'2'} style={{ width: '100%', textAlign: 'center' }}>
                        Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {types
                      .filter((item) => {
                        return !typesIdsForDelete.includes(item.id);
                      })
                      .map((item) => {
                        return (
                          <tr key={item.id}>
                            <td style={{ height: '30px' }}>{item.key1}</td>
                            <td style={{ height: '30px' }}>{item.key2}</td>
                          </tr>
                        );
                      })}
                    {newTypes.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td style={{ height: '30px' }}>{item.key1}</td>
                          <td style={{ height: '30px' }}>{item.key2}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className={itemAddStyles.ModalDivForUserInputs}>
            <textarea
              style={{ resize: 'none', transition: 'all 0.3s ease-in-out' }}
              className={itemAddStyles.ModalUserInputs}
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              required
            />
            <label className={itemAddStyles.ModalLabelForUserInputs}>Description</label>
          </div>

          <div className={itemAddStyles.button_wrapper}>
            <button
              className={itemAddStyles.ModalSaveButton}
              type="submit"
              onClick={handleEditItem}>
              Save Changes
            </button>

            <button
              className={itemAddStyles.ModalCancelBtn}
              type="button"
              onClick={() => navigate(-1)}>
              Cancel
            </button>
          </div>
        </form>

        <Footer />
      </div>
      {modalOpen && (
        <EditIngredientsModal
          newTypes={newTypes}
          setNewTypes={setNewTypes}
          setTypesIdsForDelete={setTypesIdsForDelete}
          typesIdsForDelete={typesIdsForDelete}
          deletedIngredientsID={deletedIngredientsID}
          setDeletedIngredientsID={setDeletedIngredientsID}
          ingredients={ingredients}
          setIngredients={setIngredients}
          newIngredients={newIngredients}
          setNewIngredients={setNewIngredients}
          types={types}
          setTypes={setTypes}
          modalOpen={modalOpen}
          modalClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

export default EditItem;
