import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url';
import { logout } from './adminSlice';

const initialState = {
  tables: [],
};

export const deleteTable = createAsyncThunk(
  'tables/delete-table',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/tables/delete-table/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(deleteTable({ id }));
        return 'refreshed';
      }

      dispatch(deleteTableReducer({ id }));
      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const getTables = createAsyncThunk(
  'tables/getTables',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/tables/get-tables/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getTables({ id }));
        return 'refreshed';
      }

      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const addTables = createAsyncThunk(
  'tables/addTables',
  async (dataForAdding, { rejectWithValue, dispatch }) => {
    try {
      const { tables, id } = dataForAdding;

      const response = await fetch(`${URL}/tables/add-tables/${id}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(tables),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(addTables(dataForAdding));
        return 'refreshed';
      }

      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    deleteTableReducer(state, action) {
      state.tables = state.tables.filter((table) => table.id !== action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTables.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.tables = action.payload.tables;
      })
      .addCase(addTables.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.tables = action.payload.tables;
      });
  },
});

export const { deleteTableReducer } = tablesSlice.actions;
export default tablesSlice.reducer;
