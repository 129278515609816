import { useState } from 'react';
import ModalCloseIcon from '../../images/close icon.svg';
import ingredientsModalStyles from './IngredientsModal.module.css';
import iconForAddItem from '../../images/iconForAddItem.svg';
import itemDeleteIcon from '../../images/itemDeleteIcon.svg';
import blackCloseIconSVG from '../../images/blackCloseIconSVG.svg';

function IngredientsModal({
  modalClose,
  types,
  setTypes,
  ingredients,
  setIngredients,
  setVisible,
}) {
  const [ingredientName, setIngredientName] = useState('');
  const [ingredientPrice, setIngredientPrice] = useState('');
  const [type1, setType1] = useState('');
  const [type2, setType2] = useState('');

  const addIngredient = () => {
    setIngredients([
      ...ingredients,
      { id: Math.random(), name: ingredientName, price: ingredientPrice },
    ]);
    setIngredientName('');
    setIngredientPrice('');
  };

  function deleteIngredient(id) {
    const filteredIngrd = ingredients.filter((ingredient) => ingredient.id !== id);
    setIngredients(filteredIngrd);
  }

  const addItemType = () => {
    if (type1 && type2) {
      setTypes([...types, { id: Math.random(), key1: type1, key2: type2 }]);
      setType1('');
      setType2('');
    }
    return;
  };

  return (
    <div className={ingredientsModalStyles.ModalBackground}>
      <div className={ingredientsModalStyles.ModalContainer}>
        <div className={ingredientsModalStyles.divForModalCloseIcon}>
          <img
            src={ModalCloseIcon}
            alt=""
            className={ingredientsModalStyles.ModalCloseButton}
            style={{ color: 'white', cursor: 'pointer' }}
            onClick={() => {
              modalClose();
            }}
          />
        </div>

        <div className={ingredientsModalStyles.ModalDetailsPart}>
          <p className={ingredientsModalStyles.ModalName}>Ingredients</p>
          <p className={ingredientsModalStyles.warningMessage}>
            For billable ingredients please mention the price.
          </p>

          <div className={ingredientsModalStyles.ModalDivForUserInputs}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: '10px',
              }}>
              <input
                className={ingredientsModalStyles.ModalUserInputs}
                type="text"
                value={ingredientName}
                onChange={(e) => setIngredientName(e.target.value)}
                required
              />
              <label className={ingredientsModalStyles.ModalLabelForUserInputs}>Ingredients</label>
              <div className={ingredientsModalStyles.ModalDivForItemNameAndPrice}>
                <input
                  className={ingredientsModalStyles.ModalUserInputsForPrice}
                  type="text"
                  value={ingredientPrice}
                  onChange={(e) => setIngredientPrice(e.target.value)}
                  required
                />
                <label className={ingredientsModalStyles.ModalLabelForPrice}>Price</label>
              </div>
              <div
                className={ingredientsModalStyles.circleForAddItemIcon}
                onClick={() => {
                  if (ingredientName !== '') {
                    addIngredient();
                  } else {
                    return;
                  }
                }}>
                <img src={iconForAddItem} alt="" />
              </div>
            </div>
            <p className={ingredientsModalStyles.itemDetailType}>Checkbox</p>
          </div>

          <div className={ingredientsModalStyles.divForingredientsWithPrice}>
            {ingredients.length !== 0
              ? ingredients.map((ingrd) => {
                  const id = ingrd.id;
                  return (
                    <div key={ingrd.id}>
                      <div key={id} className={ingredientsModalStyles.ingredientDivWithPrice}>
                        <p className={ingredientsModalStyles.ingredientName}>{ingrd.name}</p>
                        <p className={ingredientsModalStyles.ingredientPrice}>
                          {!ingrd.price ? '-' : ingrd.price}
                        </p>
                        <img
                          style={{ cursor: 'pointer', width: '10px', height: '10px' }}
                          src={itemDeleteIcon}
                          alt=""
                          onClick={() => deleteIngredient(id)}
                        />
                      </div>
                      <hr style={{ border: '1px solid #e7e7e7' }} />
                    </div>
                  );
                })
              : null}
          </div>

          <div className={ingredientsModalStyles.wrapper_for_inputs_type}>
            <input
              disabled={types.length === 3 ? true : false}
              className={ingredientsModalStyles.ModalUserInputsForType}
              value={type1}
              onChange={(e) => setType1(e.target.value)}
              type="text"
              placeholder="Type 1"
            />
            <input
              disabled={types.length === 3 ? true : false}
              required={type1}
              className={ingredientsModalStyles.ModalUserInputsForType}
              value={type2}
              onChange={(e) => setType2(e.target.value)}
              type="text"
              placeholder="Type 2"
            />
            <div className={ingredientsModalStyles.circleForAddItemIcon} onClick={addItemType}>
              <img src={iconForAddItem} alt="" />
            </div>
          </div>
          {/* 
          <div className={ingredientsModalStyles.ModalDivForUserInputs}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: '10px',
              }}>
              <input
                className={ingredientsModalStyles.ModalUserInputsForType}
                type="text"
                value={type}
                required
                onChange={(e) => {
                  setType(e.target.value);
                }}
              />
              <input
                className={ingredientsModalStyles.ModalUserInputsForType}
                type="text"
                value={type}
                required
                onChange={(e) => {
                  setType(e.target.value);
                }}
              />
              <label className={ingredientsModalStyles.ModalLabelForIngredientType}>Type</label>
              <div
                className={ingredientsModalStyles.circleForAddItemIcon}
                onClick={() => {
                  if (type !== '') {
                    addItemType();
                  } else {
                    return;
                  }
                }}>
                <img src={iconForAddItem} alt="" />
              </div>
            </div>
            <p className={ingredientsModalStyles.itemDetailType}>RadioButton</p>
          </div>  */}

          <div className={ingredientsModalStyles.divForingredientType}>
            {types.length
              ? types.map((item) => {
                  return (
                    <div className={ingredientsModalStyles.itemTypeDetails} key={item.id}>
                      <p>
                        {item.key1} / {item.key2}
                      </p>
                      <img
                        style={{ cursor: 'pointer' }}
                        src={blackCloseIconSVG}
                        alt=""
                        onClick={() => {
                          setTypes(types.filter((type) => type.id !== item.id));
                        }}
                      />
                    </div>
                  );
                })
              : null}
          </div>
          <div>{types.length} / 3 types</div>

          <div style={{ marginTop: '5px' }}>
            <button
              className={ingredientsModalStyles.ModalSaveButton}
              onClick={() => {
                modalClose();
                setVisible(true);
              }}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IngredientsModal;
