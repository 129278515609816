// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__WbHCa {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 150px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000000000000;
}

.style_wrapper__T51z- {
  position: fixed;
  background-color: rgb(255, 255, 255);
  width: 40%;
  border-radius: 10px;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;
}

.style_ok_btn__RqAq0 {
  background-color: rgba(66, 114, 94, 1);
  padding: 10px 36px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
  line-height: 19px;
  font-family: Inter;
  transition: 0.2s linear;
  cursor: pointer;
}

.style_ok_btn__RqAq0:hover {
  background-color: rgba(66, 114, 94, 90%);
}

.style_text__O7p\\+H {
  margin-bottom: 16px;
  text-align: center;
  font-weight: 700;
  font-family: Inter;
  color: rgba(67, 67, 67, 1);
}

.style_close_btn__z01vK {
  cursor: pointer;
  position: absolute;
  top: 7%;
  left: 94%;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailVerificationModalForSignUp/style.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,0BAA0B;EAC1B,oCAAoC;EACpC,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,UAAU;EACV,mBAAmB;EACnB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,OAAO;EACP,SAAS;AACX","sourcesContent":[".container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: calc(100% + 150px);\n  background-color: rgba(0, 0, 0, 0.2);\n  z-index: 1000000000000;\n}\n\n.wrapper {\n  position: fixed;\n  background-color: rgb(255, 255, 255);\n  width: 40%;\n  border-radius: 10px;\n  top: 30%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 21px;\n}\n\n.ok_btn {\n  background-color: rgba(66, 114, 94, 1);\n  padding: 10px 36px;\n  border-radius: 10px;\n  border: none;\n  outline: none;\n  color: white;\n  font-weight: 600;\n  line-height: 19px;\n  font-family: Inter;\n  transition: 0.2s linear;\n  cursor: pointer;\n}\n\n.ok_btn:hover {\n  background-color: rgba(66, 114, 94, 90%);\n}\n\n.text {\n  margin-bottom: 16px;\n  text-align: center;\n  font-weight: 700;\n  font-family: Inter;\n  color: rgba(67, 67, 67, 1);\n}\n\n.close_btn {\n  cursor: pointer;\n  position: absolute;\n  top: 7%;\n  left: 94%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__WbHCa`,
	"wrapper": `style_wrapper__T51z-`,
	"ok_btn": `style_ok_btn__RqAq0`,
	"text": `style_text__O7p+H`,
	"close_btn": `style_close_btn__z01vK`
};
export default ___CSS_LOADER_EXPORT___;
