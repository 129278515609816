import React, { useState } from 'react';
import styles from './style.module.css';
import { PiTrash } from 'react-icons/pi';
import { useDispatch } from 'react-redux';
import { deleteEmployee } from '../../redux/restaurantsSlice';
import DeleteEmployeeModal from './DeleteEmployeeModal';

function Employee({ role, firstName, lastName, email, phoneNumber, id }) {
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleDeleteEmployee = () => {
    dispatch(deleteEmployee({ id }));
  };

  return (
    <>
      <div className={styles.employee_wrapper}>
        <span
          className={`${styles.employee_info_item}`}
          style={{
            backgroundColor: role === 'Manager' ? '#f1dec8' : '#FFFBE9',
          }}>
          {capitalizeFirstLetter(role)}
        </span>
        <span className={styles.employee_info_item}>{firstName}</span>
        <span className={styles.employee_info_item}>{lastName}</span>
        <span className={`${styles.employee_info_item} ${styles.email}`}>{email}</span>
        <span className={styles.employee_info_item}>{phoneNumber}</span>
        <div className={styles.employee_info_item}>
          <PiTrash size={24} cursor={'pointer'} onClick={() => setDeleteModalOpen(true)} />
        </div>
      </div>
      {isDeleteModalOpen && (
        <DeleteEmployeeModal
          setDeleteModalOpen={setDeleteModalOpen}
          handleDeleteEmployee={handleDeleteEmployee}
        />
      )}
    </>
  );
}

export default Employee;
