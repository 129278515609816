import eyeOn from '../../images/eye.svg';
import eyeOff from '../../images/eyeOff.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { removeErrorMessage, signin } from '../../redux/adminSlice';
import styles from './style.module.css';
import cafeClickLogo from '../../images/cafeClickLogoWithText.png';

function SignIn() {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.admin.message);
  const isLoading = useSelector((state) => state.admin.isLoading);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: null, password: null });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailTimeout, setEmailTimeout] = useState(null);
  const [passwordTimeout, setPasswordTimeout] = useState(null);
  const [isEmailTyping, setEmailTyping] = useState(false);
  const [isPasswordTyping, setPasswordTyping] = useState(false);

  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(signin({ email, password }));
  };

  const isBtnDisabled = () => {
    return !Object.values(errors).every((err) => err === '');
  };

  const handleChangeEmail = (e) => {
    dispatch(removeErrorMessage());
    const text = e.target.value;
    setEmail(text);
    let error;
    let time;

    setEmailTyping(true);

    if (emailTimeout) {
      clearTimeout(emailTimeout);
    }

    if (
      errors.password === '' &&
      text !== '' &&
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(text)
    ) {
      time = 10;
    } else {
      time = 1000;
    }

    setEmailTimeout(
      setTimeout(() => {
        if (text === '') {
          error = 'Please enter your email address';
        } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(text)) {
          error = 'Please enter your email address in format yourname@example.com';
        } else {
          error = '';
        }

        setEmailTyping(false);
        setErrors((errors) => {
          return { ...errors, email: error };
        });
      }, time),
    );
  };

  const handleChangePassword = (e) => {
    dispatch(removeErrorMessage());
    const text = e.target.value;
    setPassword(text);
    let error;
    let time;

    setPasswordTyping(true);

    if (passwordTimeout) {
      clearTimeout(passwordTimeout);
    }

    if (
      errors.email === '' &&
      text !== '' &&
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(text)
    ) {
      time = 10;
    } else {
      time = 1000;
    }

    setPasswordTimeout(
      setTimeout(() => {
        if (text === '') {
          error = 'Please enter a password';
        } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(text)) {
          error = 'Password must contain min 8 charachters, capital letters, numbers or symbols';
        } else {
          error = '';
        }

        setPasswordTyping(false);
        setErrors((errors) => {
          return { ...errors, password: error };
        });
      }, time),
    );
  };

  const getErrorMessageForEmail = () => {
    if (isEmailTyping) {
      return '';
    } else if (errors.email) {
      return errors.email;
    } else if (message === 'User is not found!') {
      return message;
    } else {
      return '';
    }
  };

  const getErrorMessageForPassword = () => {
    if (isPasswordTyping) {
      return '';
    } else if (errors.password) {
      return errors.password;
    } else if (message === 'Incorrect password!') {
      return message;
    } else {
      return '';
    }
  };

  return (
    <div className={styles.signIn}>
      <div className={styles.bothSideSignIn}>
        <div className={styles.leftSideSignIn}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={cafeClickLogo}
              alt=""
              style={{ width: '192px', height: '86px', objectFit: 'contain' }}
            />
          </div>
          <div className={styles.leftSideBottomInfoSignIn}>
            <p>Welcome</p>
            <span>Step into Your Account The Journey Continues!</span>
          </div>
        </div>

        <div className={styles.rightSideSignIn}>
          <div className={styles.rightSideInfoSignIn}>
            <p className={styles.signInHeaderSignIn}>Sign In</p>
            <form className={styles.formForInputsSignIn} onSubmit={handleSignIn}>
              <div className={styles.divForUserInputsSignIn}>
                <input
                  autoComplete="off"
                  className={styles.userInputsSignIn}
                  type="text"
                  value={email}
                  onChange={handleChangeEmail}
                  required
                />
                <label className={styles.labelForUserInputsSignIn}>Email</label>
                <span
                  style={{
                    height: '12px',
                    marginTop: '2px',
                    color: '#FF0000',
                    fontSize: 10,
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    textIndent: 7,
                  }}>
                  {getErrorMessageForEmail()}
                </span>
              </div>
              <div className={styles.divForUserInputsSignIn}>
                <input
                  autoComplete="new-password"
                  className={styles.userInputsSignIn}
                  id="firstPasswordEyeSignIn"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handleChangePassword}
                  required
                />
                <label className={styles.labelForUserInputsSignIn}>Password</label>
                <span
                  style={{
                    height: '12px',
                    marginTop: '2px',
                    color: '#FF0000',
                    fontSize: 10,
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    textIndent: 7,
                  }}>
                  {getErrorMessageForPassword()}
                </span>
                <p
                  className={styles.forgotPasswordRow}
                  onClick={() => navigate('/forgot-password')}>
                  Forgot Password?
                </p>
                <img
                  className={styles.passwordEyeSignIn}
                  src={showPassword ? eyeOff : eyeOn}
                  onClick={() => setShowPassword(!showPassword)}
                  alt="eye"
                />
              </div>
              {/* <span>
                <input type="checkbox" id="checkbox1" name="checkbox1" />
                <label style={{ fontSize: '12px' }} htmlFor="checkbox1">
                  Remember me
                </label>
              </span> */}
              <button className={styles.SignInButton2} disabled={isLoading || isBtnDisabled()}>
                Sign in
              </button>
            </form>
            <div className={styles.rightSideBottomInfoSignIn}>
              <span className={styles.span_text}>Don't have an account?</span>
              <button className={styles.SignUpButton2} onClick={() => navigate('/signup')}>
                Sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
