import React, { useState } from 'react';
import styles from './style.module.css';
import closeIcon from '../../images/close_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { checkPassword } from '../../redux/adminSlice.js';

function VerificationModal({ setModalActive, setEditMod }) {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const { email } = useSelector((state) => state.admin.user);
  const [message, setMessage] = useState('');

  const handleCheckPassword = (e) => {
    e.preventDefault();
    dispatch(checkPassword({ email, password, setEditMod, setModalActive, setMessage }));
  };

  return (
    <div className={styles.container} onClick={() => setModalActive(false)}>
      <form
        className={styles.wrapper}
        onSubmit={handleCheckPassword}
        onClick={(e) => e.stopPropagation()}>
        <h3 className={styles.title}>Verification</h3>
        <div className={styles.input_wrapper}>
          <input
            id="password"
            className={styles.input}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {message ? (
            <span className={styles.message}>{message}</span>
          ) : (
            <label htmlFor="password" className={styles.text}>
              Please enter you CafeClick Password
            </label>
          )}
        </div>
        <button className={styles.submit_btn}>Done</button>
        <img
          className={styles.close_btn}
          src={closeIcon}
          alt="close"
          onClick={() => setModalActive(false)}
        />
      </form>
    </div>
  );
}

export default VerificationModal;
