// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_divForSideBarMenu__MyvNc {
  margin-top: 60px;
  width: 265px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  filter: drop-shadow(4px 0px 10px rgba(0, 0, 0, 0.25));
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 20px;
  padding-top: 70px;
  transform: translateX(0px);
  transition: transform 0.5s ease-out;
  z-index: 3;
}

.style_divForSideBarMenu__MyvNc.style_active__tFYtq {
  transform: translateX(-265px);
}

.style_sideBarPagesName__2YhGL {
  color: rgba(72, 72, 72, 1);
  text-align: left;
  vertical-align: text-top;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: auto;
  border-style: hidden;
  outline: none;
  text-decoration: none;
  width: 130px;
}
`, "",{"version":3,"sources":["webpack://./src/components/BurgerMenu/style.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,yBAAyB;EACzB,qDAAqD;EACrD,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,aAAa;EACb,iBAAiB;EACjB,0BAA0B;EAC1B,mCAAmC;EACnC,UAAU;AACZ;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,oBAAoB;EACpB,aAAa;EACb,qBAAqB;EACrB,YAAY;AACd","sourcesContent":[".divForSideBarMenu {\n  margin-top: 60px;\n  width: 265px;\n  height: calc(100% - 60px);\n  background-color: #ffffff;\n  filter: drop-shadow(4px 0px 10px rgba(0, 0, 0, 0.25));\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  row-gap: 20px;\n  padding-top: 70px;\n  transform: translateX(0px);\n  transition: transform 0.5s ease-out;\n  z-index: 3;\n}\n\n.divForSideBarMenu.active {\n  transform: translateX(-265px);\n}\n\n.sideBarPagesName {\n  color: rgba(72, 72, 72, 1);\n  text-align: left;\n  vertical-align: text-top;\n  font-size: 16px;\n  font-family: Inter;\n  font-weight: 400;\n  line-height: auto;\n  border-style: hidden;\n  outline: none;\n  text-decoration: none;\n  width: 130px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divForSideBarMenu": `style_divForSideBarMenu__MyvNc`,
	"active": `style_active__tFYtq`,
	"sideBarPagesName": `style_sideBarPagesName__2YhGL`
};
export default ___CSS_LOADER_EXPORT___;
