import { configureStore, combineReducers } from '@reduxjs/toolkit';
import restaurantsSlice from './restaurantsSlice';
import categoriesSlice from './categoriesSlice';
import itemsSlice from './itemsSlice';
import adminSlice from './adminSlice';
import ingredientsSlice from './ingredientsSlice';
import typesSlice from './typesSlice';
import tablesSlice from './tablesSlice';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root2',
  storage,
  blacklist: ['admin'],
};

const rootReducer = combineReducers({
  restaurants: restaurantsSlice,
  categories: categoriesSlice,
  items: itemsSlice,
  admin: adminSlice,
  ingredients: ingredientsSlice,
  types: typesSlice,
  tables: tablesSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
