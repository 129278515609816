import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeIsVerified } from '../../redux/adminSlice';
import successVerify from '../../images/successVerify.svg';
import styles from './style.module.css';
import LoadingPage from '../LoadingPage/LoadingPage';
import { URL } from '../../utils/url';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function AdminVerified() {
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    fetch(`${URL}/admins/verifyAccount/${token}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 'already verified') {
          navigate('/my-restaurants');
        }
        if (data.message === 'success') {
          setIsVerified(true);
          setIsLoading(false);
          dispatch(changeIsVerified());
        }
      })
      .catch((err) => setMessage(err.message))
      .finally(() => setIsLoading(false));
  }, [token, message, dispatch, navigate]);

  return (
    <div className={styles.global_container}>
      <Header adminHeader={true} inVerifyPage={true} isVerified={isVerified} />

      {/* case 1 */}
      {isLoading && <LoadingPage />}

      {/* case 2 */}
      {!isLoading && isVerified && (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div>
              <h3 className={styles.title}>Success!</h3>
            </div>
            <div>
              <img src={successVerify} alt="success" />
            </div>
            <p className={styles.text}>
              Your account has been successfully <br /> verified.
            </p>
            <div>
              <button className={styles.continue_btn} onClick={() => navigate('/my-restaurants')}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      {/* case 3 */}
      {!isLoading && !isVerified && (
        <div
          style={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <span
            style={{
              color: 'red',
            }}>
            Invalid token! please try again.
          </span>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default AdminVerified;
