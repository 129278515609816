import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../redux/adminSlice';
import styles from './style.module.css';
import UserInfo from './UserInfo';
import eye from '../../images/eye.svg';
import closeEye from '../../images/eyeOff.svg';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../Header/Header';
import Footer from '../Footer/Footer.jsx';

function Settings() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('info');
  const { id } = useSelector((state) => state.admin?.user);
  const inputRef = useRef(null);

  // passwords

  const [message, setMessage] = useState({ type: null, text: '' });
  const [errors, setErrors] = useState({
    password: '',
    newPassword1: '',
    newPassword2: '',
  });

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  const [isPswVisible, setIsPswVisible] = useState(false);
  const [isPswVisible2, setIsPswVisible2] = useState(false);
  const [isPswVisible3, setIsPswVisible3] = useState(false);

  const resetInputs = () => {
    setPassword('');
    setNewPassword('');
    setNewPassword2('');
  };

  const handleChangeValue = (key, value) => {
    if (key === 'password') {
      setPassword(value);
      setMessage({ type: null, message: '' });
      if (!value) {
        setErrors({ ...errors, password: 'Please enter a password' });
      } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)) {
        setErrors({
          ...errors,
          password: 'Password must contain min 8 charachters, capital letters, numbers or symbols',
        });
      } else setErrors({ ...errors, password: '' });
    } else if (key === 'newPassword') {
      setNewPassword(value);

      if (!value) {
        setErrors({ ...errors, newPassword1: 'Please enter a new password' });
      } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)) {
        setErrors({
          ...errors,
          newPassword1:
            'Password must contain min 8 charachters, capital letters, numbers or symbols',
          newPassword2: errors.newPassword2 === 'Passwords do not match' ? '' : errors.newPassword2,
        });
      } else if (value !== newPassword2) {
        setErrors({ ...errors, newPassword1: 'Passwords do not match' });
      } else setErrors({ ...errors, newPassword1: '' });
    } else {
      setNewPassword2(value);

      if (!value) {
        setErrors({ ...errors, newPassword2: 'Please confirm a new password' });
      } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value)) {
        setErrors({
          ...errors,
          newPassword2:
            'Password must contain min 8 charachters, capital letters, numbers or symbols',
        });
      } else if (value !== newPassword) {
        setErrors({ ...errors, newPassword2: 'Passwords do not match' });
      } else
        setErrors({
          ...errors,
          newPassword2: '',
          newPassword1: errors.newPassword1 === 'Passwords do not match' ? '' : errors.newPassword1,
        });
    }
  };

  const isBtnDisabled = () => {
    if (password.length && newPassword.length && newPassword2.length) {
      return !Object.values(errors).every((err) => err === '');
    }
    return true;
  };

  const showToast = (text) => {
    toast.info(text, {
      position: 'top-right',
      autoClose: 900,
      style: {
        color: '#0DA300',
      },
      icon: false,
    });
  };

  const handleSaveNewPassword = (e) => {
    e.preventDefault();
    dispatch(changePassword({ id, password, newPassword, setMessage, resetInputs, showToast }));
  };

  return (
    <div className={styles.container_global}>
      <Header adminHeader={true} inSettings={true} />

      <div className={styles.container}>
        <div className={styles.top_content}>
          <div className={styles.wrapper}>
            <h3 className={styles.accounts_settings_title}>Account Settings</h3>
            <div className={styles.tabs_wrapper}>
              <div className={styles.tab_title_wrapper}>
                <span
                  className={styles.tab_title}
                  onClick={() => setActiveTab('info')}
                  style={{
                    fontWeight: activeTab === 'info' ? '600' : '500',
                  }}>
                  User Info
                </span>
              </div>
              <div className={styles.tab_title_wrapper}>
                <span
                  className={styles.tab_title}
                  onClick={() => setActiveTab('password')}
                  style={{
                    fontWeight: activeTab === 'password' ? '600' : '500',
                  }}>
                  Password
                </span>
              </div>
              {activeTab === 'info' ? (
                <div className={styles.active_tab_left}></div>
              ) : (
                <div className={styles.active_tab_right}></div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.content_wrapper}>
        {activeTab === 'info' && <UserInfo />}

        {activeTab === 'password' && (
          <form onSubmit={handleSaveNewPassword} className={styles.password_wrapper}>
            <div>
              <ToastContainer />
            </div>
            <div className={styles.password_input_wrapper}>
              <input
                required
                id="password"
                className={styles.password_input}
                type={isPswVisible ? 'text' : 'password'}
                value={password}
                onChange={(e) => handleChangeValue('password', e.target.value)}
              />
              <label htmlFor="password" className={styles.password_label}>
                Password
              </label>
              <img
                className={styles.eye}
                src={isPswVisible ? closeEye : eye}
                alt="eyePsw"
                onClick={() => setIsPswVisible(!isPswVisible)}
              />
              {errors.password && (
                <span ref={inputRef} className={styles.password_error_message}>
                  {errors.password}
                </span>
              )}
              {message.text === 'Invalid password' && (
                <span
                  ref={inputRef}
                  className={styles.password_error_message}
                  style={{
                    top: '40px',
                    left: '15px',
                  }}>
                  {message.text}
                </span>
              )}
            </div>
            <div className={styles.password_input_wrapper}>
              <input
                required
                id="newPassword"
                className={styles.password_input}
                type={isPswVisible2 ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => handleChangeValue('newPassword', e.target.value)}
              />
              <label htmlFor="newPassword" className={styles.password_label}>
                New Password
              </label>
              <img
                className={styles.eye}
                src={isPswVisible2 ? closeEye : eye}
                alt="eyePsw"
                onClick={() => setIsPswVisible2(!isPswVisible2)}
              />
              {errors.newPassword1 && (
                <span className={styles.password_error_message}>{errors.newPassword1}</span>
              )}
            </div>
            <div className={styles.password_input_wrapper}>
              <input
                required
                id="confirmNewPassword"
                className={styles.password_input}
                type={isPswVisible3 ? 'text' : 'password'}
                value={newPassword2}
                onChange={(e) => handleChangeValue('newPassword2', e.target.value)}
              />
              <label htmlFor="confirmNewPassword" className={styles.password_label}>
                Confirm New Password
              </label>
              <img
                className={styles.eye}
                src={isPswVisible3 ? closeEye : eye}
                alt="eyePsw"
                onClick={() => setIsPswVisible3(!isPswVisible3)}
              />
              {errors.newPassword2 && (
                <span className={styles.password_error_message}>{errors.newPassword2}</span>
              )}
            </div>
            <div className={styles.saveNewBtnWrapper}>
              {message.type && (
                <>
                  {message.type === 'success' && (
                    <p className={styles.success_message}>{message.text}</p>
                  )}
                </>
              )}
              <button disabled={isBtnDisabled()} className={styles.save_btn}>
                Save
              </button>
            </div>
          </form>
        )}
      </div>

      <Footer />
    </div>
  );
}

export default Settings;
