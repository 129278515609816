import React, { useCallback, useEffect, useState } from 'react';
import Header from '../Header/Header';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import Footer from '../Footer/Footer.jsx';
import { useParams } from 'react-router';
import styles from './style.module.css';
import Employee from './Employee.jsx';
import AddEmployeeModal from './AddEmployeeModal.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from '../../redux/restaurantsSlice.js';

function Employees() {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const { id } = useParams();
  const [isModalActive, setModalActive] = useState(false);
  const dispatch = useDispatch();
  const { employees } = useSelector((state) => state.restaurants.currentRestaurant);
  useEffect(() => {
    dispatch(getEmployees({ id }));
  }, [id, dispatch]);

  const showToast = () => {
    toast.info('The new employee successfully added', {
      position: 'top-right',
      autoClose: 900,
      style: {
        color: '#0DA300',
      },
      icon: false,
    });
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape' && isModalActive) {
        setModalActive(false);
      }
      return;
    },
    [isModalActive],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const toggleSideBarMenu = () => setSideBarOpen(!sideBarOpen);

  return (
    <div className={styles.global_container}>
      <Header toggleSideBarMenu={toggleSideBarMenu} sideBarOpen={sideBarOpen} />

      <BurgerMenu id={id} sideBarOpen={sideBarOpen} page="employees" />

      <div>
        <ToastContainer />
      </div>

      <div onClick={() => setSideBarOpen(false)}>
        <div className={styles.btn_wrapper}>
          <button
            className={styles.add_employee_btn}
            onClick={() => {
              setModalActive(true);
            }}>
            Add employee
          </button>
        </div>

        <div className={styles.wrapper}>
          <header className={styles.header}>
            <div className={styles.header_item}>Role</div>
            <div className={styles.header_item}>First Name</div>
            <div className={styles.header_item}>Last Name</div>
            <div className={`${styles.header_item} ${styles.email}`}>Email</div>
            <div className={styles.header_item}>Phone Number</div>
            <div className={styles.header_item}>Delete</div>
          </header>

          <div className={styles.employees}>
            {employees?.map((employee) => {
              return <Employee key={employee.id} {...employee} />;
            })}
          </div>
        </div>
      </div>

      {isModalActive && <AddEmployeeModal setModalActive={setModalActive} showToast={showToast} />}

      <Footer />
    </div>
  );
}

export default Employees;
