import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url.js';
import { getCategoriesFirstTime } from './categoriesSlice.js';
import { getItemsFirstTime } from './itemsSlice.js';
import { getIngredientsFirstTime } from './ingredientsSlice.js';
import { getTypesFirstTime } from './typesSlice.js';
import { logout } from './adminSlice.js';

export const deleteEmployee = createAsyncThunk(
  'admin/delete-employee',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/restaurants/delete-employee/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(deleteEmployee({ id }));
        return 'refreshed';
      }

      console.log(data);
      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const getEmployees = createAsyncThunk(
  'admin/get-employees',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/restaurants/get-employees/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getEmployees({ id }));
        return 'refreshed';
      }

      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const removeRestaurant = createAsyncThunk(
  'restaurants/removeRestaurant',
  async ({ id, setMessage, setRemoveModalOpen, showToast }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/restaurants/remove-restaurant/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(message);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(removeRestaurant({ id }));
        return 'refreshed';
      }

      setRemoveModalOpen(null);
      showToast();
      dispatch(getRestaurants());
    } catch (err) {
      setMessage(err.message);
      return rejectWithValue({ message: err.message });
    }
  },
);

export const saveStripeKeys = createAsyncThunk(
  'restaurants/saveStripeKeys',
  async (keysData, { rejectWithValue, dispatch }) => {
    try {
      const { secretKey, publishableKey, id, setSuccessMessage } = keysData;

      const response = await fetch(`${URL}/restaurants/save-stripe-keys/${id}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ secretKey, publishableKey }),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(saveStripeKeys(keysData));
        return 'refreshed';
      }
      setSuccessMessage('Stripe keys successfully changed.');
      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const getAllInformationAboutCurrentRestaurant = createAsyncThunk(
  'restaurants/getAllInformationAboutCurrentRestaurant',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/restaurants/get-all-info-about-rest/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getAllInformationAboutCurrentRestaurant({ id }));
        return 'refreshed';
      }

      dispatch(getCategoriesFirstTime(data.categories));
      dispatch(getItemsFirstTime(data.items));
      dispatch(getIngredientsFirstTime(data.ingredients));
      dispatch(getTypesFirstTime(data.types));

      return data.restaurant;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const addRest = createAsyncThunk(
  'restaurants/add-restaurant',
  async ({ formData, setLoading, showToast }, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch(`${URL}/restaurants/add-restaurant`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(addRest(formData));
        return 'refreshed';
      }

      setLoading(false);
      return data.restaurants;
    } catch (err) {
      setLoading(false);
      showToast(err.message);
      return rejectWithValue({ message: err.message });
    }
  },
);

export const getRestaurants = createAsyncThunk(
  'restaurants/get-current-admin-restaurants',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch(`${URL}/restaurants/get-current-admin-restaurants`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getRestaurants());
        return 'refreshed';
      }

      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const getCurrentRestaurant = createAsyncThunk(
  'restaurants/getCurrentRestaurant',
  async (
    { id, setShowComponent, setEditValues, getCountryByPhoneCode, setPhoneNumber },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await fetch(`${URL}/restaurants/getCurrentRestaurant/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getCurrentRestaurant({ id, setShowComponent, setEditValues }));
        return 'refreshed';
      }
      if (setShowComponent) {
        setShowComponent(true);
      }
      if (setEditValues) {
        setEditValues(data.restaurant);
        getCountryByPhoneCode(data.restaurant.phoneNumber.split(' ')[0]);
        setPhoneNumber(data.restaurant.phoneNumber.split(' ')[1]);
      }

      return data.restaurant;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const updateRestaurant = createAsyncThunk(
  'restaurants/updateRestaurant',
  async (
    { formData, setShowComponent, setEditValues },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const state = getState();
      const id = state.restaurants.currentRestaurant.id;
      const response = await fetch(`${URL}/restaurants/update-restaurant/${id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(updateRestaurant({ formData, setShowComponent, setEditValues }));
        return 'refreshed';
      }

      if (setShowComponent) {
        setShowComponent(true);
      }

      if (setEditValues) {
        setEditValues(data.restaurant);
      }

      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

const restaurants = createSlice({
  name: 'restaurants',
  initialState: {
    stripe: {
      isLoading: false,
      message: '',
    },
    restaurants: [],
    currentRestaurant: {},
    message: '',
  },
  reducers: {
    removeMessage(state, action) {
      state.stripe.message = '';
    },
    updateEmployees(state, action) {
      state.currentRestaurant.employees = action.payload.employees;
    },
    editRestaurant(state, action) {
      state.restaurants = state.restaurants.map((restaurant) => {
        if (restaurant.id === action.payload.id) {
          restaurant.name = action.payload.name;
          restaurant.phoneNumber = action.payload.phoneNumber;
          restaurant.image = action.payload.image;
          restaurant.address = action.payload.address;
          restaurant.description = action.payload.description;
        } else {
          return restaurant;
        }
        return restaurant;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.currentRestaurant.employees = state.currentRestaurant.employees.filter(
          (employee) => employee.id !== +action.payload.id,
        );
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.currentRestaurant.employees = action.payload.employees;
      })
      .addCase(saveStripeKeys.pending, (state, action) => {
        state.stripe.message = '';
        state.stripe.isLoading = true;
      })
      .addCase(saveStripeKeys.rejected, (state, action) => {
        state.stripe.isLoading = false;
        state.stripe.message = action.payload.message;
      })
      .addCase(saveStripeKeys.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.stripe.isLoading = false;
      })
      .addCase(getAllInformationAboutCurrentRestaurant.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.currentRestaurant = action.payload;
      })
      .addCase(updateRestaurant.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.restaurants = action.payload.restaurants;
        state.currentRestaurant = action.payload.currentRest;
      })
      .addCase(getCurrentRestaurant.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.currentRestaurant = action.payload;
      })
      .addCase(getCurrentRestaurant.rejected, (state, action) => {
        state.message = action.payload.message;
      })
      .addCase(getRestaurants.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.restaurants = action.payload;
      })
      .addCase(addRest.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.restaurants = action.payload;
      })
      .addCase(getRestaurants.rejected, (state, action) => {
        state.message = action.payload.message;
      });
  },
});

export default restaurants.reducer;
export const { editRestaurant, updateEmployees, removeMessage } = restaurants.actions;
