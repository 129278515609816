import { useRef, useState } from 'react';
import './Modal.css';
import ModalCloseIcon from '../../images/close icon.svg';
import GeocodeLocation from '../../GeocodeLocation';
import { useDispatch } from 'react-redux';
import { addRest } from '../../redux/restaurantsSlice';
import { v4 as uuidv4 } from 'uuid';
import countries from '../../data/countries.json';
import styles from '../Signup/style.module.css';
import Select from 'react-select';
import itemAddStyles from '../ItemAdd/ItemAdd.module.css';
import { FlagIcon } from 'react-flag-kit';

function Modal({ modalOpen, modalClose, setLoading, showToast }) {
  // Restaurant data with validation
  const [values, setValues] = useState({
    name: '',
    address: '',
    description: '',
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const fileInput = useRef();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState({ value: '+1', label: '(+1)', code: 'US' });

  const countriesOptions = countries.map((country) => {
    const { dial_code, code } = country;
    return { value: dial_code, label: `(${dial_code})`, code };
  });

  const fullPhoneNumber = `${countryCode.value} ${phoneNumber}`;

  const hasNumbers = (text) => {
    return /\d/.test(text);
  };

  const validateName = (e) => {
    if (!e) {
      return 'Restaurant name is required !';
    }
    return '';
  };

  const validatePhoneNumber = (e) => {
    if (!e) {
      return 'Phone Number is required !'; //
    } else if (e.length < 3 && e.length > 0) {
      return 'Phone number must contain min 2 numbers';
    }
    return '';
  };

  const validateAddress = (e) => {
    if (!e) {
      return "Restaurant's address is required !";
    } else if (!hasNumbers(e)) {
      return "Restaurant's address must contain numbers.";
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    const newValues = { ...values, [field]: value };
    setValues(newValues);
    if (field !== 'description') {
      const newErrors = {
        ...errors,
        [field]: validateField(field, value),
      };
      setErrors(newErrors);
    }
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'name':
        return validateName(value);
      case 'phoneNumber':
        return validatePhoneNumber(value);
      case 'address':
        return validateAddress(value);
      default:
        return '';
    }
  };

  // Google Map Geocoding

  let apiKey = 'AIzaSyB6HUj_uw4KoaBogJFTbP1_RdO7gYWwfqE';
  let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${values.address}&key=${apiKey}`;
  const [latitude, setLatitude] = useState(40.17769453337888);
  const [longitude, setLongitude] = useState(44.51265097282707);

  async function getRestAddress() {
    try {
      const response = await fetch(api);
      let data = await response.json();
      const { lng, lat } = data.results[0]?.geometry?.location;

      if (lat && lng) {
        setLatitude(lat);
        setLongitude(lng);
      }
    } catch (error) {
      return error.message;
    }
  }

  //Dispatch method for adding restaurant with checking validation
  const [file, setFile] = useState(null);

  const addNewRest = (e) => {
    e.preventDefault();
    setLoading(true);
    modalClose();

    const validationErrors = {
      name: validateField('name', values.name),
      phoneNumber: validateField('phoneNumber', phoneNumber),
      address: validateField('address', values.address),
    };

    if (Object.values(validationErrors).some((error) => error !== '')) {
      setErrors(validationErrors);
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', values.name);
    formData.append('phoneNumber', fullPhoneNumber);
    formData.append('address', values.address);
    formData.append('description', values.description);
    formData.append('id', uuidv4());
    dispatch(addRest({ formData, setLoading, showToast }));
  };

  // Getting user's chosen file image
  const [previewImage, setPreviewImage] = useState(null);

  const handleImageChange = (event) => {
    setFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const isSaveBtnDisabled = () => {
    if (Object.keys(errors).length === 3 && Object.values(errors).every((err) => err === '')) {
      return false;
    }

    return true;
  };

  const filterInputValue = (e) => {
    if (phoneNumber.length) {
      if (
        e.key === '+' ||
        e.key === 'e' ||
        e.key === '-' ||
        e.key === '.' ||
        e.key === ',' ||
        e.key === 'E'
      ) {
        e.preventDefault();
      }
      return;
    }
    if (
      e.key === '+' ||
      e.key === '0' ||
      e.key === 'e' ||
      e.key === '-' ||
      e.key === '.' ||
      e.key === ',' ||
      e.key === 'E'
    ) {
      e.preventDefault();
    }
  };

  const handleChangePhoneNumber = (e) => {
    let error;
    if (e.target.value.length < 3 && e.target.value.length > 0) {
      error = 'Phone number must contain min 2 numbers';
    } else if (e.target.value.length === 0) {
      error = 'Please enter your phone number';
    } else {
      setErrors({ ...errors, phoneNumber: '' });
    }

    if (error) {
      setErrors({ ...errors, phoneNumber: error });
    }
    setPhoneNumber(e.target.value);
  };

  const handleRemoveIcon = () => {
    setFile('default');
    setPreviewImage(null);
  };

  const modalHeight = document.documentElement.scrollHeight;

  if (modalOpen === false) return null;
  return (
    <div
      className="ModalBackground"
      style={{
        height: modalHeight,
      }}>
      <div className="ModalContainer" onClick={(e) => e.stopPropagation()}>
        <div className="divForModalCloseIcon">
          <img
            src={ModalCloseIcon}
            alt=""
            className="ModalCloseButton"
            style={{ color: 'white', cursor: 'pointer' }}
            onClick={() => {
              modalClose();
            }}
          />
        </div>

        <form onSubmit={addNewRest} className="ModalDetailsPart">
          <p className="ModalName">Restaurant Details</p>
          <div className="ModalDivForUserInputs">
            <input
              className="ModalUserInputs"
              type="text"
              value={values.name}
              onChange={function (e) {
                handleInputChange('name', e.target.value);
              }}
            />
            <label className="ModalLabelForUserInputs">Name</label>
            {errors.name && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.name}
              </p>
            )}
          </div>

          <div
            style={{
              width: '380px',
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              position: 'relative',
            }}>
            <div
              id="phoneNumber"
              style={{
                width: '100%',
                borderBottom: '1px #979797 solid',
                display: 'flex',
                alignItems: 'center',
              }}>
              <div className={styles.divForUserInputs}>
                <Select
                  options={countriesOptions}
                  value={countryCode}
                  getOptionLabel={(e) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                        }}>
                        <FlagIcon code={e.code} size={18} /> <span>{e.label}</span>
                      </div>
                    );
                  }}
                  onChange={setCountryCode}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      minWidth: state.isFocused ? '104.66px' : null,
                      boxShadow: state.isFocused ? 'none' : null,
                      borderColor: state.isFocused ? '#ccc' : null,
                      cursor: 'pointer',
                      border: state.isFocused ? 'none' : 'none',
                      outline: state.isFocused ? 'none' : 'none',
                    }),
                  }}
                />
              </div>
              <input
                style={{
                  paddingLeft: '16px',
                  border: 'none',
                  width: '64%',
                  transform: 'translate(-10px, 0px)',
                }}
                type="number"
                value={phoneNumber}
                placeholder="Phone Number"
                className={styles.userInputs}
                onKeyDown={filterInputValue}
                onChange={handleChangePhoneNumber}
              />
            </div>
            {errors.phoneNumber && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.phoneNumber}
              </p>
            )}
          </div>

          <div className="ModalDivForRestLogo">
            <label className="ModalLabelForRestLogo logo_label">Logo</label>
            <div className="ModalDivForInputAndLogo">
              <button
                type="button"
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                  background: '#E7E7E7',
                  boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '5px 20px',
                  marginTop: '10px',
                  cursor: 'pointer',
                  color: '#484848',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  border: 'none',
                  height: '24.5px',
                }}
                onClick={() => {
                  fileInput.current.click();
                }}>
                Choose file
              </button>
              {!previewImage && <span className="no_file_choosen_message">No file chosen</span>}
              <input
                style={{
                  opacity: '0',
                  height: '0',
                  width: '0',
                  lineHeight: '0',
                  overflow: 'hidden',
                  padding: '0',
                  margin: '0',
                }}
                ref={fileInput}
                className="ModalRestLogo"
                type="file"
                onChange={handleImageChange}
                accept=".png, .jpeg, .jpg, .svg"
              />
              {previewImage && (
                <>
                  <img
                    style={{
                      marginTop: '10px',
                    }}
                    className="ModalRestImage"
                    src={previewImage}
                    alt="Selected"
                  />
                  <button
                    onClick={handleRemoveIcon}
                    type="button"
                    className={itemAddStyles.removeIconBtn}>
                    X
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="ModalDivForUserInputs">
            <input
              className="ModalUserInputs"
              type="text"
              value={values.address}
              onChange={function (e) {
                handleInputChange('address', e.target.value);
                getRestAddress();
              }}
            />
            <label className="ModalLabelForUserInputs">Address</label>
            {errors.address && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.address}
              </p>
            )}
          </div>
          {
            <GeocodeLocation
              address={values.address}
              setValues={setValues}
              getRestAddress={getRestAddress}
              apiKey={apiKey}
              latitude={latitude}
              longitude={longitude}
            />
          }
          <div className="ModalDivForUserInputs">
            <textarea
              style={{
                maxHeight: '27px',
                marginTop: '4px',
                resize: 'none',
                transition: 'all 0.3s ease-in-out',
              }}
              className="ModalUserInputs"
              value={values.description}
              onChange={function (e) {
                handleInputChange('description', e.target.value);
              }}
            />
            <label className="ModalLabelForUserInputs">About Restaurant</label>
          </div>

          <button disabled={isSaveBtnDisabled()} type="submit" className="ModalSaveButton">
            Save
          </button>
        </form>
      </div>
    </div>
  );
}

export default Modal;
