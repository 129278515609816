import { useState } from 'react';
import { useNavigate } from 'react-router';
import ForgotPasswordStyles from './ForgotPassword.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { sendEmail } from '../../redux/adminSlice.js';
import cafeClickLogo from '../../images/cafeClickLogoWithText.png';

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.admin.mailSendingLoading);
  const message = useSelector((state) => state.admin.message);
  const [values, setValues] = useState({
    email: '',
  });

  const [errors, setErrors] = useState({});

  const validateEmail = (e) => {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(e)) {
      return 'Please enter your email address in format yourname@example.com';
    } else if (values.email === '') {
      return 'Please enter your email address';
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    const newvalues = { ...values, [field]: value };
    setValues(newvalues);

    const newErrors = {
      ...errors,
      [field]: validateField(field, value),
    };
    setErrors(newErrors);
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'email':
        return validateEmail(value);
      default:
        return null;
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    dispatch(sendEmail({ email: values.email, navigate }));
  };

  return (
    <div className={ForgotPasswordStyles.ForgotPassword}>
      <div className={ForgotPasswordStyles.bothSideFP}>
        <div className={ForgotPasswordStyles.leftSideFP}>
          <img
            src={cafeClickLogo}
            alt=""
            style={{ width: '192px', height: '86px', objectFit: 'contain' }}
          />
          <div className={ForgotPasswordStyles.leftSideBottomInfoFP}>
            <p>Welcome</p>
            <span>Step into Your Account The Journey Continues!</span>
          </div>
        </div>

        <div className={ForgotPasswordStyles.rightSideFP}>
          <div className={ForgotPasswordStyles.rightSideInfoFP}>
            <p className={ForgotPasswordStyles.signInHeaderFP}>Forgot Password</p>
            <form className={ForgotPasswordStyles.formForInputsFP} onSubmit={handleSendMessage}>
              <div className={ForgotPasswordStyles.divForUserInputsFP}>
                <input
                  className={ForgotPasswordStyles.userInputsFP}
                  type="text"
                  value={values.email}
                  onChange={(e) => handleInputChange('email', e.target.value)}
                  required
                />
                <label className={ForgotPasswordStyles.labelForUserInputsFP}>Email</label>
                {errors.email && (
                  <p
                    style={{
                      color: '#FF0000',
                      fontSize: 10,
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                      textIndent: 7,
                    }}>
                    {errors.email}
                  </p>
                )}
              </div>
              <button disabled={isLoading} className={ForgotPasswordStyles.ContinueButton}>
                Continue
              </button>
              {message && <h3>{message}</h3>}
            </form>
            <div className={ForgotPasswordStyles.rightSideBottomInfoFP}>
              <p>Don't have an account?</p>
              <button
                className={ForgotPasswordStyles.SignUpButton}
                onClick={() => {
                  navigate('/signup');
                }}>
                Sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
