// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__lIEkV {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_wrapper__nsrXW {
  padding: 10px;
}

.style_section__6gmrZ {
  margin-bottom: 10%;
}

.style_content_wrapper__3MWty {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.style_oops_wrapper__NDlHX {
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_top_text__RHLf0 {
  font-weight: 500;
  font-size: 24px;
  font-family: Montserrat;
  color: #1f1f1f;
}

.style_span_4__V7nVl {
  color: #42725e;
  font-family: Inter;
  font-size: 128px;
  font-weight: 600;
}

.style_not_found_message__BoNSv {
  color: #1f1f1f;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
}

.style_btn__yXwzS {
  outline: none;
  border: none;
  color: white;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  padding: 10px 48px;
  border-radius: 10px;
  background-color: #42725e;
  cursor: pointer;
  transition: 0.2s linear;
}

.style_btn__yXwzS:hover {
  background-color: #42725ecf;
}
`, "",{"version":3,"sources":["webpack://./src/components/NotFound/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,OAAO;AACT;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".container {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.wrapper {\n  padding: 10px;\n}\n\n.section {\n  margin-bottom: 10%;\n}\n\n.content_wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 5%;\n}\n\n.oops_wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.top_text {\n  font-weight: 500;\n  font-size: 24px;\n  font-family: Montserrat;\n  color: #1f1f1f;\n}\n\n.span_4 {\n  color: #42725e;\n  font-family: Inter;\n  font-size: 128px;\n  font-weight: 600;\n}\n\n.not_found_message {\n  color: #1f1f1f;\n  font-family: Montserrat;\n  font-size: 32px;\n  font-weight: 600;\n}\n\n.btn {\n  outline: none;\n  border: none;\n  color: white;\n  font-size: 16px;\n  font-family: Inter;\n  font-weight: 600;\n  padding: 10px 48px;\n  border-radius: 10px;\n  background-color: #42725e;\n  cursor: pointer;\n  transition: 0.2s linear;\n}\n\n.btn:hover {\n  background-color: #42725ecf;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__lIEkV`,
	"wrapper": `style_wrapper__nsrXW`,
	"section": `style_section__6gmrZ`,
	"content_wrapper": `style_content_wrapper__3MWty`,
	"oops_wrapper": `style_oops_wrapper__NDlHX`,
	"top_text": `style_top_text__RHLf0`,
	"span_4": `style_span_4__V7nVl`,
	"not_found_message": `style_not_found_message__BoNSv`,
	"btn": `style_btn__yXwzS`
};
export default ___CSS_LOADER_EXPORT___;
