import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './style.module.css';
import menuStrct from '../../images/menuStrct.svg';
import orders from '../../images/orders.svg';
import myRests from '../../images/myRests.svg';
import settings from '../../images/settings_for_burger.svg';
import menuTmplt from '../../images/menuTmplt.svg';
import qr_table from '../../images/qr_table.svg';
import payments from '../../images/payments.svg';
import employee from '../../images/employee.svg';
import { useSelector } from 'react-redux';

function BurgerMenu({ id, sideBarOpen, page, menuRef }) {
  const { user } = useSelector((state) => state.admin);

  if (user?.role === 'waiter') {
    return (
      <div
        ref={menuRef}
        id="burgerMenu"
        className={`${styles.divForSideBarMenu} ${!sideBarOpen ? styles.active : ''}`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '30px',
            alignItems: 'center',
            borderBottom: '1px solid #d1d1d1',
          }}>
          <img style={{ width: '16px', height: '16px' }} src={orders} alt="" />
          <NavLink
            style={{
              fontWeight: page === 'orderList' ? '700' : '400',
            }}
            to={`/order-list/${id}`}
            className={styles.sideBarPagesName}>
            Order List
          </NavLink>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '30px',
            alignItems: 'center',
            borderBottom: '1px solid #d1d1d1',
          }}>
          <img style={{ width: '16px', height: '16px' }} src={settings} alt="settings" />
          <NavLink to={`/settings`} className={styles.sideBarPagesName}>
            Settings
          </NavLink>
        </div>
      </div>
    );
  }

  if (user?.role === 'manager') {
    return (
      <div
        ref={menuRef}
        id="burgerMenu"
        className={`${styles.divForSideBarMenu} ${!sideBarOpen ? styles.active : ''}`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '30px',
            alignItems: 'center',
            borderBottom: '1px solid #d1d1d1',
          }}>
          <img style={{ width: '16px', height: '16px' }} src={menuTmplt} alt="template" />
          <NavLink
            style={{
              fontWeight: page === 'menu-template' ? '700' : '400',
            }}
            to={`/menu-template/${id}`}
            className={styles.sideBarPagesName}>
            Menu Template
          </NavLink>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '30px',
            alignItems: 'center',
            borderBottom: '1px solid #d1d1d1',
          }}>
          <img style={{ width: '16px', height: '16px' }} src={qr_table} alt="" />
          <NavLink
            style={{
              fontWeight: page === 'qr-tables' ? '700' : '400',
            }}
            to={`/rest-tables/${id}`}
            className={styles.sideBarPagesName}>
            Table QRs
          </NavLink>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '30px',
            alignItems: 'center',
            borderBottom: '1px solid #d1d1d1',
          }}>
          <img style={{ width: '16px', height: '16px' }} src={orders} alt="" />
          <NavLink
            style={{
              fontWeight: page === 'orderList' ? '700' : '400',
            }}
            to={`/order-list/${id}`}
            className={styles.sideBarPagesName}>
            Order List
          </NavLink>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '30px',
            alignItems: 'center',
            borderBottom: '1px solid #d1d1d1',
          }}>
          <img style={{ width: '16px', height: '16px' }} src={settings} alt="settings" />
          <NavLink to={`/settings`} className={styles.sideBarPagesName}>
            Settings
          </NavLink>
        </div>
      </div>
    );
  }

  return (
    <div
      ref={menuRef}
      id="burgerMenu"
      className={`${styles.divForSideBarMenu} ${!sideBarOpen ? styles.active : ''}`}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #d1d1d1',
        }}>
        <img style={{ width: '16px', height: '16px' }} src={myRests} alt="my-restaurants" />
        <NavLink to="/my-restaurants" className={styles.sideBarPagesName}>
          My Restaurants
        </NavLink>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #d1d1d1',
        }}>
        <img style={{ width: '16px', height: '16px' }} src={menuStrct} alt="item" />
        <NavLink
          to={`/menu-structure/${id}`}
          style={{
            fontWeight: page === 'menu-structure' ? '700' : '400',
          }}
          className={styles.sideBarPagesName}>
          Menu Structure
        </NavLink>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #d1d1d1',
        }}>
        <img style={{ width: '16px', height: '16px' }} src={menuTmplt} alt="template" />
        <NavLink
          style={{
            fontWeight: page === 'menu-template' ? '700' : '400',
          }}
          to={`/menu-template/${id}`}
          className={styles.sideBarPagesName}>
          Menu Template
        </NavLink>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #d1d1d1',
        }}>
        <img style={{ width: '16px', height: '16px' }} src={employee} alt="employee" />
        <NavLink
          style={{
            fontWeight: page === 'employees' ? '700' : '400',
          }}
          to={`/employees/${id}`}
          className={styles.sideBarPagesName}>
          Employees
        </NavLink>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #d1d1d1',
        }}>
        <img style={{ width: '16px', height: '16px' }} src={qr_table} alt="" />
        <NavLink
          style={{
            fontWeight: page === 'qr-tables' ? '700' : '400',
          }}
          to={`/rest-tables/${id}`}
          className={styles.sideBarPagesName}>
          Table QRs
        </NavLink>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #d1d1d1',
        }}>
        <img style={{ width: '16px', height: '16px' }} src={orders} alt="" />
        <NavLink
          style={{
            fontWeight: page === 'orderList' ? '700' : '400',
          }}
          to={`/order-list/${id}`}
          className={styles.sideBarPagesName}>
          Order List
        </NavLink>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #d1d1d1',
        }}>
        <img style={{ width: '16px', height: '16px' }} src={payments} alt="" />
        <NavLink
          style={{
            fontWeight: page === 'payments' ? '700' : '400',
          }}
          to={`/payments/${id}`}
          className={styles.sideBarPagesName}>
          Payments
        </NavLink>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '30px',
          alignItems: 'center',
          borderBottom: '1px solid #d1d1d1',
        }}>
        <img style={{ width: '16px', height: '16px' }} src={settings} alt="settings" />
        <NavLink to={`/settings`} className={styles.sideBarPagesName}>
          Settings
        </NavLink>
      </div>
    </div>
  );
}

export default BurgerMenu;
