import { useRef, useState } from 'react';
import itemAddStyles from './ItemAdd.module.css';
import iconForAddItem from '../../images/iconForAddItem.svg';
import { useDispatch, useSelector } from 'react-redux';
import IngredientsModal from '../IngredientsModal/IngredientsModal';
import { addItem } from '../../redux/itemsSlice';
import { URL as ServerURL } from '../../utils/url.js';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../Header/Header.jsx';
import BurgerMenu from '../BurgerMenu/BurgerMenu.jsx';
import Footer from '../Footer/Footer.jsx';
import { useNavigate } from 'react-router';

function ItemAdd() {
  const navigate = useNavigate();
  const categories = useSelector((state) => state.categories.categories);
  const { id } = useSelector((state) => state.restaurants.currentRestaurant);
  const [modalOpen, setModalOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [parentID, setParentID] = useState('');
  const [isBtnSubmitted, setBtnSubmitted] = useState(false);
  const fileInput = useRef();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [values, setValues] = useState({
    name: '',
    price: '',
    description: '',
    itemTypesArr: types,
    itemIngredientsArr: ingredients,
  });

  const validateNameOfItem = (e) => {
    if (e.length < 2) {
      return 'Item name should contain minimum two characters';
    } else if (!/^[A-Za-z' -]+$/.test(e)) {
      return 'Please enter your item name';
    }
    return '';
  };

  const validateParentCategory = (e) => {
    if (e === '' || e === 'Choose parent category') {
      return 'Please select Parent Category';
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    const newvalues = { ...values, [field]: value };
    setValues(newvalues);
    const newErrors = {
      ...errors,
      [field]: validateField(field, value),
    };
    setErrors(newErrors);
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'nameOfItem':
        return validateNameOfItem(value);
      case 'parentCategory':
        return validateParentCategory(value);
      default:
        return '';
    }
  };

  const handleImageChange = (event) => {
    setFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const toggleSideBarMenu = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const resetInformation = () => {
    setPreviewImage(null);
    setFile(null);
    setValues({
      ...values,
      name: '',
      price: '',
      description: '',
    });
    setParentID('');
    setIngredients([]);
    setTypes([]);
  };

  const [visible, setVisible] = useState(false);

  const showToast = () => {
    toast.info('Your item is successfully added.', {
      position: 'top-right',
      autoClose: 900,
      style: {
        color: '#0DA300',
      },
      icon: false,
    });
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    setBtnSubmitted(true);
    if (values.name.length >= 2) {
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('categoryID', parentID || 'without');
      formData.append('file', file);
      formData.append('description', values.description);
      formData.append('price', values.price);
      formData.append('ingredients', JSON.stringify(ingredients));
      formData.append('types', JSON.stringify(types));
      dispatch(addItem({ id, formData, resetInformation, setBtnSubmitted, showToast }));
    }
  };

  const handleRemoveIcon = () => {
    setPreviewImage(`${ServerURL}/images/defaultItemIcon.png`);
    setFile('default');
  };

  return (
    <>
      <div className={itemAddStyles.ItemAdd}>
        <Header toggleSideBarMenu={toggleSideBarMenu} sideBarOpen={sideBarOpen} />

        <BurgerMenu id={id} sideBarOpen={sideBarOpen} page="menu-structure" />

        <form
          className={itemAddStyles.ModalDetailsPart}
          onSubmit={handleAddItem}
          onClick={() => setSideBarOpen(false)}>
          <div onClick={() => navigate(`/menu-structure/${id}`)}>
            <ToastContainer />
          </div>
          <p className={itemAddStyles.ModalName}>Add Item</p>
          <div className={itemAddStyles.ModalDivForUserInputs}>
            <select
              className={itemAddStyles.ModalUserInputs}
              name="ParentCategories"
              value={parentID}
              onChange={(e) => setParentID(e.target.value)}>
              <option defaultValue={''} value={''}>
                Choose category
              </option>
              {categories.map((item) => {
                if (!item.parentID) {
                  return (
                    <option style={{ fontWeight: 'bold' }} key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                } else if (item.parentID && !item.isLastChild) {
                  return (
                    <option style={{ fontWeight: '500' }} key={item.id} value={item.id}>
                      &nbsp;&nbsp;{item.name}
                    </option>
                  );
                } else if (item.parentID && item.isLastChild) {
                  return (
                    <option key={item.id} value={item.id}>
                      &nbsp;&nbsp;&nbsp;&nbsp;{item.name}
                    </option>
                  );
                } else {
                  return null;
                }
              })}
            </select>
            <label className={itemAddStyles.ModalLabelForUserInputs}>Parent Category</label>
            {errors.parentCategory && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.parentCategory}
              </p>
            )}
          </div>

          <div className={itemAddStyles.ModalDivForUserInputs}>
            <input
              className={itemAddStyles.ModalUserInputs}
              type="text"
              value={values.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              required
            />
            <label className={itemAddStyles.ModalLabelForUserInputs}>Name</label>
            {errors.nameOfItem && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.nameOfItem}
              </p>
            )}
          </div>

          <div className={itemAddStyles.ModalDivForUserInputs}>
            <input
              className={itemAddStyles.ModalUserInputs}
              type="text"
              value={values.price}
              onChange={(e) => handleInputChange('price', e.target.value)}
              required
            />
            <label className={itemAddStyles.ModalLabelForUserInputs}>Price</label>
          </div>

          <div className={itemAddStyles.ModalDivForRestLogo}>
            <label className={itemAddStyles.ModalLabelForRestLogo}>Image</label>
            <div className={itemAddStyles.ModalDivForInputAndLogo}>
              <button
                type="button"
                style={{
                  outline: 'none',
                  borderRadius: '5px',
                  background: '#E7E7E7',
                  boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                  padding: '4px 20px',
                  marginTop: '10px',
                  cursor: 'pointer',
                  color: '#484848',
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  border: 'none',
                }}
                onClick={() => {
                  fileInput.current.click();
                }}>
                Choose file
              </button>
              <input
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                style={{
                  opacity: '0',
                  height: '0',
                  width: '0',
                  lineHeight: '0',
                  overflow: 'hidden',
                  padding: '0',
                  margin: '0',
                }}
                ref={fileInput}
                type="file"
                onChange={handleImageChange}
                accept=".png, .jpeg, .jpg, .svg"
              />

              {(!previewImage || previewImage === `${ServerURL}/images/defaultItemIcon.png`) && (
                <p
                  style={{
                    fontSize: '12px',
                    fontFamily: 'Inter',
                    color: '#484848',
                    transform: 'translate(20px, 14px)',
                  }}>
                  No file chosen
                </p>
              )}

              {previewImage && previewImage !== `${ServerURL}/images/defaultItemIcon.png` && (
                <>
                  <img className={itemAddStyles.ModalRestImage} src={previewImage} alt="Selected" />
                  <button
                    type="button"
                    onClick={handleRemoveIcon}
                    className={itemAddStyles.removeIconBtn}>
                    X
                  </button>
                </>
              )}
            </div>
          </div>

          <div className={itemAddStyles.ModalDivForIngredients}>
            <label className={itemAddStyles.itemDetailsLabel}>Item Details</label>
            <div
              className={itemAddStyles.divForAddItemIcon}
              onClick={() => {
                setModalOpen(true);
                setSideBarOpen(false);
                setVisible(false);
              }}>
              <img src={iconForAddItem} alt="" />
            </div>
          </div>

          <div
            className={
              visible === false ? itemAddStyles.tablesNonActive : itemAddStyles.tablesActive
            }>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '15px',
              }}>
              <div
                className={
                  ingredients.length || types.length
                    ? itemAddStyles.twoTablesOfIngredient
                    : itemAddStyles.twoTablesOfIngredientActive
                }>
                <table className={itemAddStyles.tableForIngAndPrice}>
                  <thead>
                    <tr>
                      <th>Ingredient</th>
                      <th>Price</th>
                    </tr>
                  </thead>

                  <tbody>
                    {ingredients.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td style={{ width: '100px' }}>{item.name}</td>
                          <td style={{ width: '100px' }}>{!item.price ? '-' : item.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <table
                  className={
                    types.length ? itemAddStyles.tableForType : itemAddStyles.tableForTypeActive
                  }>
                  <thead>
                    <tr>
                      <th colSpan="2" style={{ width: '100px' }}>
                        Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {types.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td style={{ height: '30px' }}>{item.key1}</td>
                          <td style={{ height: '30px' }}>{item.key2}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className={itemAddStyles.ModalDivForUserInputs}>
            <textarea
              style={{ maxHeight: '27px', resize: 'none', transition: 'all 0.3s ease-in-out' }}
              className={itemAddStyles.ModalUserInputs}
              value={values.description}
              onChange={function (e) {
                handleInputChange('description', e.target.value);
              }}
              required
            />
            <label className={itemAddStyles.ModalLabelForUserInputs}>Description</label>
          </div>

          <button
            type="submit"
            className={itemAddStyles.ModalSaveButton}
            onClick={handleAddItem}
            disabled={values.name.length < 2 || !values.price || isBtnSubmitted}>
            Save
          </button>
        </form>

        <Footer />
      </div>

      {modalOpen && (
        <IngredientsModal
          setVisible={setVisible}
          ingredients={ingredients}
          setIngredients={setIngredients}
          types={types}
          setTypes={setTypes}
          modalOpen={modalOpen}
          modalClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
}

export default ItemAdd;
