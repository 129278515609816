import React from 'react';
import styles from './style.module.css';
import { useNavigate } from 'react-router';
import logo from '../../images/notFoundPageLogo.png';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.section + ' ' + styles.oops_wrapper}>
          <h3 className={styles.top_text}>Ooops</h3>
        </div>
        <div className={styles.section + ' ' + styles.content_wrapper}>
          <span className={styles.span_4}>4</span>
          <img src={logo} alt="not found" />
          <span className={styles.span_4}>4</span>
        </div>
        <div
          className={styles.section}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <h3 className={styles.not_found_message}>Page not found!</h3>
        </div>
        <div
          className={styles.section}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '100px',
          }}>
          <button onClick={() => navigate('/')} className={styles.btn}>
            Go to home page
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
