import React, { useState } from 'react';
import styles from './style.module.css';
import closeModal from '../../images/close_modal.svg';
import { useDispatch } from 'react-redux';
import { removeRestaurant } from '../../redux/restaurantsSlice';

function DeleteModalForRestaurants({ id, setRemoveModalOpen, showToast }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');

  const handleDeleteCategory = () => {
    dispatch(removeRestaurant({ id, setMessage, setRemoveModalOpen, showToast }));
  };

  const modalHeight = document.documentElement.scrollHeight;

  return (
    <div
      className={styles.container}
      style={{
        height: modalHeight,
      }}
      onClick={() => setRemoveModalOpen(null)}>
      <div
        className={styles.wrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <h3 className={styles.title}>Are you sure?</h3>
        <img
          src={closeModal}
          alt="close"
          className={styles.close}
          onClick={() => setRemoveModalOpen(null)}
        />
        <p className={styles.text}>This action will be delete your restaurant.</p>
        {message && (
          <span
            style={{
              color: 'red',
            }}>
            {message}
          </span>
        )}
        <div className={styles.modal_btns}>
          <button className={styles.remove_btn} onClick={handleDeleteCategory}>
            Delete
          </button>
          <button onClick={() => setRemoveModalOpen(null)} className={styles.cancel_btn}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModalForRestaurants;
