import React from 'react';
import styles from './style.module.css';

function ProductInOrder({ product }) {
  const { ingredients, types } = product;

  return (
    <div
      style={{
        padding: '16px 8px',
        borderBottom: '1px solid #D9D9D9',
      }}>
      <div className={styles.name_and_count_wrapper}>
        <span className={styles.name_text}>{product.name}</span>
        <span className={styles.count_text}>{product.count}</span>
      </div>
      <div
        style={{
          marginTop: '6px',
        }}>
        {ingredients.map((ingredient) => {
          return (
            <span className={styles.additionalInformation} key={ingredient.id}>
              {ingredient.name + ', '}
            </span>
          );
        })}
        {types.map((type) => {
          return (
            <span className={styles.additionalInformation} key={type.id}>
              {type.value + ', '}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default ProductInOrder;
