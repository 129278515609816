import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resendEmailForStripe } from '../../redux/adminSlice';
import styles from './style.module.css';
import { useParams } from 'react-router';
import edit_pencil from '../../images/edit_pencil.svg';
import VerificationModal from '../VerificationModal/VerificationModal';
import stripe from '../../images/stripe.svg';
import Timer from '../Timer/Timer';
import { removeMessage, saveStripeKeys } from '../../redux/restaurantsSlice';
import Header from '../Header/Header';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import Footer from '../Footer/Footer.jsx';

function Payments() {
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const toggleSideBarMenu = () => setSideBarOpen(!sideBarOpen);
  const { id } = useParams();
  const { message, isLoading } = useSelector((state) => state.restaurants.stripe);
  const [isModalActive, setModalActive] = useState(false);
  const { isVerified, email, id: userID, firstName } = useSelector((state) => state.admin.user);
  const [successMessage, setSuccessMessage] = useState('');
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [flag, setFlag] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  // stripe keys for this restaurant
  const { publishableKey, secretKey } = useSelector((state) => state.restaurants.currentRestaurant);

  // edited stripe keys for edit them
  const [editedPublishableKey, setEditedPublishableKey] = useState(publishableKey || '');
  const [editedSecretKey, setEditedSecretKey] = useState(secretKey || '');

  // inputs disabled state
  const [inputsDisabled, setInputsDisabled] = useState(editedPublishableKey || editedSecretKey);

  // stop scroll for active modal
  document.body.style.overflow = isModalActive ? 'hidden' : 'auto';

  const setEditMod = () => {
    setInputsDisabled(false);
    setEditedPublishableKey('');
    setEditedSecretKey('');
  };

  const handleSaveKeys = async (e) => {
    e.preventDefault();
    dispatch(
      saveStripeKeys({
        secretKey: editedSecretKey,
        publishableKey: editedPublishableKey,
        id,
        setSuccessMessage,
      }),
    );
  };

  useEffect(() => {
    return () => {
      dispatch(removeMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      const element = e.target;
      if (menuRef?.current) {
        if (
          !menuRef.current.contains(element) &&
          element.id !== 'burgerMenuToggleImage' &&
          element.id !== 'header' &&
          element.id !== 'footer'
        ) {
          setSideBarOpen(false);
        }
      }
    });
  }, []);

  const handleResend = () => {
    if (!isButtonDisabled) {
      setButtonDisabled(true);
      dispatch(
        resendEmailForStripe({
          email,
          setFlag,
          isFirstTime,
          setIsFirstTime,
          firstName,
          id: userID.toString(),
        }),
      );
    } else {
      return;
    }
  };

  return (
    <div className={styles.container_global}>
      <Header toggleSideBarMenu={toggleSideBarMenu} sideBarOpen={sideBarOpen} />

      <BurgerMenu menuRef={menuRef} id={id} sideBarOpen={sideBarOpen} page="payments" />

      {+isVerified ? (
        <div className={styles.container}>
          <form className={styles.wrapper} onSubmit={handleSaveKeys}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img src={stripe} alt="stripe" />
            </div>
            <div className={styles.input_wrapper}>
              <input
                disabled={inputsDisabled}
                className={styles.input}
                type="text"
                placeholder="Publishable Key"
                value={editedPublishableKey}
                onChange={(e) => setEditedPublishableKey(e.target.value)}
              />
              {inputsDisabled && (
                <img
                  className={styles.input_edit_img}
                  src={edit_pencil}
                  alt="edit"
                  onClick={() => setModalActive(true)}
                />
              )}
            </div>
            <div
              className={styles.input_wrapper}
              style={{
                marginTop: '10px',
              }}>
              <input
                disabled={inputsDisabled}
                className={styles.input}
                type="text"
                placeholder="Secret Key"
                value={editedSecretKey}
                onChange={(e) => setEditedSecretKey(e.target.value)}
              />
              {inputsDisabled && (
                <img
                  className={styles.input_edit_img}
                  src={edit_pencil}
                  alt="edit"
                  onClick={() => setModalActive(true)}
                />
              )}
            </div>
            <div className={styles.text_wrapper}>
              <span className={styles.text}>
                Please enter the Publishable Key and Secret Key from your stripe account to
                successfully make payments to your account.
              </span>
            </div>
            <span className={styles.error_message}>{message}</span>
            {successMessage && (
              <span
                style={{
                  color: '#4D9246',
                }}>
                {successMessage}
              </span>
            )}
            <button
              onClick={handleSaveKeys}
              className={styles.save_btn}
              disabled={!editedSecretKey.length || !editedPublishableKey.length || inputsDisabled}>
              {isLoading ? 'Loading...' : 'Save'}
            </button>
          </form>
        </div>
      ) : (
        <div className={styles.nothification_wrapper}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img src={stripe} alt="stripe" />
          </div>
          <h3 className={styles.nothification_title}>Please verify your email</h3>
          <p className={styles.nothification_text}>
            We've sent a confirmation email to the address you provided. Please check your <br />
            inbox and verify it to set up Stripe for payments.
          </p>

          {!isFirstTime && (
            <p className={styles.timer_text}>
              Did you not receive the confirmation email? Check again in
              <Timer flag={flag} setButtonDisabled={setButtonDisabled} />
            </p>
          )}
          <button className={styles.resend_btn} disabled={isButtonDisabled} onClick={handleResend}>
            Resend
          </button>
        </div>
      )}

      {isModalActive && (
        <VerificationModal setModalActive={setModalActive} setEditMod={setEditMod} />
      )}

      <Footer />
    </div>
  );
}

export default Payments;
