import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url.js';
import { updateEmployees } from './restaurantsSlice.js';

const initialState = {
  user: null,
  isAuth: false,
  isLoading: false,
  successMessage: '',
  mailSendingLoading: false,
  message: '',
};

export const signupAsEmployee = createAsyncThunk(
  'admin/signup-as-employee',
  async ({ setMessage, setModalActive, showToast, ...values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/admins/signup-as-employee`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(message);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        console.log('refresh');
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(signupAsEmployee({ setMessage, setModalActive, showToast, ...values }));
        return 'refreshed';
      }

      dispatch(updateEmployees({ employees: data.employees }));
      setModalActive(false);
      showToast();
    } catch (err) {
      if (err.message.includes('admins.phoneNumber')) {
        setMessage('The user already exists by this phone number.');
      } else if (err.message.includes('admins.email')) {
        setMessage('The user already exists by this email.');
      } else {
        setMessage(err.message);
      }
      return rejectWithValue({ message: err.message });
    }
  },
);

export const signup = createAsyncThunk('admin/signup', async (values, { rejectWithValue }) => {
  try {
    delete values.confirmPassword;
    const response = await fetch(`${URL}/admins/signup`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    const data = await response.json();

    if (data.message === 'success') {
      return data;
    }

    throw Error(data.message);
  } catch (err) {
    values.setMessage(err.message);
    return rejectWithValue({ message: err.message });
  }
});

export const signin = createAsyncThunk('admin/signin', async (userData, { rejectWithValue }) => {
  try {
    const response = await fetch(`${URL}/admins/signin`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(userData),
    });
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw Error(data.message);
    }
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const logout = createAsyncThunk('admin/logout', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch(`${URL}/admins/logout`, {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();

    if (response.ok) {
      localStorage.removeItem('persist:root2');
      return data;
    } else {
      throw Error(data.message);
    }
  } catch (err) {
    return rejectWithValue({ message: err.mesage });
  }
});

export const getMe = createAsyncThunk(
  'admin/getMe',
  async ({ setIsLoading }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/admins/get-me`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getMe({ setIsLoading }));
        return 'refreshed';
      }

      setIsLoading(false);
      return data;
    } catch (err) {
      setIsLoading(false);
      dispatch(logout());
      return rejectWithValue({ message: err.message });
    }
  },
);

// for stripe resend (with authorization)

export const resendEmailForStripe = createAsyncThunk(
  'admins/resendEmail',
  async (
    { email, setFlag, isFirstTime, setIsFirstTime, id, firstName },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await fetch(`${URL}/admins/resend-email-for-stripe`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ id, email, firstName }),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(message);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(
          resendEmailForStripe({ email, setFlag, isFirstTime, setIsFirstTime, id, firstName }),
        );
        return 'refreshed';
      }

      setFlag((flag) => !flag);
      if (isFirstTime === true) {
        setIsFirstTime(false);
      }
    } catch (err) {
      console.log(err.message);
      return rejectWithValue({ message: err.message });
    }
  },
);

export const sendEmail = createAsyncThunk(
  'admins/sendEmail',
  async ({ email, navigate, setFlag }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${URL}/admins/send-email`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      console.log(data);

      if (data.message === 'No user found with this email') {
        return rejectWithValue({ message: data.message });
      } else if (data.message === 'success') {
        if (setFlag) {
          setFlag(true);
        }
        navigate(`/email-message/${email}`);
      }
      return data;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const resetPassword = createAsyncThunk(
  'admin/resetPassword',
  async ({ email, password }, { rejectWithValue }) => {
    console.log(email, password);
    try {
      const response = await fetch(`${URL}/admins/reset-password`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw Error('Error from server!');
      }
    } catch (err) {
      return rejectWithValue({ message: err.mesage });
    }
  },
);

// check password for edit stripe keys
export const checkPassword = createAsyncThunk(
  'admin/checkPassword',
  async (
    { email, password, setEditMod, setModalActive, setMessage },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await fetch(`${URL}/admins/checkPasswordForEditStripeKeys`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(checkPassword({ email, password, setEditMod, setModalActive, setMessage }));
        return 'refreshed';
      }

      if (data.message === 'success') {
        setEditMod();
        setModalActive(false);
        return;
      } else {
        setMessage(data.message);
      }

      return data;
    } catch (err) {
      setMessage(err.message);
      return rejectWithValue({ message: err.mesage });
    }
  },
);

export const changeAdminInfo = createAsyncThunk(
  '/admin/changeAdminInfo',
  async ({ newInfoData, setMessage, showToast }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/admins/change-user-info`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify(newInfoData),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(message);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(changeAdminInfo({ newInfoData, setMessage }));
        return 'refreshed';
      }
      showToast();
      setMessage({ type: 'success', text: 'Your changes were successfully saved' });
      return data;
    } catch (err) {
      setMessage({ type: 'error', text: err.message });
      return rejectWithValue({ message: err.message });
    }
  },
);

export const changePassword = createAsyncThunk(
  'admin/changePassword',
  async (
    { id, password, newPassword, setMessage, resetInputs, showToast },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await fetch(`${URL}/admins/change-password`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ id, password, newPassword }),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(message);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(changePassword({ id, password, newPassword, setMessage, resetInputs }));
        return 'refreshed';
      }

      showToast(data?.message);
      resetInputs();
    } catch (err) {
      setMessage({ type: 'error', text: err.message });
      return rejectWithValue({ message: err.message });
    }
  },
);

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    changeIsVerified(state) {
      if (state.user) {
        state.user.isVerified = '1';
      }
    },
    removeCurrentUser(state) {
      state.user = null;
      state.isAuth = false;
      state.isLoading = false;
      state.message = '';
    },
    removeErrorMessage(state) {
      state.message = '';
    },
    confirmPasswordByEmployee(state, action) {
      const { accessToken, adminData } = action.payload;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('isAuth', true);
      state.user = adminData;
      state.isAuth = true;
      state.message = '';
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeAdminInfo.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.user = action.payload?.user;
        state.isLoading = false;
        state.message = '';
        state.isAuth = true;
      })
      .addCase(signup.pending, (state, action) => {
        state.message = '';
        state.isLoading = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        const { accessToken, adminData } = action.payload;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('isAuth', true);
        state.user = adminData;
        state.isAuth = true;
        state.message = 'signup';
        state.isLoading = false;
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(signin.pending, (state, action) => {
        state.message = '';
        state.isLoading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        const { accessToken, adminData } = action.payload;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('isAuth', true);
        state.user = adminData;
        state.isAuth = true;
        state.message = '';
        state.isLoading = false;
      })
      .addCase(signin.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
      })
      .addCase(getMe.pending, (state, action) => {
        state.message = '';
        state.isLoading = false;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        const { adminData } = action.payload;
        state.user = adminData;
        state.isAuth = true;
        state.message = '';
        state.isLoading = false;
        localStorage.setItem('isAuth', true);
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isAuth = false;
        state.user = null;
        state.message = '';
        state.isLoading = false;
        localStorage.removeItem('isAuth');
      })
      .addCase(logout.fulfilled, (state, action) => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('isAuth');
        state.user = null;
        state.isAuth = false;
        state.message = '';
      })
      .addCase(sendEmail.pending, (state, action) => {
        state.mailSendingLoading = true;
        state.message = '';
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.mailSendingLoading = false;
        state.message = '';
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.mailSendingLoading = false;
        state.message = action.payload.message;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.message = 'password is changed!';
      })
      .addCase(checkPassword.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
      });
  },
});

export default adminSlice.reducer;
export const {
  removeErrorMessage,
  changeIsVerified,
  removeCurrentUser,
  confirmPasswordByEmployee,
} = adminSlice.actions;
