import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url.js';
import { logout } from './adminSlice.js';

const initialState = {
  items: [],
};

export const getItems = createAsyncThunk(
  'items/get-items',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/items/get-items/${id}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(getItems({ id }));
        return 'refreshed';
      }

      return data.items;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const addItem = createAsyncThunk(
  'items/add-item',
  async (
    { id, formData, resetInformation, showToast, setBtnSubmitted },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await fetch(`${URL}/items/add-item/${id}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(addItem({ id, formData, resetInformation, showToast, setBtnSubmitted }));
        return 'refreshed';
      }

      setBtnSubmitted(false);
      showToast();
      resetInformation();
      return data;
    } catch (err) {
      setBtnSubmitted(false);
      return rejectWithValue({ message: err.message });
    }
  },
);

export const changeItem = createAsyncThunk(
  'items/change-item',
  async ({ id, formData, showToast }, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch(`${URL}/items/change-item/${id}`, {
        credentials: 'include',
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(changeItem({ id, formData, showToast }));
        return 'refreshed';
      }

      showToast();
      return data.items;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const deleteItem = createAsyncThunk(
  'items/delete-item',
  async ({ id, restaurantID }, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch(`${URL}/items/delete-item/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ restaurantID }),
      });

      if (!response.ok) {
        const { message } = await response.json();
        if (message === 'Refresh and access tokens are not valid!') {
          dispatch(logout());
        }
        throw Error(response.statusText);
      }

      const data = await response.json();

      if (data.message === 'refreshed') {
        localStorage.setItem('accessToken', data.accessToken);
        dispatch(deleteItem({ id, restaurantID }));
        return 'refreshed';
      }

      return data.items;
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    getItemsFirstTime(state, action) {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addItem.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.items = action.payload.items;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.items = action.payload;
      })
      .addCase(changeItem.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.items = action.payload;
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        if (action.payload === 'refreshed') {
          return;
        }
        state.items = action.payload;
      });
  },
});

export const { getItemsFirstTime } = itemsSlice.actions;
export default itemsSlice.reducer;
