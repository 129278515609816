import React from 'react';
import logo from '../../images/logo.jpeg';
import styles from './style.module.css';

function LoadingPage() {
  return (
    <div className={styles.wrapper}>
      <img className={styles.img} src={logo} alt="Cafe click" />
    </div>
  );
}

export default LoadingPage;
