import React, { useCallback, useEffect } from 'react';
import styles from './style.module.css';
import closeIcon from '../../images/close icon.svg';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/adminSlice';

function LogoutModal({ setLogoutModalActive }) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        setLogoutModalActive(false);
      }
    },
    [setLogoutModalActive],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setLogoutModalActive, handleKeyDown]);

  return (
    <div className={styles.modal_container} onClick={() => setLogoutModalActive(false)}>
      <div className={styles.modal_wrapper} onClick={(e) => e.stopPropagation()}>
        <img
          src={closeIcon}
          className={styles.close_icon}
          alt="close"
          onClick={() => setLogoutModalActive(false)}
        />
        <div className={styles.wrapper_for_modal_item}>
          <h2 className={styles.modal_title}>Confirm Log Out</h2>
        </div>
        <div className={styles.wrapper_for_modal_item}>
          <span className={styles.modal_text}>Are you sure you want to log out?</span>
        </div>
        <div className={styles.wrapper_for_modal_item}>
          <div className={styles.btns_wrapper}>
            <button className={styles.logout_btn} onClick={handleLogout}>
              Log out
            </button>
            <button className={styles.cancel_btn} onClick={() => setLogoutModalActive(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;
