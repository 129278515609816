import React, { useCallback, useEffect, useState } from 'react';
import styles from '../ResetPassword/styles.module.css';
import eyeOn from '../../images/eye.svg';
import eyeOff from '../../images/eyeOff.svg';
import { useParams } from 'react-router';
import { URL } from '../../utils/url';
import LoadingPage from '../LoadingPage/LoadingPage';
import { useDispatch } from 'react-redux';
import { confirmPasswordByEmployee } from '../../redux/adminSlice';
import cafeClickLogo from '../../images/cafeClickLogoWithText.png';

function ConfirmEmployeePage() {
  const dispatch = useDispatch();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [isEmployeeAlreadyVerified, setIsEmployeeAlreadyVerified] = useState(false);
  const [isEmployeeFound, setEmployeeFound] = useState(true);
  const [message, setMessage] = useState('');

  const { id } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const getEmployeeInfo = useCallback(async () => {
    try {
      const response = await fetch(`${URL}/admins/getEmployeeInfo/${id}`);
      const data = await response.json();
      console.log(data);

      if (data.message === 'success') {
        setEmployeeInfo(data);
      } else if (data.message === 'Employee is not found!') {
        setEmployeeFound(false);
      } else {
        setIsEmployeeAlreadyVerified(true);
      }
    } catch (err) {
      setMessage(err.message);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      getEmployeeInfo();
    }, 800);
  }, [getEmployeeInfo]);

  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
  });

  const handleInputChange = (type, text) => {
    if (type === 'password') {
      // changing password
      setPassword(text);
      // error handling
      if (!text) {
        setErrors({ ...errors, password: 'Please enter a password' });
      } else if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(text)) {
        setErrors({
          ...errors,
          password: 'Password must contain min 8 charachters, capital letters, numbers or symbols',
        });
      } else if (confirmPassword && text !== confirmPassword) {
        setErrors({ ...errors, confirmPassword: 'Passwords do not match' });
      } else {
        if (errors.confirmPassword === null) {
          setErrors({ ...errors, password: '' });
        } else {
          setErrors({ confirmPassword: '', password: '' });
        }
      }
    } else {
      // changing password
      setConfirmPassword(text);
      // error handling
      if (text !== password) {
        setErrors({ ...errors, confirmPassword: 'Passwords do not match' });
      } else if (password === '') {
        setErrors({ ...errors, confirmPassword: 'Please enter a password' });
      } else {
        setErrors({ ...errors, confirmPassword: '' });
      }
    }
  };

  const handleConfirmAccount = (e) => {
    e.preventDefault();
    if (isBtnDisabled()) return null;

    fetch(`${URL}/admins/confirmPasswordForNewEmployee`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ password, employeeID: id }),
    })
      .then((res) => res.json())
      .then((data) => {
        const { adminData, accessToken } = data;
        dispatch(confirmPasswordByEmployee({ adminData, accessToken }));
      })
      .catch((err) => console.log(err));
  };

  const isBtnDisabled = () => {
    return !Object.values(errors).every((err) => err === '');
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  if (message) {
    return <span>{message}</span>;
  }

  if (!isEmployeeFound) {
    return <span>Employee is not found</span>;
  }

  if (isEmployeeAlreadyVerified) {
    return <h1>Already verified</h1>;
  }

  if (employeeInfo) {
    return (
      <div className={styles.resetPassword}>
        <div className={styles.bothSideRP}>
          <div className={styles.leftSideRP}>
            <img
              src={cafeClickLogo}
              alt=""
              style={{ width: '192px', height: '86px', objectFit: 'contain' }}
            />
            <div className={styles.leftSideBottomInfoRP}>
              <p>Welcome</p>
              <span
                style={{
                  fontSize: '34px',
                }}>
                Please complete the form to confirm your role as {employeeInfo?.role}.
              </span>
            </div>
          </div>
          <div className={styles.rightSideRP}>
            <div className={styles.rightSideInfoRP}>
              <p className={styles.signUpHeaderRP}>Create Password</p>

              <form className={styles.formForInputsRP} onSubmit={handleConfirmAccount}>
                <div className={styles.divForUserInputsRP}>
                  <input
                    className={styles.userInputsRP}
                    id="firstPasswordEye"
                    type={showPassword1 ? 'text' : 'password'}
                    required
                    value={password}
                    onChange={(e) => handleInputChange('password', e.target.value)}
                  />
                  <label className={styles.labelForUserInputsRP}>Password</label>
                  {errors.password && (
                    <p className={styles.password_err_message}>{errors.password}</p>
                  )}
                  <img
                    className={styles.passwordEye}
                    src={showPassword1 ? eyeOff : eyeOn}
                    onClick={() => setShowPassword1(!showPassword1)}
                    alt=""
                  />
                </div>

                <div className={styles.divForUserInputsRP}>
                  <input
                    className={styles.userInputsRP}
                    id="secondPasswordEye"
                    type={showPassword2 ? 'text' : 'password'}
                    required
                    value={confirmPassword}
                    onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                  />
                  <label className={styles.labelForUserInputsRP}>Confirm Password</label>
                  {errors.confirmPassword && (
                    <p className={styles.password_err_message}>{errors.confirmPassword}</p>
                  )}
                  <img
                    className={styles.passwordEye}
                    src={showPassword2 ? eyeOff : eyeOn}
                    onClick={() => setShowPassword2(!showPassword2)}
                    alt=""
                  />
                </div>

                <button disabled={isBtnDisabled()} className={styles.resetButton}>
                  Confirm
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmEmployeePage;
