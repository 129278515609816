import React, { useEffect, useState } from 'react';
import styles from './style.module.css';

function Timer({ setButtonDisabled, flag }) {
  const [seconds, setSeconds] = useState(59);

  useEffect(() => {
    setSeconds(59);
    setButtonDisabled(true);
  }, [flag, setButtonDisabled]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        clearInterval(intervalId);
        setButtonDisabled(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, setButtonDisabled]);

  const formatTime = (time) => {
    return time < 10 ? `00:0${time}s` : `00:${time}s`;
  };

  return <span className={styles.text}>{formatTime(seconds)}</span>;
}

export default Timer;
