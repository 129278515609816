// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_global_container__Rb91C {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.style_wrapper__QO84Z {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
}

.style_title__Fd9Ba {
  font-family: Inter;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: rgb(66, 114, 94);
}

.style_text__07\\+OW {
  text-align: center;
  color: rgb(56, 56, 56);
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.style_continue_btn__oTeji {
  padding: 10px 38px;
  color: white;
  font-family: Inter;
  font-weight: 600;
  background-color: rgb(66, 114, 94);
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: 0.2s linear;
}

.style_continue_btn__oTeji:hover {
  background-color: rgba(66, 114, 94, 86%);
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminVerified/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,kCAAkC;EAClC,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,6CAA6C;EAC7C,uBAAuB;AACzB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".global_container {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n\n.wrapper {\n  margin-top: 100px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 42px;\n}\n\n.title {\n  font-family: Inter;\n  font-weight: 600;\n  font-size: 32px;\n  line-height: 38px;\n  color: rgb(66, 114, 94);\n}\n\n.text {\n  text-align: center;\n  color: rgb(56, 56, 56);\n  font-family: Inter;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 29px;\n}\n\n.continue_btn {\n  padding: 10px 38px;\n  color: white;\n  font-family: Inter;\n  font-weight: 600;\n  background-color: rgb(66, 114, 94);\n  border: none;\n  outline: none;\n  border-radius: 10px;\n  cursor: pointer;\n  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);\n  transition: 0.2s linear;\n}\n\n.continue_btn:hover {\n  background-color: rgba(66, 114, 94, 86%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global_container": `style_global_container__Rb91C`,
	"wrapper": `style_wrapper__QO84Z`,
	"title": `style_title__Fd9Ba`,
	"text": `style_text__07+OW`,
	"continue_btn": `style_continue_btn__oTeji`
};
export default ___CSS_LOADER_EXPORT___;
