import { useEffect, useRef, useState } from 'react';
import GeocodeLocation from '../GeocodeLocation';
import ModalCloseIcon from '../images/close icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentRestaurant, updateRestaurant } from '../redux/restaurantsSlice';
import { URL as serverURL } from '../utils/url';
import styles from '../components/Signup/style.module.css';
import countries from '../data/countries.json';
import itemAddStyles from '../components/ItemAdd/ItemAdd.module.css';
import Select from 'react-select';
import { FlagIcon } from 'react-flag-kit';

function EditRestModal({ id, setCurrentRestId, editModalClose }) {
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(false);
  const [editValues, setEditValues] = useState({});
  const currentRestaurant = useSelector((state) => state.restaurants.currentRestaurant);
  const fileInput = useRef();

  // phone number
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState({});
  const countriesOptions = countries.map((country) => {
    const { dial_code, code } = country;
    return { value: dial_code, label: `(${dial_code})`, code };
  });

  const fullPhoneNumber = `${countryCode?.value} ${phoneNumber}`;

  const getCountryByPhoneCode = (phoneCode) => {
    const findedCountry = countriesOptions.find((country) => country.value === phoneCode);
    if (findedCountry) {
      setCountryCode(findedCountry);
    }
  };

  useEffect(() => {
    dispatch(
      getCurrentRestaurant({
        id,
        setShowComponent,
        setEditValues,
        getCountryByPhoneCode,
        setPhoneNumber,
      }),
    );
  }, [dispatch, id]);

  const validateName = (e) => {
    if (!e) {
      return 'Please enter your restaurant name';
    }
    return '';
  };

  const validatePhoneNumber = (e) => {
    if (!e) {
      return 'Phone Number is required !'; //
    } else if (e.length < 3 && e.length > 0) {
      return 'Phone number must contain min 2 numbers';
    }
    return '';
  };

  const validateAddress = (e) => {
    if (!/[\p{L}0-9\s,]+/.test(e)) {
      return 'Please enter your restaurant address';
    } else if (e === '') {
      return "Restaurant's address is required";
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    const newValues = { ...editValues, [field]: value };
    setEditValues(newValues);
    if (field !== 'description') {
      const newErrors = {
        ...errors,
        [field]: validateField(field, value),
      };
      setErrors(newErrors);
    }
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'name':
        return validateName(value);
      case 'phoneNumber':
        return validatePhoneNumber(value);
      case 'address':
        return validateAddress(value);
      default:
        return '';
    }
  };

  // Google Map Geocoding
  let apiKey = 'AIzaSyB6HUj_uw4KoaBogJFTbP1_RdO7gYWwfqE';
  let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${editValues.address}&key=${apiKey}`;
  const [latitude, setLatitude] = useState(40.17769453337888);
  const [longitude, setLongitude] = useState(44.51265097282707);

  async function getRestAddress() {
    try {
      const response = await fetch(api);
      let data = await response.json();

      let lat = data.results[0].geometry.location.lat;
      setLatitude(lat);
      let lng = data.results[0].geometry.location.lng;
      setLongitude(lng);
    } catch (error) {}
  }
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const handleImageChange = (event) => {
    setFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleEditRestaurant = (e) => {
    e.preventDefault();
    const dataForEdit = {
      address: editValues.address !== currentRestaurant.address ? editValues.address : '',
      description:
        editValues.description !== currentRestaurant.description ? editValues.description : '',
      name: editValues.name !== currentRestaurant.name ? editValues.name : '',
      phoneNumber: fullPhoneNumber !== currentRestaurant.phoneNumber ? fullPhoneNumber : '',
    };

    const validationErrors = {
      name: validateField('name', dataForEdit.name ? dataForEdit.name : editValues.name),
      phoneNumber: validateField('phoneNumber', phoneNumber),
      address: validateField(
        'address',
        dataForEdit.address ? dataForEdit.address : editValues.address,
      ),
    };
    if (Object.values(validationErrors).some((error) => error !== '')) {
      setErrors(validationErrors);
      return;
    }
    if (
      !dataForEdit.address &&
      !dataForEdit.name &&
      !dataForEdit.description &&
      !dataForEdit.phoneNumber &&
      !file
    ) {
      editModalClose();
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    if (!file) {
      formData.append('image_path', currentRestaurant.logo);
    }
    formData.append('name', dataForEdit.name);
    formData.append('phoneNumber', fullPhoneNumber);
    formData.append('address', dataForEdit.address);
    formData.append('description', dataForEdit.description);
    dispatch(updateRestaurant({ formData, setShowComponent, setEditValues }));
    editModalClose();
  };

  const isSaveBtnDisabled = () => {
    if (Object.values(errors).every((err) => err === '')) {
      return false;
    }
    return true;
  };

  const filterInputValue = (e) => {
    if (phoneNumber.length) {
      if (
        e.key === '+' ||
        e.key === 'e' ||
        e.key === '-' ||
        e.key === '.' ||
        e.key === ',' ||
        e.key === 'E'
      ) {
        e.preventDefault();
      }
      return;
    }
    if (
      e.key === '+' ||
      e.key === '0' ||
      e.key === 'e' ||
      e.key === '-' ||
      e.key === '.' ||
      e.key === ',' ||
      e.key === 'E'
    ) {
      e.preventDefault();
    }
  };

  const handleChangePhoneNumber = (e) => {
    let error;
    if (e.target.value.length < 3 && e.target.value.length > 0) {
      error = 'Phone number must contain min 2 numbers';
    } else if (e.target.value.length === 0) {
      error = 'Please enter your phone number';
    } else {
      setErrors({ ...errors, phoneNumber: '' });
    }

    if (error) {
      setErrors({ ...errors, phoneNumber: error });
    }
    setPhoneNumber(e.target.value);
  };

  const handleRemoveIcon = () => {
    setFile('default');
    setPreviewImage(null);
  };

  const modalHeight = document.documentElement.scrollHeight;

  if (showComponent) {
    return (
      <div
        style={{
          height: modalHeight,
        }}
        className="ModalBackground">
        <div className="ModalContainer">
          <div className="divForModalCloseIcon">
            <img
              src={ModalCloseIcon}
              alt=""
              className="ModalCloseButton"
              style={{ color: 'white', cursor: 'pointer' }}
              onClick={() => {
                editModalClose();
                setCurrentRestId(null);
              }}
            />
          </div>

          <form className="ModalDetailsPart" onSubmit={handleEditRestaurant}>
            <p className="ModalName">Restaurant Details</p>
            <div className="ModalDivForUserInputs">
              <input
                className="ModalUserInputs"
                type="text"
                value={editValues.name}
                onChange={function (e) {
                  handleInputChange('name', e.target.value);
                }}
              />
              <label className="ModalLabelForUserInputs">Name</label>
              {errors.name && (
                <p
                  style={{
                    color: '#FF0000',
                    fontSize: 10,
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    textIndent: 7,
                  }}>
                  {errors.name}
                </p>
              )}
            </div>

            {countryCode && (
              <div
                style={{
                  width: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  position: 'relative',
                }}>
                <div
                  id="phoneNumber"
                  style={{
                    width: '100%',
                    borderBottom: '1px #979797 solid',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <div className={styles.divForUserInputs}>
                    <Select
                      options={countriesOptions}
                      value={countryCode}
                      onChange={setCountryCode}
                      getOptionLabel={(e) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}>
                            <FlagIcon code={e.code} size={18} /> <span>{e.label}</span>
                          </div>
                        );
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          minWidth: state.isFocused ? '104.66px' : null,
                          boxShadow: state.isFocused ? 'none' : null,
                          borderColor: state.isFocused ? '#ccc' : null,
                          cursor: 'pointer',
                          border: state.isFocused ? 'none' : 'none',
                          outline: state.isFocused ? 'none' : 'none',
                        }),
                      }}
                    />
                  </div>
                  <input
                    style={{
                      paddingLeft: '16px',
                      border: 'none',
                      width: '64%',
                      transform: 'translate(-10px, 0px)',
                    }}
                    type="number"
                    value={phoneNumber}
                    placeholder="1122334455"
                    className={styles.userInputs}
                    onKeyDown={filterInputValue}
                    onChange={handleChangePhoneNumber}
                  />
                </div>
                {errors.phoneNumber && (
                  <p
                    style={{
                      color: '#FF0000',
                      fontSize: 10,
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                      textIndent: 7,
                    }}>
                    {errors.phoneNumber}
                  </p>
                )}
              </div>
            )}

            <div className="ModalDivForRestLogo">
              <label className="ModalLabelForRestLogo">Logo</label>
              <div className="ModalDivForInputAndLogo">
                <button
                  type="button"
                  style={{
                    outline: 'none',
                    borderRadius: '5px',
                    background: '#E7E7E7',
                    boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.25)',
                    padding: '4px 20px',
                    marginTop: '10px',
                    cursor: 'pointer',
                    color: '#484848',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    border: 'none',
                  }}
                  onClick={() => {
                    fileInput.current.click();
                  }}>
                  Choose file
                </button>
                <input
                  ref={fileInput}
                  style={{
                    opacity: '0',
                    height: '0',
                    width: '0',
                    lineHeight: '0',
                    overflow: 'hidden',
                    padding: '0',
                    margin: '0',
                  }}
                  onChange={handleImageChange}
                  type="file"
                  accept=".png, .jpeg, .jpg, .svg"
                />

                {editValues.logo !== 'images/defaultRestIcon.png' && file !== 'default' && (
                  <img
                    style={{
                      marginTop: '10px',
                    }}
                    className="ModalRestImage"
                    src={previewImage ? previewImage : `${serverURL}/${editValues.logo}`}
                    alt="Selected"
                  />
                )}

                {(editValues.logo === 'images/defaultRestIcon.png' || file === 'default') &&
                  !previewImage && (
                    <span
                      style={{
                        transform: 'translate(14px, 6px)',
                        fontSize: '12px',
                        lineHeight: '14px',
                        fontWeight: '400',
                        fontFamily: 'Inter',
                        color: 'rgb(72, 72, 72)',
                      }}>
                      No file chosen
                    </span>
                  )}

                {editValues.logo === 'images/defaultRestIcon.png' && previewImage && (
                  <img
                    style={{
                      marginTop: '10px',
                    }}
                    className="ModalRestImage"
                    src={previewImage}
                    alt="Selected"
                  />
                )}

                {(editValues.logo !== 'images/defaultRestIcon.png' || previewImage) &&
                  file !== 'default' && (
                    <button
                      onClick={handleRemoveIcon}
                      type="button"
                      className={itemAddStyles.removeIconBtn}>
                      X
                    </button>
                  )}
              </div>
            </div>
            <div className="ModalDivForUserInputs">
              <input
                className="ModalUserInputs"
                type="text"
                value={editValues.address}
                onChange={function (e) {
                  handleInputChange('address', e.target.value);
                  getRestAddress();
                }}
              />
              <label className="ModalLabelForUserInputs">Address</label>
              {errors.address && (
                <p
                  style={{
                    color: '#FF0000',
                    fontSize: 10,
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    wordWrap: 'break-word',
                    textIndent: 7,
                  }}>
                  {errors.address}
                </p>
              )}
            </div>
            {/* Google map should be here */}
            {<GeocodeLocation apiKey={apiKey} latitude={latitude} longitude={longitude} />}
            <div className="ModalDivForUserInputs">
              <textarea
                style={{
                  maxHeight: '27px',
                  resize: 'none',
                  marginTop: '4px',
                  transition: 'all 0.3s ease-in-out',
                }}
                className="ModalUserInputs"
                value={editValues.description}
                onChange={function (e) {
                  handleInputChange('description', e.target.value);
                }}
              />
              <label className="ModalLabelForUserInputs">About Restaurant</label>
            </div>

            <button className="ModalSaveButton" disabled={isSaveBtnDisabled()} type="submit">
              Save changes
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default EditRestModal;
