import React from 'react';
import styles from './style.module.css';
import closeModal from '../../images/close_modal.svg';

function DeleteModal({ setDeleteModalActive, handleDeleteCategory, setDeletedCategoryInfo }) {
  const modalHeight = document.documentElement.scrollHeight;

  return (
    <div
      className={styles.container}
      style={{
        height: modalHeight,
      }}
      onClick={() => {
        setDeleteModalActive(false);
        setDeletedCategoryInfo(null);
      }}>
      <div
        className={styles.wrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <h3 className={styles.title}>Are you sure?</h3>
        <img
          src={closeModal}
          alt="close"
          className={styles.close}
          onClick={() => {
            setDeleteModalActive(false);
            setDeletedCategoryInfo(null);
          }}
        />
        <p className={styles.text}>This action will delete all your information.</p>
        <div className={styles.modal_btns}>
          <button className={styles.remove_btn} onClick={handleDeleteCategory}>
            Delete
          </button>
          <button
            className={styles.cancel_btn}
            onClick={() => {
              setDeleteModalActive(false);
              setDeletedCategoryInfo(null);
            }}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
