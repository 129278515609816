import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getMe } from './redux/adminSlice';
import MyRestaurants from './components/MyRestaurants/MyRestaurants';
import SignIn from './components/Signin/SignIn';
import SignUp from './components/Signup/SignUp';
import MenuStructure from './components/MenuStructure/MenuStructure';
import MenuTemplate from './components/MenuTemplate/MenuTemplate';
import ItemAdd from './components/ItemAdd/ItemAdd';
import EditItem from './components/EditItem.jsx';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import EmailMessage from './components/EmailMessage/EmailMessage';
import RestTables from './components/RestTables/RestTables.jsx';
import Payments from './components/Payments/Payments.jsx';
import Settings from './components/Settings/Settings.jsx';
import AdminVerified from './components/AdminVerified/AdminVerified.jsx';
import LoadingPage from './components/LoadingPage/LoadingPage.jsx';
import OrderList from './components/OrderList/OrderList.jsx';
import Employees from './components/Employees/Employees.jsx';
import ConfirmEmployeePage from './components/ConfirmEmployeePage/ConfirmEmployeePage.jsx';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute.jsx';
import NotFound from './components/NotFound/NotFound.jsx';

function App() {
  const { isAuth, user } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      dispatch(getMe({ setIsLoading }));
    } else {
      setIsLoading(false);
    }
  }, [dispatch]);

  function dinamicNavigateWithoutAuth(Component) {
    if (isAuth) {
      return <Navigate to="/my-restaurants" />;
    } else {
      return <Component />;
    }
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* pages without auth */}

        <Route path="/" element={dinamicNavigateWithoutAuth(SignIn)} />
        <Route path="/signup" element={dinamicNavigateWithoutAuth(SignUp)} />
        <Route path="/signin" element={dinamicNavigateWithoutAuth(SignIn)} />
        <Route path="/forgot-password" element={dinamicNavigateWithoutAuth(ForgotPassword)} />
        <Route path="/reset-password/:token" element={dinamicNavigateWithoutAuth(ResetPassword)} />
        <Route path="/email-message/:email" element={dinamicNavigateWithoutAuth(EmailMessage)} />
        <Route
          path="/confirm-employee/:id"
          element={dinamicNavigateWithoutAuth(ConfirmEmployeePage)}
        />

        {/* pages with auth  */}

        <Route
          path="/my-restaurants"
          element={
            <ProtectedRoute allowedRoles={['admin']} userRole={user?.role} restID={user?.restID}>
              <MyRestaurants />
            </ProtectedRoute>
          }
        />

        <Route
          path="/payments/:id"
          element={
            <ProtectedRoute allowedRoles={['admin']} userRole={user?.role} restID={user?.restID}>
              <Payments />
            </ProtectedRoute>
          }
        />

        <Route
          path="/menu-structure/:id"
          element={
            <ProtectedRoute
              allowedRoles={['admin', 'manager']}
              userRole={user?.role}
              restID={user?.restID}>
              <MenuStructure />
            </ProtectedRoute>
          }
        />

        <Route
          path="/item-add"
          element={
            <ProtectedRoute
              allowedRoles={['admin', 'manager']}
              userRole={user?.role}
              restID={user?.restID}>
              <ItemAdd />
            </ProtectedRoute>
          }
        />

        <Route
          path="/edit-item/:id"
          element={
            <ProtectedRoute
              allowedRoles={['admin', 'manager']}
              userRole={user?.role}
              restID={user?.restID}>
              <EditItem />
            </ProtectedRoute>
          }
        />

        <Route
          path="/menu-template/:id"
          element={
            <ProtectedRoute
              allowedRoles={['admin', 'manager']}
              userRole={user?.role}
              restID={user?.restID}>
              <MenuTemplate />
            </ProtectedRoute>
          }
        />

        <Route
          path="/rest-tables/:id"
          element={
            <ProtectedRoute
              allowedRoles={['admin', 'manager']}
              userRole={user?.role}
              restID={user?.restID}>
              <RestTables />
            </ProtectedRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <ProtectedRoute
              allowedRoles={['admin', 'manager', 'waiter']}
              userRole={user?.role}
              restID={user?.restID}>
              <Settings />
            </ProtectedRoute>
          }
        />

        <Route
          path="/employees/:id"
          element={
            <ProtectedRoute allowedRoles={['admin']} userRole={user?.role} restID={user?.restID}>
              <Employees />
            </ProtectedRoute>
          }
        />

        <Route
          path="/order-list/:id"
          element={
            <ProtectedRoute
              allowedRoles={['admin', 'manager', 'waiter']}
              userRole={user?.role}
              restID={user?.restID}>
              <OrderList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/verification/:token"
          element={
            <ProtectedRoute allowedRoles={['admin']} userRole={user?.role} restID={user?.restID}>
              <AdminVerified />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
