import React from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

function GeocodeLocation({ apiKey, latitude, longitude }) {
  const center = {
    lat: latitude,
    lng: longitude,
  };

  const containerStyle = {
    width: '380px',
    height: '140px',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  return (
    (isLoaded && <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18} />) || (
      <div
        style={{
          backgroundColor: '#c6c6c6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '380px',
          height: '140px',
        }}>
        <span>Loading...</span>
      </div>
    )
  );
}

export default GeocodeLocation;
