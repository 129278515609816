import React from 'react';
import styles from './style.module.css';
import copyRight from '../../images/copyRight.svg';
import cafeClickIcon from '../../images/cafeClickIcon.svg';
import worxLogo from '../../images/worx_logo.jpg';

function Footer() {
  const handleNavigate = () => {
    window.open('http://worx.am', '_blank');
  };

  return (
    <footer className={styles.wrapper} id="footer">
      <img src={copyRight} alt="copyRight" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={cafeClickIcon}
          alt="cafeClick"
          style={{ width: '28px', height: '37px', objectFit: 'contain' }}
        />
        <p className={styles.restName}>Cafe Click</p>
      </div>
      <p className={styles.right_block_text}>
        Powered by{' '}
        <img onClick={handleNavigate} className={styles.worx_logo} src={worxLogo} alt="Worx" />
      </p>
    </footer>
  );
}

export default Footer;
