import React from 'react';
import styles from './style.module.css';
import ProductInOrder from './ProductInOrder';

function Modal({ setModalActive, order }) {
  const handleCloseModal = () => {
    setModalActive(false);
  };

  const products = JSON.parse(order.body);

  return (
    <div className={styles.modal_container} onClick={handleCloseModal}>
      <div
        className={styles.modal_wrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <button onClick={handleCloseModal} className={styles.close_btn}>
          X
        </button>
        <h2 className={styles.modal_table_number_text}>Table Number: {order.tableNumber}</h2>
        <div className={styles.products_wrapper}>
          {products.map((product) => {
            return <ProductInOrder key={product.orderId} product={product} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Modal;
